@charset "UTF-8";@keyframes rotate{100%{transform:rotate(360deg);}
}
@keyframes dash{0%{stroke-dasharray:1, 150;stroke-dashoffset:0;}
50%{stroke-dasharray:90, 150;stroke-dashoffset:-35;}
100%{stroke-dasharray:90, 150;stroke-dashoffset:-124;}
}
@-webkit-keyframes dot-move{from{-webkit-transform:translateX(0);transform:translateX(0);}
to{-webkit-transform:translateX(88px);transform:translateX(88px);}
}
@-webkit-keyframes dot-move{from{-ms-transform:translateX(0);transform:translateX(0);}
to{-ms-transform:translateX(88px);transform:translateX(88px);}
}
@keyframes dot-move{from{-webkit-transform:translateX(0);transform:translateX(0);}
to{-webkit-transform:translateX(88px);transform:translateX(88px);}
}
@-webkit-keyframes dot-move2{from{-webkit-transform:translateX(0);transform:translateX(0);}
to{-webkit-transform:translateX(48px);transform:translateX(48px);}
}
@-webkit-keyframes dot-move2{from{-ms-transform:translateX(0);transform:translateX(0);}
to{-ms-transform:translateX(48px);transform:translateX(48px);}
}
@keyframes dot-move2{from{-webkit-transform:translateX(0);transform:translateX(0);}
to{-webkit-transform:translateX(48px);transform:translateX(48px);}
}
@-webkit-keyframes play-button-pulse{0%{-webkit-transform:scale(0.5);-moz-transform:scale(0.5);-ms-transform:scale(0.5);-o-transform:scale(0.5);transform:scale(0.5);opacity:0;}
50%{opacity:1;}
100%{-webkit-transform:scale(1.2);-moz-transform:scale(1.2);-ms-transform:scale(1.2);-o-transform:scale(1.2);transform:scale(1.2);opacity:0;}
}
@-moz-keyframes play-button-pulse{0%{-webkit-transform:scale(0.5);-moz-transform:scale(0.5);-ms-transform:scale(0.5);-o-transform:scale(0.5);transform:scale(0.5);opacity:0;}
50%{opacity:1;}
100%{-webkit-transform:scale(1.2);-moz-transform:scale(1.2);-ms-transform:scale(1.2);-o-transform:scale(1.2);transform:scale(1.2);opacity:0;}
}
@-ms-keyframes play-button-pulse{0%{-webkit-transform:scale(0.5);-moz-transform:scale(0.5);-ms-transform:scale(0.5);-o-transform:scale(0.5);transform:scale(0.5);opacity:0;}
50%{opacity:1;}
100%{-webkit-transform:scale(1.2);-moz-transform:scale(1.2);-ms-transform:scale(1.2);-o-transform:scale(1.2);transform:scale(1.2);opacity:0;}
}
@-o-keyframes play-button-pulse{0%{-webkit-transform:scale(0.5);-moz-transform:scale(0.5);-ms-transform:scale(0.5);-o-transform:scale(0.5);transform:scale(0.5);opacity:0;}
50%{opacity:1;}
100%{-webkit-transform:scale(1.2);-moz-transform:scale(1.2);-ms-transform:scale(1.2);-o-transform:scale(1.2);transform:scale(1.2);opacity:0;}
}
@keyframes play-button-pulse{0%{-webkit-transform:scale(0.5);-moz-transform:scale(0.5);-ms-transform:scale(0.5);-o-transform:scale(0.5);transform:scale(0.5);opacity:0;}
50%{opacity:1;}
100%{-webkit-transform:scale(1.2);-moz-transform:scale(1.2);-ms-transform:scale(1.2);-o-transform:scale(1.2);transform:scale(1.2);opacity:0;}
}
@keyframes blink{50%{opacity:1;}
}
*{padding:0;margin:0;box-sizing:border-box;}
ul{padding:0;margin:0;list-style-type:none;}
a:hover,
a:focus{text-decoration:none;}
button:focus,
input:focus{outline:none;}
h1, h2, h3, h4, h5, h6{color:#233d63;}
h1, h2, h3, h4, h5, h6, p{margin:0;}
body{font-size:16px;line-height:28px;font-family:"Mukta", sans-serif;color:#7f8897;position:relative;-moz-osx-font-smoothing:grayscale;-webkit-font-smoothing:antialiased;font-weight:500;}
.container{max-width:1200px;}
@media (min-width:576px){.container{max-width:540px;}
}
@media (min-width:768px){.container{max-width:720px;}
}
@media (min-width:992px){.container{max-width:960px;}
}
@media (min-width:1200px){.container{max-width:1200px;}
}
.section-bg{background-color:#F7FAFD;}
.section-bg-2{background-color:#233d63;}
.primary-color{color:#233d63 !important;}
.primary-color-2{color:#51be78 !important;}
.primary-color-3{color:#7f8897 !important;}
.text-color{color:#F68A03 !important;}
.text-color-2{color:#358FF7 !important;}
.text-color-3{color:#38BB0C !important;}
.text-color-rgba{color:rgba(255, 255, 255, 0.7) !important;}
.bg-color-1{background-color:#51be78 !important;}
.bg-color-2{background-color:#7E3CF9 !important;}
.bg-color-3{background-color:#F68A03 !important;}
.bg-color-4{background-color:#358FF7 !important;}
.bg-color-5{background-color:#38BB0C !important;}
.bg-color-6{background-color:#DD493D !important;}
.bg-color-7{background-color:#7f8897 !important;}
.bg-black{background-color:#233d63 !important;}
.bg-gradient{background:#00d2ff;background:-webkit-linear-gradient(to right, #928DAB, #00d2ff);background:linear-gradient(to right, #928DAB, #00d2ff);}
.border{border-color:rgba(128, 137, 150, 0.1) !important;}
.border-top{border-top-color:rgba(128, 137, 150, 0.1) !important;}
.border-right{border-right-color:rgba(128, 137, 150, 0.1) !important;}
.border-bottom{border-bottom-color:rgba(128, 137, 150, 0.1) !important;}
.border-left{border-left-color:rgba(128, 137, 150, 0.1) !important;}
.w-50px{width:50px !important;}
.w-60px{width:60px !important;}
.w-70px{width:70px !important;}
.w-80px{width:80px !important;}
.w-90px{width:90px !important;}
.w-100px{width:100px !important;}
.section-padding{padding-top:120px;padding-bottom:120px;}
.section--padding{padding-top:120px;padding-bottom:90px;}
.padding-top-200px{padding-top:200px;}
.padding-top-190px{padding-top:190px;}
.padding-top-180px{padding-top:180px;}
.padding-top-170px{padding-top:170px;}
.padding-top-160px{padding-top:160px;}
.padding-top-150px{padding-top:150px;}
.padding-top-140px{padding-top:140px;}
.padding-top-130px{padding-top:130px;}
.padding-top-120px{padding-top:120px;}
.padding-top-115px{padding-top:115px;}
.padding-top-110px{padding-top:110px;}
.padding-top-100px{padding-top:100px;}
.padding-top-95px{padding-top:95px;}
.padding-top-90px{padding-top:90px;}
.padding-top-85px{padding-top:85px;}
.padding-top-80px{padding-top:80px;}
.padding-top-75px{padding-top:75px;}
.padding-top-70px{padding-top:70px;}
.padding-top-60px{padding-top:60px;}
.padding-top-50px{padding-top:50px;}
.padding-top-50px{padding-top:50px;}
.padding-top-45px{padding-top:45px;}
.padding-top-40px{padding-top:40px;}
.padding-top-35px{padding-top:35px;}
.padding-top-30px{padding-top:30px;}
.padding-top-20px{padding-top:20px;}
.padding-top-10px{padding-top:10px;}
.padding-bottom-200px{padding-bottom:200px;}
.padding-bottom-190px{padding-bottom:190px;}
.padding-bottom-180px{padding-bottom:180px;}
.padding-bottom-170px{padding-bottom:170px;}
.padding-bottom-160px{padding-bottom:160px;}
.padding-bottom-150px{padding-bottom:150px;}
.padding-bottom-140px{padding-bottom:140px;}
.padding-bottom-130px{padding-bottom:130px;}
.padding-bottom-120px{padding-bottom:120px;}
.padding-bottom-115px{padding-bottom:115px;}
.padding-bottom-110px{padding-bottom:110px;}
.padding-bottom-100px{padding-bottom:100px;}
.padding-bottom-95px{padding-bottom:95px;}
.padding-bottom-90px{padding-bottom:90px;}
.padding-bottom-85px{padding-bottom:85px;}
.padding-bottom-80px{padding-bottom:80px;}
.padding-bottom-75px{padding-bottom:75px;}
.padding-bottom-70px{padding-bottom:70px;}
.padding-bottom-60px{padding-bottom:60px;}
.padding-bottom-50px{padding-bottom:50px;}
.padding-bottom-45px{padding-bottom:45px;}
.padding-bottom-40px{padding-bottom:40px;}
.padding-bottom-35px{padding-bottom:35px;}
.padding-bottom-30px{padding-bottom:30px;}
.padding-bottom-20px{padding-bottom:20px;}
.padding-bottom-10px{padding-bottom:10px;}
.padding-right-150px{padding-right:150px;}
.padding-right-140px{padding-right:140px;}
.padding-right-130px{padding-right:130px;}
.padding-right-120px{padding-right:120px;}
.padding-right-110px{padding-right:110px;}
.padding-right-100px{padding-right:100px;}
.padding-right-95px{padding-right:95px;}
.padding-right-90px{padding-right:90px;}
.padding-right-85px{padding-right:85px;}
.padding-right-80px{padding-right:80px;}
.padding-right-70px{padding-right:70px;}
.padding-right-60px{padding-right:60px;}
.padding-right-55px{padding-right:55px;}
.padding-right-50px{padding-right:50px;}
.padding-right-45px{padding-right:45px;}
.padding-right-40px{padding-right:40px;}
.padding-right-30px{padding-right:30px;}
.padding-left-150px{padding-left:150px;}
.padding-left-140px{padding-left:140px;}
.padding-left-130px{padding-left:130px;}
.padding-left-120px{padding-left:120px;}
.padding-left-110px{padding-left:110px;}
.padding-left-100px{padding-left:100px;}
.padding-left-95px{padding-left:95px;}
.padding-left-90px{padding-left:90px;}
.padding-left-85px{padding-left:85px;}
.padding-left-80px{padding-left:80px;}
.padding-left-70px{padding-left:70px;}
.padding-left-60px{padding-left:60px;}
.padding-left-55px{padding-left:55px;}
.padding-left-50px{padding-left:50px;}
.padding-left-45px{padding-left:45px;}
.padding-left-40px{padding-left:40px;}
.padding-left-30px{padding-left:30px;}
.margin-top-200px{margin-top:200px;}
.margin-top-190px{margin-top:190px;}
.margin-top-180px{margin-top:180px;}
.margin-top-160px{margin-top:160px;}
.margin-top-150px{margin-top:150px;}
.margin-top-140px{margin-top:140px;}
.margin-top-130px{margin-top:130px;}
.margin-top-120px{margin-top:120px;}
.margin-top-110px{margin-top:110px;}
.margin-top-100px{margin-top:100px;}
.margin-top-95px{margin-top:95px;}
.margin-top-90px{margin-top:90px;}
.margin-top-85px{margin-top:85px;}
.margin-top-80px{margin-top:80px;}
.margin-top-70px{margin-top:70px;}
.margin-top-60px{margin-top:60px;}
.margin-top-55px{margin-top:55px;}
.margin-top-50px{margin-top:50px;}
.margin-top-45px{margin-top:45px;}
.margin-top-40px{margin-top:40px;}
.margin-top-35px{margin-top:35px;}
.margin-top-30px{margin-top:30px;}
.margin-top-28px{margin-top:28px;}
.margin-top-20px{margin-top:20px;}
.margin-top-10px{margin-top:10px;}
.margin-top-5px{margin-top:5px;}
.margin-bottom-200px{margin-bottom:200px;}
.margin-bottom-190px{margin-bottom:190px;}
.margin-bottom-180px{margin-bottom:180px;}
.margin-bottom-170px{margin-bottom:170px;}
.margin-bottom-160px{margin-bottom:160px;}
.margin-bottom-150px{margin-bottom:150px;}
.margin-bottom-140px{margin-bottom:140px;}
.margin-bottom-130px{margin-bottom:130px;}
.margin-bottom-120px{margin-bottom:120px;}
.margin-bottom-110px{margin-bottom:110px;}
.margin-bottom-100px{margin-bottom:100px;}
.margin-bottom-95px{margin-bottom:95px;}
.margin-bottom-90px{margin-bottom:90px;}
.margin-bottom-85px{margin-bottom:85px;}
.margin-bottom-80px{margin-bottom:80px;}
.margin-bottom-70px{margin-bottom:70px;}
.margin-bottom-60px{margin-bottom:60px;}
.margin-bottom-50px{margin-bottom:50px;}
.margin-bottom-55px{margin-bottom:55px;}
.margin-bottom-45px{margin-bottom:45px;}
.margin-bottom-40px{margin-bottom:40px;}
.margin-bottom-35px{margin-bottom:35px;}
.margin-bottom-30px{margin-bottom:30px;}
.margin-bottom-28px{margin-bottom:28px;}
.margin-bottom-20px{margin-bottom:20px;}
.margin-bottom-10px{margin-bottom:10px;}
.margin-bottom-5px{margin-bottom:5px;}
.radius-round{-webkit-border-radius:4px !important;-moz-border-radius:4px !important;border-radius:4px !important;}
.radius-rounded{-webkit-border-radius:30px !important;-moz-border-radius:30px !important;border-radius:30px !important;}
.radius--rounded{-webkit-border-radius:50% !important;-moz-border-radius:50% !important;border-radius:50% !important;}
.radius-none{-webkit-border-radius:0 !important;-moz-border-radius:0 !important;border-radius:0 !important;}
.before-none:before{display:none !important;}
.after-none:after{display:none !important;}
.after-none:after,
.before-none:before{display:none !important;}
.font-size-60{font-size:60px !important;}
.font-size-55{font-size:55px !important;}
.font-size-50{font-size:50px !important;}
.font-size-45{font-size:45px !important;}
.font-size-40{font-size:40px !important;}
.font-size-35{font-size:35px !important;}
.font-size-30{font-size:30px !important;}
.font-size-28{font-size:28px !important;}
.font-size-27{font-size:27px !important;}
.font-size-26{font-size:26px !important;}
.font-size-25{font-size:25px !important;}
.font-size-24{font-size:24px !important;}
.font-size-23{font-size:23px !important;}
.font-size-22{font-size:22px !important;}
.font-size-21{font-size:21px !important;}
.font-size-20{font-size:20px !important;}
.font-size-19{font-size:19px !important;}
.font-size-18{font-size:18px !important;}
.font-size-17{font-size:17px !important;}
.font-size-16{font-size:16px !important;}
.font-size-15{font-size:15px !important;}
.font-size-14{font-size:14px !important;}
.font-size-13{font-size:13px !important;}
.font-size-12{font-size:12px !important;}
.font-size-11{font-size:11px !important;}
.font-weight-thin{font-weight:100 !important;}
.font-weight-light{font-weight:300 !important;}
.font-weight-regular{font-weight:400 !important;}
.font-weight-medium{font-weight:500 !important;}
.font-weight-semi-bold{font-weight:600 !important;}
.font-weight-bold{font-weight:700 !important;}
.font-weight-black{font-weight:800 !important;}
.line-height-65{line-height:65px !important;}
.line-height-60{line-height:60px !important;}
.line-height-58{line-height:58px !important;}
.line-height-57{line-height:57px !important;}
.line-height-56{line-height:56px !important;}
.line-height-55{line-height:55px !important;}
.line-height-52{line-height:52px !important;}
.line-height-50{line-height:50px !important;}
.line-height-48{line-height:48px !important;}
.line-height-46{line-height:46px !important;}
.line-height-45{line-height:45px !important;}
.line-height-40{line-height:40px !important;}
.line-height-35{line-height:35px !important;}
.line-height-30{line-height:30px !important;}
.line-height-28{line-height:28px !important;}
.line-height-27{line-height:27px !important;}
.line-height-26{line-height:26px !important;}
.line-height-25{line-height:25px !important;}
.line-height-24{line-height:24px !important;}
.line-height-22{line-height:22px !important;}
.line-height-20{line-height:20px !important;}
.line-height-18{line-height:18px !important;}
.icon-element-bg-1{background-color:#51be78 !important;color:#fff !important;}
.icon-element-bg-2{background-color:#7E3CF9 !important;color:#fff !important;}
.icon-element-bg-3{background-color:#F68A03 !important;color:#fff !important;}
.icon-element-bg-4{background-color:#358FF7 !important;color:#fff !important;}
.icon-element-bg-5{background-color:#3E5B99 !important;color:#fff !important;}
.icon-element-bg-6{background-color:#3AAAE1 !important;color:#fff !important;}
.menu-category{margin-right: 30px;}
@media (max-width:1440px){.column-lld-half{-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
}
@media (max-width:1440px){.column-lld-third{-ms-flex:0 0 33%;flex:0 0 33%;max-width:33%;}
}
@media (max-width:1366px){.column-lmd-half{-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
}
@media (max-width:1199px){.column-lmd-2-half{-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
}
@media only screen and (min-width:768px) and (max-width:991px){.column-td-full{-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}
@media only screen and (min-width:480px) and (max-width:767px){.column-td-full{-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}.product-breadcrumb{display: block;}
}
@media only screen and (min-width:320px) and (max-width:479px){.column-td-full{-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}.product-breadcrumb{display: block;}
}
@media only screen and (min-width:768px) and (max-width:991px){.column-td-half{-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
}
@media only screen and (min-width:480px) and (max-width:767px){.column-md-full{-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}
@media only screen and (min-width:320px) and (max-width:479px){.column-md-full{-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}
@media only screen and (min-width:320px) and (max-width:479px){.column-md-2-full{-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}
.section-block{position:relative;height:1px;background-color:rgba(127, 136, 151, 0.2);}
.new-page-badge{display:inline-block;padding-right:8px;padding-left:8px;line-height:22px;background-color:#51be78;color:#fff;font-size:11px;-webkit-border-radius:30px;-moz-border-radius:30px;border-radius:30px;margin-top:2px;float:right;font-weight:700;}
.section-heading{position:relative;z-index:1;}
.section__meta{display:inline-block;text-transform:uppercase;font-size:14px;color:#51be78;background-color:rgba(81, 190, 120, 0.1);padding:0 10px;-webkit-border-radius:5px;-moz-border-radius:5px;border-radius:5px;letter-spacing:0.6px;margin-bottom:5px;line-height:26px;}
.section__metalight{background-color:rgba(255, 255, 255, 0.1);color:#fff;}
.section__title{font-size:36px;font-weight:700;}
@media (max-width:575px){.section__title{font-size:30px;}
}
@media only screen and (min-width:768px) and (max-width:991px){.section__title br{display:none;}
}
@media only screen and (min-width:480px) and (max-width:767px){.section__title br{display:none;}
}
@media only screen and (min-width:320px) and (max-width:479px){.section__title br{display:none;}
}
.section__desc{line-height:30px;margin-bottom:34px;font-size:18px;}
@media only screen and (min-width:768px) and (max-width:991px){.section__desc br{display:none;}
}
@media only screen and (min-width:480px) and (max-width:767px){.section__desc br{display:none;}
}
@media only screen and (min-width:320px) and (max-width:479px){.section__desc br{display:none;}
}
.section-divider{display:inline-block;position:relative;height:5px;-webkit-border-radius:30px;-moz-border-radius:30px;border-radius:30px;background-color:#51be78;width:90px;margin-top:25px;margin-bottom:25px;overflow:hidden;}
.section-divider:after{content:'';position:absolute;left:0;top:-1.1px;height:7px;width:8px;background-color:#fff;-webkit-animation-duration:3s;animation-duration:3s;-webkit-animation-timing-function:linear;animation-timing-function:linear;-webkit-animation-iteration-count:infinite;animation-iteration-count:infinite;-webkit-animation-name:dot-move;animation-name:dot-move;}
.section-divider-light{background-color:rgba(255, 255, 255, 0.1);}
.section-divider-light:after{background-color:#fff;}
.widget-title{font-size:22px;font-weight:600;}
.input-number-group{display:-webkit-flex;display:-ms-flexbox;display:flex;}
.input-number-group input[type=number]::-webkit-inner-spin-button,
.input-number-group input[type=number]::-webkit-outer-spin-button{-webkit-appearance:none;appearance:none;}
.input-number-group .input-number{outline:none;display:block;text-align:center;border:none;-webkit-border-radius:30px;-moz-border-radius:30px;border-radius:30px;}
.input-number-group .input-number,
.input-number-group .input-number-decrement,
.input-number-group .input-number-increment{background-color:rgba(127, 136, 151, 0.1);-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;font-weight:600;font-size:18px;color:#233d63;}
.input-number-group .input-number-decrement,
.input-number-group .input-number-increment{display:inline-block;width:35px;height:35px;line-height:35px;text-align:center;cursor:pointer;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;}
.input-number-group .input-number-decrement:hover,
.input-number-group .input-number-increment:hover{background-color:#51be78;color:#fff;}
.input-number-group .input-number-decrement{margin-right:0.5rem;margin-left:1rem;font-size:30px;}
.input-number-group .input-number-increment{margin-left:0.5rem;font-size:22px;}
.review-stars{font-size:15px;}
.review-stars li{margin-left:-1px;display:inline-block;}
.review-stars li span{color:#F68A03;}
.star-rating-wrap{color:#233d63;font-weight:500;}
.star-rating-wrap .star__rating{margin:0 1px 0 3px;}
.before-price{font-size:16px;margin-right:3px;color:#7f8897;text-decoration:line-through;}
.list-items li{position:relative;padding-left:18px;margin-bottom:8px;}
.list-items li:last-child{margin-bottom:0;}
.list-items li .la{color:#51be78;margin-right:6px;}
.list-items li a{color:#7f8897;}
.list-items li:after{position:absolute;content:"";top:50%;-webkit-transform:translateY(-50%);-moz-transform:translateY(-50%);-ms-transform:translateY(-50%);-o-transform:translateY(-50%);transform:translateY(-50%);left:0;width:6px;height:6px;background-color:rgba(127, 136, 151, 0.3);-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.social-profile li{display:inline-block;}
.social-profile li a{color:#233d63;display:block;width:35px;height:35px;line-height:35px;text-align:center;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;background-color:rgba(35, 61, 99, 0.1);-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;position:relative;z-index:1;}
.social-profile li a:hover{color:#fff;background-color:#51be78;}
.address-list li{margin-bottom:10px;}
.address-list li a{display:block;color:#7f8897;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.address-list li a:hover{color:#51be78;}
.address-list li a:hover i{color:#fff;background-color:#51be78;}
.address-list li i{color:#233d63;display:inline-block;width:36px;height:36px;line-height:36px;text-align:center;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;background-color:rgba(35, 61, 99, 0.1);-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;margin-right:5px;}
.widget-title-tooltip{background-color:rgba(127, 136, 151, 0.1);-webkit-border-radius:8px;-moz-border-radius:8px;border-radius:8px;position:relative;padding:12px 18px;text-align:center;}
.widget-title-tooltip i{margin-right:5px;font-size:17px;}
.widget-title-tooltip:after{position:absolute;content:"";bottom:-6px;left:50%;-webkit-transform:translateX(-50%) rotate(45deg);-moz-transform:translateX(-50%) rotate(45deg);-ms-transform:translateX(-50%) rotate(45deg);-o-transform:translateX(-50%) rotate(45deg);transform:translateX(-50%) rotate(45deg);width:12px;height:12px;background-color:#fff;}
#perticles-js,
#perticles-js-2{width:100%;height:100%;position:absolute;top:0;left:0;}
#scroll-top{position:fixed;right:0;bottom:150px;background-color:#fff;color:#7f8897;font-size:24px;z-index:9999;width:50px;height:45px;line-height:43px;-webkit-border-radius:30px 0 0 30px;-moz-border-radius:30px 0 0 30px;border-radius:30px 0 0 30px;text-align:center;cursor:pointer;-webkit-box-shadow:0 10px 40px rgba(14, 16, 48, 0.06);-moz-box-shadow:0 10px 40px rgba(14, 16, 48, 0.06);box-shadow:0 10px 40px rgba(14, 16, 48, 0.06);border:1px solid rgba(127, 136, 151, 0.3);border-right:0;padding-left:4px;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
#scroll-top:hover{background-color:#51be78;border-color:#51be78;color:#fff;}
.watch-video-btn{display:block;width:130px;height:130px;background-color:#fff;text-align:center;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;line-height:130px;color:#233d63;font-size:60px;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;position:relative;z-index:1;}
@media (max-width:320px){.watch-video-btn{width:100px;height:100px;line-height:100px;font-size:50px;}
}
.watch-video-btn:before, .watch-video-btn:after{position:absolute;content:'';top:-25px;left:-25px;bottom:-25px;right:-25px;background-color:rgba(255, 255, 255, 0.2);-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;-webkit-animation:play-button-pulse 1.8s linear infinite;-moz-animation:play-button-pulse 1.8s linear infinite;animation:play-button-pulse 1.8s linear infinite;opacity:0;z-index:-1;}
.watch-video-btn:after{-webkit-animation-delay:.6s;-moz-animation-delay:.6s;animation-delay:.6s;}
.watch-video-btn:hover{color:#51be78;}
.text-outline{-webkit-text-stroke-width:1px;-webkit-text-stroke-color:rgba(255, 255, 255, 0.1);-ms-text-stroke-color:rgba(255, 255, 255, 0.1);-o-text-stroke-color:rgba(255, 255, 255, 0.1);font-size:120px;color:transparent;position:absolute;letter-spacing:-0.02em;bottom:40px;left:50%;width:100%;-webkit-transform:translateX(-50%);-moz-transform:translateX(-50%);-ms-transform:translateX(-50%);-o-transform:translateX(-50%);transform:translateX(-50%);z-index:-1;text-transform:capitalize;font-weight:700;}
@media (max-width:1199px){.text-outline{display:none;}
}
.sort-ordering{position:relative;width:190px;}
.sort-ordering .bootstrap-select{width:100% !important;}
.sort-ordering .bootstrap-select.show .dropdown-toggle{border-color:#51be78 !important;}
.sort-ordering .dropdown-toggle{padding:12px 20px;border-color:rgba(127, 136, 151, 0.2) !important;background-color:#fff !important;color:#7f8897 !important;}
.sort-ordering .dropdown-toggle:focus{outline:none !important;-webkit-box-shadow:0 0 0 0 !important;-moz-box-shadow:0 0 0 0 !important;box-shadow:0 0 0 0 !important;border-color:#51be78;}
.sort-ordering .dropdown-menu{border:1px solid rgba(127, 136, 151, 0.2);}
.sort-ordering .dropdown-menu .bs-searchbox .form-control{border-color:rgba(127, 136, 151, 0.2);-webkit-box-shadow:0 0 0 0;-moz-box-shadow:0 0 0 0;box-shadow:0 0 0 0;padding-left:8px;}
.sort-ordering .dropdown-menu .bs-searchbox .form-control:focus{border-color:rgba(127, 136, 151, 0.5);}
.sort-ordering .dropdown-menu .inner::-webkit-scrollbar{width:8px;}
.sort-ordering .dropdown-menu .inner::-webkit-scrollbar-track{background-color:rgba(128, 137, 150, 0.1);-webkit-border-radius:10px;-moz-border-radius:10px;border-radius:10px;}
.sort-ordering .dropdown-menu .inner::-webkit-scrollbar-thumb{background-color:rgba(128, 137, 150, 0.2);-webkit-border-radius:10px;-moz-border-radius:10px;border-radius:10px;}
.sort-ordering .dropdown-menu .inner::-webkit-scrollbar-thumb:hover{background-color:rgba(128, 137, 150, 0.6);}
.sort-ordering .dropdown-item{color:#7f8897;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;padding-right:15px;padding-left:15px;}
.sort-ordering .dropdown-item.active{background-color:#51be78;color:#fff;}
.sort-ordering .dropdown-item.active:hover{background-color:#51be78;color:#fff;}
.sort-ordering .dropdown-item:focus{outline:none;}
.sort-ordering .dropdown-item:hover{background-color:rgba(81, 190, 120, 0.1);color:#51be78;}
.user-form-short{width:auto;}
.tip{display:inline-block;cursor:help;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;color:#fff;font-size:15px;line-height:18px;width:18px;height:18px;text-align:center;background-color:#51be78;}
.radio-trigger{position:relative;padding-left:25px;margin-bottom:15px;cursor:pointer;font-size:16px;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;font-weight:500;color:#233d63;}
.radio-trigger input[type=radio]{position:absolute;opacity:0;cursor:pointer;height:0;width:0;}
.radio-trigger input[type=radio]:checked ~ .checkmark{border-color:#51be78;}
.radio-trigger input[type=radio]:checked ~ .checkmark:before{border-color:#51be78;}
.radio-trigger input[type=radio]:checked ~ .checkmark:after{-webkit-transform:scale(1);-moz-transform:scale(1);-ms-transform:scale(1);-o-transform:scale(1);transform:scale(1);opacity:1;visibility:visible;}
.radio-trigger .checkmark{position:absolute;top:4px;left:0;width:19px;height:19px;border:2px solid #BDBBBC;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.radio-trigger .checkmark:after{position:absolute;left:3px;top:3px;content:'';width:9px;height:9px;background-color:#51be78;opacity:0;visibility:hidden;-webkit-transform:scale(0.1);-moz-transform:scale(0.1);-ms-transform:scale(0.1);-o-transform:scale(0.1);transform:scale(0.1);-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.badge-label{background-color:#51be78;color:#fff;text-transform:uppercase;font-weight:600;display:inline-block;padding:4px 10px 4px 12px;-webkit-border-radius:4px 4px 4px 0;-moz-border-radius:4px 4px 4px 0;border-radius:4px 4px 4px 0;font-size:12px;position:relative;}
.badge-label:after{content:"";position:absolute;display:block;width:100%;height:100%;top:0;left:8px;z-index:-1;background-color:#51be78;-webkit-transform-origin:bottom left;transform-origin:bottom left;-webkit-transform:skew(12deg);-moz-transform:skew(12deg);-ms-transform:skew(12deg);-o-transform:skew(12deg);transform:skew(12deg);-webkit-border-radius:4px 0 0 0;-moz-border-radius:4px 0 0 0;border-radius:4px 0 0 0;}
.filter-bar{background-color:#fff;border:1px solid rgba(127, 136, 151, 0.2);-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;padding:20px 20px;-webkit-box-shadow:0 0 40px rgba(82, 85, 90, 0.06);-moz-box-shadow:0 0 40px rgba(82, 85, 90, 0.06);box-shadow:0 0 40px rgba(82, 85, 90, 0.06);}
@media (max-width:480px){.filter-bar{display:block !important;}
}
@media (max-width:480px){.filter-bar .sort-ordering{margin-left:auto;margin-right:auto;}
}
.filter-bar-tab{border:none;}
@media (max-width:480px){.filter-bar-tab{margin-bottom:20px;-ms-flex-pack:center;justify-content:center;}
}
.filter-bar-tab .nav-item{margin-left:0;margin-right:5px;margin-bottom:0;}
.filter-bar-tab .nav-item:nth-child(2){margin-right:15px;}
.filter-bar-tab .nav-item .nav-link{font-size:22px;padding:0;border:none;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;width:45px;height:45px;line-height:45px;background-color:rgba(81, 190, 120, 0.1);color:#51be78;}
.filter-bar-tab .nav-item .nav-link span{display:block;}
.filter-bar-tab .nav-item .nav-link.active{background-color:#51be78;color:#fff;}
.msg-action-dot .action-dot{color:rgba(127, 136, 151, 0.7);font-size:20px;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.msg-action-dot .action-dot:hover{color:#7f8897;}
.msg-action-dot .dropdown-menu{-webkit-border-radius:8px;-moz-border-radius:8px;border-radius:8px;border:none;padding:10px;-webkit-box-shadow:0 0 18px 0 rgba(0, 0, 0, 0.05);-moz-box-shadow:0 0 18px 0 rgba(0, 0, 0, 0.05);box-shadow:0 0 18px 0 rgba(0, 0, 0, 0.05);-webkit-transform:translate3d(0, 0, 0) !important;-moz-transform:translate3d(0, 0, 0) !important;-ms-transform:translate3d(0, 0, 0) !important;-o-transform:translate3d(0, 0, 0) !important;transform:translate3d(0, 0, 0) !important;min-width:140px;margin-top:30px;}
.msg-action-dot .dropdown-menu .dropdown-item{color:#7f8897;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;font-weight:500;padding:6px 14px;}
.msg-action-dot .dropdown-menu .dropdown-item:hover{background-color:rgba(127, 136, 151, 0.1);}
.msg-action-dot .dropdown-menu.show{-webkit-transform:translate3d(0, 0, 0) !important;-moz-transform:translate3d(0, 0, 0) !important;-ms-transform:translate3d(0, 0, 0) !important;-o-transform:translate3d(0, 0, 0) !important;transform:translate3d(0, 0, 0) !important;}
.edit-list li{display:inline-block;margin-right:5px;}
.edit-list li:last-child{margin-right:0;}
.edit-list li .edit-btn{background-color:rgba(127, 136, 151, 0.1);color:#7f8897;border-color:rgba(127, 136, 151, 0.3);}
.edit-list li .edit-btn:hover{border-color:rgba(127, 136, 151, 0.7);}
.edit-list li .view-btn{background-color:rgba(81, 190, 120, 0.1);border-color:rgba(81, 190, 120, 0.3);color:#51be78;}
.edit-list li .view-btn:hover{border-color:rgba(81, 190, 120, 0.7);}
.edit-list li .delete-btn{background-color:rgba(221, 73, 61, 0.1);border-color:rgba(221, 73, 61, 0.3);color:#DD493D;}
.edit-list li .delete-btn:hover{border-color:rgba(221, 73, 61, 0.7);}
.edit-list li .theme-btn{line-height:32px;font-size:13px;padding-right:8px;padding-left:8px;cursor:pointer;border-width:1px;}
.page-navigation{display:-webkit-flex;display:-ms-flex;display:flex;-ms-flex-align:center;align-items:center;}
.page-navigation .page-navigation-nav{background-color:#fff;-webkit-border-radius:30px;-moz-border-radius:30px;border-radius:30px;-webkit-box-shadow:0 0 40px rgba(82, 85, 90, 0.2);-moz-box-shadow:0 0 40px rgba(82, 85, 90, 0.2);box-shadow:0 0 40px rgba(82, 85, 90, 0.2);padding:10px 15px;margin-right:10px;margin-left:10px;}
.page-navigation .page-navigation-nav li{display:inline-block;font-weight:600;}
.page-navigation .page-navigation-nav li:hover .page-go-link,
.page-navigation .page-navigation-nav li.active .page-go-link{background-color:#51be78;color:#fff;}
.page-navigation .page-go-link,
.page-navigation .page-go{color:#51be78;font-size:16px;margin-left:3px;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;display:block;width:40px;height:40px;line-height:40px;text-align:center;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.page-navigation .page-go{background-color:#fff;font-size:20px;-webkit-box-shadow:0 0 40px rgba(82, 85, 90, 0.2);-moz-box-shadow:0 0 40px rgba(82, 85, 90, 0.2);box-shadow:0 0 40px rgba(82, 85, 90, 0.2);width:50px;height:50px;line-height:50px;}
@media (max-width:425px){.page-navigation .page-go{margin-left:auto;margin-right:auto;}
}
.page-navigation .page-go:hover{background-color:#51be78;color:#fff;}
.custom-checkbox{margin-bottom:7px;}
@media (max-width:375px){.custom-checkbox{margin-bottom:0;}
}
.custom-checkbox label{color:#7f8897;font-size:15px;font-weight:500;margin-bottom:0;}
.custom-checkbox label a{color:#51be78;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.custom-checkbox label a:hover{color:#42be6f;text-decoration:underline;}
.custom-checkbox input[type=checkbox]:not(:checked),
.custom-checkbox input[type=checkbox]:checked{position:absolute;left:-9999px;}
.custom-checkbox input[type=checkbox]:not(:checked) + label,
.custom-checkbox input[type=checkbox]:checked + label{position:relative;padding-left:26px;cursor:pointer;}
.custom-checkbox input[type=checkbox]:not(:checked) + label:before,
.custom-checkbox input[type=checkbox]:checked + label:before{content:"";position:absolute;left:0;top:5px;width:18px;height:18px;border:1px solid rgba(127, 136, 151, 0.4);background-color:#fff;-webkit-border-radius:2px;-moz-border-radius:2px;border-radius:2px;}
.custom-checkbox input[type=checkbox]:not(:checked) + label:after,
.custom-checkbox input[type=checkbox]:checked + label:after{content:'\f00c';position:absolute;top:0;left:3px;font-size:13px;color:#fff;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;font-family:FontAwesome,sans-serif;}
.custom-checkbox input[type=checkbox]:not(:checked) + label:after{opacity:0;visibility:hidden;}
.custom-checkbox input[type=checkbox]:checked + label:after{opacity:1;visibility:visible;}
.custom-checkbox input[type=checkbox]:checked + label:before{background-color:#51be78;border-color:#51be78;}
.rating-shared{font-family:'FontAwesome';}
.rating-shared > fieldset{display:inline-block;}
.rating-shared > fieldset:not(:checked) > input{position:absolute;clip:rect(0, 0, 0, 0);}
.rating-shared > fieldset:not(:checked) > label{float:right;width:21px;overflow:hidden;white-space:nowrap;cursor:pointer;font-size:18px;color:#F68A03;margin-bottom:0;}
.rating-shared > fieldset:not(:checked) > label:before{content:'\f006';}
.rating-shared > fieldset:not(:checked) > label:hover,
.rating-shared > fieldset:not(:checked) > label:hover ~ label{color:#F68A03;}
.rating-shared > fieldset:not(:checked) > label:hover:before,
.rating-shared > fieldset:not(:checked) > label:hover ~ label:before{content:'\f005';}
.rating-shared > fieldset > input:checked ~ label:before{content:'\f005';}
.rating-shared > fieldset > label:active{position:relative;top:2px;}
.rating-shared-box{background-color:rgba(246, 138, 3, 0.1);-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;padding:12px 15px 6px 20px;}
.rating-shared.rating-shared-2 > fieldset label{width:60px;line-height:60px;font-size:60px;margin-right:10px;}
@media (max-width:320px){.rating-shared.rating-shared-2 > fieldset label{font-size:30px;line-height:30px;width:30px;}
}
.typing-director{position:relative;}
.typing-director span{height:6px;width:6px;float:left;margin:0 1px;background-color:#7f8897;display:block;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;opacity:0.4;}
.typing-director span:nth-of-type(1){animation:1s blink infinite 0.3333s;}
.typing-director span:nth-of-type(2){animation:1s blink infinite 0.6666s;}
.typing-director span:nth-of-type(3){animation:1s blink infinite 0.9999s;}
.filter-by-rating li{display:inline-block;padding-right:20px;}
@media (max-width:400px){.filter-by-rating li{display:block;padding-right:0;padding-bottom:30px;}
}
.filter-by-rating li .la{color:#F68A03;font-size:16px;}
.filter-by-rating li:last-child{padding-right:0;}
.filter-by-rating li .review-label{display:block;position:relative;user-select:none;margin-top:6px;}
.filter-by-rating li .review-label input{opacity:0;position:absolute;cursor:pointer;}
.filter-by-rating li .review-label input:checked ~ .review-mark{border-color:#F68A03;}
.filter-by-rating li .review-label input:checked ~ .review-mark:before{opacity:1;-webkit-transform:scale(1);-moz-transform:scale(1);-ms-transform:scale(1);-o-transform:scale(1);transform:scale(1);}
.filter-by-rating li .review-label .review-mark{position:absolute;top:0;left:0;width:18px;height:18px;cursor:pointer;border-width:2px;border-style:solid;border-color:rgba(127, 136, 151, 0.2);border-image:initial;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.filter-by-rating li .review-label .review-mark:before{top:2px;left:2px;position:absolute;content:'';width:10px;height:10px;background-color:#F68A03;opacity:0;-webkit-transform:scale(0.1);-moz-transform:scale(0.1);-ms-transform:scale(0.1);-o-transform:scale(0.1);transform:scale(0.1);-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.filter-by-rating-2 li{display:block;padding-right:0;padding-bottom:10px;}
.filter-by-rating-2 li .review-label{display:inline-block;float:left;margin-right:26px;}
.theme-btn{font-size:15px;text-transform:uppercase;background-color:#51be78;color:#fff;padding:0 30px 0 30px;line-height:48px;-webkit-border-radius:5px;-moz-border-radius:5px;border-radius:5px;z-index:1;display:inline-block;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;border:1px solid #51be78;font-weight:500;}
.theme-btn:hover{background-color:#fff;color:#51be78;}
.theme-btn-light{color:#7f8897;padding-right:20px;padding-left:20px;line-height:45px;background-color:rgba(127, 136, 151, 0.1);border:1px solid rgba(127, 136, 151, 0.2);text-transform:capitalize;}
.theme-btn-light:hover{background-color:#51be78;color:#fff;}
.theme-btn-hover-light:hover{background-color:transparent;border-color:#fff;color:#fff;}
.icon-btn{width:43px;height:43px;line-height:41px;text-align:center;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;border:1px solid rgba(255, 255, 255, 0.7);font-size:22px;}
.icon-btn:hover{background-color:#fff;border-color:#fff;color:#51be78;}
.icon-element{color:#7E3CF9;font-size:50px;position:relative;width:80px;height:80px;line-height:85px;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;background-color:rgba(126, 60, 249, 0.1);text-align:center;-webkit-transition:all 0.4s;-moz-transition:all 0.4s;-ms-transition:all 0.4s;-o-transition:all 0.4s;transition:all 0.4s;display:inline-block;}
.icon--element{width:45px;height:45px;line-height:45px;font-size:24px;}
.icon-success{background-color:#51be78 !important;border-color:#51be78 !important;color:#fff !important;}
.icon-error{background-color:#DD493D !important;border-color:#DD493D !important;color:#fff !important;}
.btn-text{color:rgba(255, 255, 255, 0.7);-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;display:-webkit-flex;display:-ms-flex;display:flex;-ms-flex-align:center;align-items:center;}
@media (max-width:425px){.btn-text{display:block;text-align:center;margin-left:0;margin-top:20px;}
}
.btn-text:hover{color:#fff;}
.btn-text:hover .icon-btn{background-color:#fff;border-color:#fff;color:#51be78;}
.text-btn{text-transform:capitalize;color:#7E3CF9;font-weight:500;-webkit-transition:all 0.4s;-moz-transition:all 0.4s;-ms-transition:all 0.4s;-o-transition:all 0.4s;transition:all 0.4s;}
.play-button svg{width:70px;}
.link-collapsed{color:#51be78;display:-webkit-flex;display:-ms-flex;display:flex;-ms-flex-align:center;align-items:center;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;background-color:rgba(81, 190, 120, 0.1);-webkit-border-radius:30px;-moz-border-radius:30px;border-radius:30px;padding:4px 16px;}
.link-collapsed:hover{color:#fff;background-color:#51be78;}
.link-collapsed[aria-expanded="false"] .link-collapse-active,
.link-collapsed[aria-expanded="false"] .la-minus{display:none;}
.link-collapsed[aria-expanded="true"] .link-collapse-active,
.link-collapsed[aria-expanded="true"] .la-minus{display:block;}
.link-collapsed[aria-expanded="true"] .link-collapse-read-more,
.link-collapsed[aria-expanded="true"] .la-plus{display:none;}
.info-box{background-color:#fff;-webkit-box-shadow:0 0 40px rgba(14, 16, 48, 0.05);-moz-box-shadow:0 0 40px rgba(14, 16, 48, 0.05);box-shadow:0 0 40px rgba(14, 16, 48, 0.05);padding:50px 40px 42px 40px;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;-webkit-transition:all 0.4s;-moz-transition:all 0.4s;-ms-transition:all 0.4s;-o-transition:all 0.4s;transition:all 0.4s;position:relative;overflow:hidden;margin-bottom:30px;z-index:1;}
.info-box .hover-overlay:before, .info-box .hover-overlay:after{position:absolute;content:'';top:50%;left:-90px;width:540px;height:540px;z-index:-1;transition:all .5s ease;-webkit-transform:translateY(100%) rotate(10deg);-moz-transform:translateY(100%) rotate(10deg);-ms-transform:translateY(100%) rotate(10deg);-o-transform:translateY(100%) rotate(10deg);transform:translateY(100%) rotate(10deg);background-color:rgba(255, 255, 255, 0.2);}
@media only screen and (min-width:480px) and (max-width:767px){.info-box .hover-overlay:before, .info-box .hover-overlay:after{width:640px;}
}
.info-box .hover-overlay:after{top:70%;background-color:rgba(255, 255, 255, 0.3);}
.info-box .info__title{font-weight:700;text-transform:capitalize;font-size:22px;margin-top:25px;margin-bottom:20px;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.info-box .info__text{line-height:28px;margin-bottom:20px;-webkit-transition:all 0.4s;-moz-transition:all 0.4s;-ms-transition:all 0.4s;-o-transition:all 0.4s;transition:all 0.4s;}
.info-box:before{position:absolute;content:'';top:30%;left:-90px;width:540px;height:540px;background-color:rgba(255, 255, 255, 0.1);z-index:-1;transition:all .5s ease;-webkit-transform:translateY(100%) rotate(10deg);-moz-transform:translateY(100%) rotate(10deg);-ms-transform:translateY(100%) rotate(10deg);-o-transform:translateY(100%) rotate(10deg);transform:translateY(100%) rotate(10deg);}
@media only screen and (min-width:480px) and (max-width:767px){.info-box:before{width:640px;}
}
.info-box:hover{border-color:#51be78;background-color:#51be78;}
.info-box:hover .info__title,
.info-box:hover .info__text,
.info-box:hover .text-btn{color:#fff;}
.info-box:hover .icon-element{color:#51be78;background-color:#fff;}
.info-box:hover:before{transition-delay:.2s;-webkit-transform:translateY(0) rotate(10deg);-moz-transform:translateY(0) rotate(10deg);-ms-transform:translateY(0) rotate(10deg);-o-transform:translateY(0) rotate(10deg);transform:translateY(0) rotate(10deg);}
.info-box:hover .hover-overlay:before, .info-box:hover .hover-overlay:after{-webkit-transform:translateY(0) rotate(10deg);-moz-transform:translateY(0) rotate(10deg);-ms-transform:translateY(0) rotate(10deg);-o-transform:translateY(0) rotate(10deg);transform:translateY(0) rotate(10deg);}
.info-box:hover .hover-overlay:after{transition-delay:.1s;}
.info-box-color-2 .icon-element{color:#F68A03;background-color:rgba(246, 138, 3, 0.1);}
.info-box-color-2 .text-btn{color:#F68A03;}
.info-box-color-3 .icon-element{color:#358FF7;background-color:rgba(53, 143, 247, 0.1);}
.info-box-color-3 .text-btn{color:#358FF7;}
.info-area{position:relative;}
.info-area:after{position:absolute;content:"";bottom:-30px;left:50%;-webkit-transform:translateX(-50%) rotate(45deg);-moz-transform:translateX(-50%) rotate(45deg);-ms-transform:translateX(-50%) rotate(45deg);-o-transform:translateX(-50%) rotate(45deg);transform:translateX(-50%) rotate(45deg);width:60px;height:60px;background-color:#F7FAFD;}
@media only screen and (min-width:480px) and (max-width:767px){.category-area .section-heading{text-align:center;}
}
@media only screen and (min-width:320px) and (max-width:479px){.category-area .section-heading{text-align:center;}
}
@media only screen and (min-width:768px) and (max-width:991px){.category-area .btn-box.justify-content-end{-ms-flex-pack:start !important;justify-content:flex-start !important;}
}
@media only screen and (min-width:480px) and (max-width:767px){.category-area .btn-box.justify-content-end{-ms-flex-pack:center !important;justify-content:center !important;}
}
@media only screen and (min-width:320px) and (max-width:479px){.category-area .btn-box.justify-content-end{-ms-flex-pack:center !important;justify-content:center !important;}
}
@media only screen and (min-width:768px) and (max-width:991px){.category-wrapper{margin-top:60px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.category-wrapper{margin-top:60px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.category-wrapper{margin-top:60px;}
}
.category-item{-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;position:relative;margin-bottom:30px;overflow:hidden;}
.category-item img{width:100%;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.category-item .category-content{position:absolute;text-align:center;padding-top:50px;top:0;left:0;width:100%;height:100%;z-index:1;color:#fff;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;display:-webkit-flex;display:-ms-flex;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;}
.category-item .cat__title{color:#fff;text-transform:capitalize;font-size:22px;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;font-weight:700;}
.category-item .cat__title a{color:#fff;}
.category-item .cat__meta{margin-top:4px;text-transform:capitalize;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.category-item .theme-btn{background-color:#fff;color:#233d63;line-height:40px;padding-right:20px;padding-left:20px;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;font-size:13px;opacity:0;visibility:hidden;border-color:#fff;}
.category-item .theme-btn:hover{background-color:transparent;color:#fff;}
.category-item:after{position:absolute;left:0;top:0;height:100%;width:100%;background-color:#233d63;opacity:.80;content:"";-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.category-item:hover .category-content{padding-top:0;}
.category-item:hover .cat__meta{opacity:0;visibility:hidden;margin:0;}
.category-item:hover .theme-btn{opacity:1;visibility:visible;}
.category-item-layout-2{background-color:#fff;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;border:1px solid rgba(127, 136, 151, 0.2);overflow:hidden;}
.category-item-layout-2 .category-content{color:#233d63;position:inherit;top:auto;left:auto;-webkit-transform:translate(0, 0);-moz-transform:translate(0, 0);-ms-transform:translate(0, 0);-o-transform:translate(0, 0);transform:translate(0, 0);display:block;padding:40px 0 40px 0;}
.category-item-layout-2 .icon-element{color:#51be78;width:60px;height:60px;line-height:60px;background-color:rgba(81, 190, 120, 0.1);font-size:30px;}
.category-item-layout-2 .cat__title{color:#233d63;margin-top:20px;font-size:20px;}
.category-item-layout-2:before{position:absolute;content:'';top:0;left:0;width:100%;height:100%;background-image:url("../images/img31.jpg");background-size:cover;background-position:center;background-color:transparent;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;opacity:0;visibility:hidden;}
.category-item-layout-2:before, .category-item-layout-2:after{-webkit-transform:scale(1.1);-moz-transform:scale(1.1);-ms-transform:scale(1.1);-o-transform:scale(1.1);transform:scale(1.1);opacity:0;visibility:hidden;-webkit-transition:all 0.6s;-moz-transition:all 0.6s;-ms-transition:all 0.6s;-o-transition:all 0.6s;transition:all 0.6s;}
.category-item-layout-2:hover{border-color:transparent;}
.category-item-layout-2:hover .category-content{padding-top:40px;}
.category-item-layout-2:hover .icon-element{background-color:#fff;}
.category-item-layout-2:hover .cat__title{color:#fff;}
.category-item-layout-2:hover:after{opacity:.80;}
.category-item-layout-2:hover:before, .category-item-layout-2:hover:after{-webkit-transform:scale(1);-moz-transform:scale(1);-ms-transform:scale(1);-o-transform:scale(1);transform:scale(1);visibility:visible;}
.category-item-layout-2:hover:before{opacity:1;}
.category--item-layout-2{border:none;overflow:inherit;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;margin-bottom:10px;}
.category--item-layout-2 .category-content{padding-top:30px;padding-bottom:30px;}
.category--item-layout-2 .cat__title{font-size:16px;}
.category--item-layout-2::before, .category--item-layout-2::after{display:none;}
.category--item-layout-2:hover{background-color:#51be78;}
.category--item-layout-2:hover .category-content{padding-top:30px;}
.category--item-layout-2:hover .cat__title{color:#fff;}
.funfact-area{position:relative;z-index:1;}
@media only screen and (min-width:768px) and (max-width:991px){.funfact-area{padding-bottom:54px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.funfact-area{padding-bottom:54px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.funfact-area{padding-bottom:54px;}
}
.funfact-area:before, .funfact-area:after{position:absolute;content:'';bottom:0;left:0;width:200px;height:200px;background-position:center;background-image:url("../images/dots.png");-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;-webkit-transform:rotate(10deg);-moz-transform:rotate(10deg);-ms-transform:rotate(10deg);-o-transform:rotate(10deg);transform:rotate(10deg);background-size:cover;opacity:0.2;}
.funfact-area:after{top:-80px;left:auto;bottom:auto;right:0;}
.counter-item{margin-bottom:30px;position:relative;}
@media only screen and (min-width:480px) and (max-width:767px){.counter-item{margin-bottom:50px;}
}
.counter-item .count__icon{position:absolute;top:-20px;left:35px;font-size:70px;color:rgba(127, 136, 151, 0.1);}
@media only screen and (min-width:480px) and (max-width:767px){.counter-item .count__icon{left:50%;-webkit-transform:translateX(-50%);-moz-transform:translateX(-50%);-ms-transform:translateX(-50%);-o-transform:translateX(-50%);transform:translateX(-50%);top:-35px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.counter-item .count__icon{left:50%;-webkit-transform:translateX(-50%);-moz-transform:translateX(-50%);-ms-transform:translateX(-50%);-o-transform:translateX(-50%);transform:translateX(-50%);top:-35px;}
}
.counter-item .count__title{font-size:50px;font-weight:600;margin-bottom:10px;color:#7E3CF9;}
.counter-item .count__meta{font-size:15px;font-weight:500;text-transform:uppercase;letter-spacing:1px;}
.upload-btn-box .jFiler-input{width:170px;border:none;background-color:transparent;height:auto;font-size:14px;color:#7f8897;font-weight:500;}
.upload-btn-box .jFiler-input .jFiler-input-caption{display:none;}
.upload-btn-box .jFiler-input .jFiler-input-button{float:none;padding:10px 0;position:relative;color:#F7FAFD;}
.upload-btn-box .jFiler-input .jFiler-input-button:after{position:absolute;content:'Upload a Course';top:0;left:0;padding-top:10px;color:#7f8897;width:100%;height:100%;padding-left:30px;}
.upload-btn-box .jFiler-input .jFiler-input-button:before{position:absolute;content:"\f0ee";top:10px;left:20px;font-family:"FontAwesome";color:#7f8897;font-size:17px;}
.course-photo-btn .jFiler-input .jFiler-input-button:after{position:absolute;content:'Upload a Photo';}
.course-photo-btn .jFiler-input .jFiler-input-button:before{content:"\f03e";}
.msg-action-wrap .jFiler-input{width:50px;height:50px;margin:0;}
.msg-action-wrap .jFiler-input .jFiler-input-button{padding:0;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;}
.msg-action-wrap .jFiler-input .jFiler-input-button:after{display:none;}
.msg-action-wrap .jFiler-input .jFiler-input-button:before{content:"\f0c6";font-size:24px;top:50%;left:50%;-webkit-transform:translate(-50%, -50%);-moz-transform:translate(-50%, -50%);-ms-transform:translate(-50%, -50%);-o-transform:translate(-50%, -50%);transform:translate(-50%, -50%);}
.team-item{margin-bottom:30px;text-align:center;}
.team-item .team-img-box{position:relative;width:200px;height:200px;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;margin-right:auto;margin-left:auto;}
.team-item .team-img-box img{width:100%;height:100%;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;}
.team-item .team-img-box:after{position:absolute;content:'';top:0;left:0;width:100%;height:100%;background-color:#233d63;opacity:0;visibility:hidden;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;}
.team-item .social-profile{position:absolute;top:50%;left:0;width:100%;-webkit-transform:translateY(-40%);-moz-transform:translateY(-40%);-ms-transform:translateY(-40%);-o-transform:translateY(-40%);transform:translateY(-40%);opacity:0;visibility:hidden;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;text-align:center;z-index:1;}
.team-item .social-profile li{font-size:14px;opacity:0;visibility:hidden;-webkit-transform:translateY(60%);-moz-transform:translateY(60%);-ms-transform:translateY(60%);-o-transform:translateY(60%);transform:translateY(60%);-webkit-transition:all 1s cubic-bezier(0.23, 1, 0.32, 1);-moz-transition:all 1s cubic-bezier(0.23, 1, 0.32, 1);-ms-transition:all 1s cubic-bezier(0.23, 1, 0.32, 1);-o-transition:all 1s cubic-bezier(0.23, 1, 0.32, 1);transition:all 1s cubic-bezier(0.23, 1, 0.32, 1);}
.team-item .social-profile li a{width:34px;height:34px;line-height:34px;background-color:#fff;color:#233d63;}
.team-item .social-profile li a:hover{color:#fff;background-color:#51be78;}
.team-item .social-profile li:nth-child(1){-webkit-transition-delay:.1s;transition-delay:.1s;}
.team-item .social-profile li:nth-child(2){-webkit-transition-delay:.2s;transition-delay:.2s;}
.team-item .social-profile li:nth-child(3){-webkit-transition-delay:.3s;transition-delay:.3s;}
.team-item .social-profile li:nth-child(4){-webkit-transition-delay:.4s;transition-delay:.4s;}
.team-item .team-detail{padding:24px 40px 30px 40px;}
.team-item .team-detail .team__title{font-size:22px;text-transform:capitalize;font-weight:700;display:inline-block;margin-bottom:3px;}
.team-item .team-detail .team__title a{color:#233d63;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;display:block;position:relative;}
.team-item .team-detail .team__title a:hover{color:#51be78;}
.team-item .team-detail .team__meta{text-transform:capitalize;margin-bottom:15px;}
.team-item .team-detail .team__text{line-height:28px;margin-bottom:30px;}
.team-item .team-detail .theme-btn{-webkit-border-radius:30px;-moz-border-radius:30px;border-radius:30px;line-height:42px;}
.team-item:hover .social-profile{opacity:1;visibility:visible;-webkit-transform:translateY(-50%);-moz-transform:translateY(-50%);-ms-transform:translateY(-50%);-o-transform:translateY(-50%);transform:translateY(-50%);}
.team-item:hover .social-profile li{opacity:1;visibility:visible;-webkit-transform:translateY(0);-moz-transform:translateY(0);-ms-transform:translateY(0);-o-transform:translateY(0);transform:translateY(0);}
.team-item:hover .team-img-box:after{opacity:0.8;visibility:visible;}
.team-layout-2{background-color:#fff;-webkit-box-shadow:0px 0px 33px 0px rgba(46, 61, 73, 0.1);-moz-box-shadow:0px 0px 33px 0px rgba(46, 61, 73, 0.1);box-shadow:0px 0px 33px 0px rgba(46, 61, 73, 0.1);-webkit-border-radius:8px;-moz-border-radius:8px;border-radius:8px;padding:40px 10px;position:relative;}
.team-layout-2 .team-img-box{width:100px;height:100px;margin-bottom:20px;}
.team-layout-2 .team-img-box:after{display:none;}
.team-layout-2 .team-detail{padding:0;}
.team-layout-2 .team-detail .team__meta{margin-bottom:20px;}
.team-layout-2:after{position:absolute;content:"";top:0;left:50%;-webkit-transform:translateX(-50%);-moz-transform:translateX(-50%);-ms-transform:translateX(-50%);-o-transform:translateX(-50%);transform:translateX(-50%);width:60px;height:4px;background-color:rgba(127, 136, 151, 0.2);-webkit-border-radius:0 0 5px 5px;-moz-border-radius:0 0 5px 5px;border-radius:0 0 5px 5px;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.team-layout-2:hover:after{background-color:#51be78;}
.team-single-img{position:relative;z-index:1;}
@media only screen and (min-width:768px) and (max-width:991px){.team-single-img{margin-bottom:60px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.team-single-img{margin-bottom:60px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.team-single-img{margin-bottom:60px;}
}
.team-single-img .team__img{width:100%;-webkit-border-radius:15px 0 15px 15px;-moz-border-radius:15px 0 15px 15px;border-radius:15px 0 15px 15px;}
.team-single-img:after{position:absolute;content:'';height:33%;width:50%;left:-30px;bottom:-30px;z-index:-1;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;background-image:url("../images/dots.png");background-size:cover;background-position:center;opacity:0.2;}
.team-single-wrap{padding-left:30px;}
@media only screen and (min-width:768px) and (max-width:991px){.team-single-wrap{padding-left:0;}
}
@media only screen and (min-width:480px) and (max-width:767px){.team-single-wrap{padding-left:0;}
}
@media only screen and (min-width:320px) and (max-width:479px){.team-single-wrap{padding-left:0;}
}
.team-single-item{margin-bottom:30px;}
.team-single-item .team-address li:after{display:none;}
.team-single-item .number-count{font-size:20px;font-weight:700;}
.about-tab-wrap .tab-content .pane-body{background-color:#fff;padding:30px 35px;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;-webkit-box-shadow:0 0 40px rgba(82, 85, 90, 0.1);-moz-box-shadow:0 0 40px rgba(82, 85, 90, 0.1);box-shadow:0 0 40px rgba(82, 85, 90, 0.1);line-height:28px;}
.label-text{font-size:15px;color:#233d63;font-weight:600;position:relative;}
.contact-form-action .form-control{width:100%;line-height:inherit;padding:10px 20px 10px 45px;font-size:14px;color:#233d63;border-color:rgba(127, 136, 151, 0.2);font-weight:400;background-color:#fff;-webkit-box-shadow:0 0 0 0;-moz-box-shadow:0 0 0 0;box-shadow:0 0 0 0;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;-webkit-border-radius:5px;-moz-border-radius:5px;border-radius:5px;min-width: 250px;height: auto;}
.contact-form-action .form-control:focus{border-color:#51be78;}
.contact-form-action .message-control{height:150px;}
.contact-form-action .form-group{margin-bottom:20px;position:relative;}
.contact-form-action .form-group .submit-btn{position:absolute;top:50%;-webkit-transform:translateY(-50%);-moz-transform:translateY(-50%);-ms-transform:translateY(-50%);-o-transform:translateY(-50%);transform:translateY(-50%);right:0;padding:10px;background-color:transparent;border:none;color:#7f8897;font-size:20px;}
.contact-form-action .form-group .input-icon{position:absolute;top:15px;left:20px;font-size:18px;}
.contact-form-action .form-group .search-close-icon,
.contact-form-action .form-group .subscriber-btn,
.contact-form-action .form-group .search-icon{position:absolute;top:0;right:0;}
.contact-form-action .form-group .search-close-icon{color:#233d63;padding:15px;top:50%;-webkit-transform:translateY(-50%);-moz-transform:translateY(-50%);-ms-transform:translateY(-50%);-o-transform:translateY(-50%);transform:translateY(-50%);cursor:pointer;font-size:18px;}
.contact-form-action .form-group .search-icon{color:#233d63;padding:15px;top:50%;-webkit-transform:translateY(-50%) rotate(-90deg);-moz-transform:translateY(-50%) rotate(-90deg);-ms-transform:translateY(-50%) rotate(-90deg);-o-transform:translateY(-50%) rotate(-90deg);transform:translateY(-50%) rotate(-90deg);cursor:pointer;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;font-size:18px;background-color:transparent;border:none;}
.contact-form-action .form-group .search-icon:hover{color:#51be78;}
.contact-form-action .form-group .subscriber-btn{-webkit-border-radius:0 5px 5px 0;-moz-border-radius:0 5px 5px 0;border-radius:0 5px 5px 0;line-height:56px;border:none;}
.contact-form-action .form-group .subscriber-btn:hover{background-color:#7E3CF9;color:#fff;}
.account-assist .account__desc{position:relative;font-size:22px;}
.account-assist .account__desc:before, .account-assist .account__desc:after{position:absolute;content:'';left:0;top:50%;width:45%;height:1px;background-color:rgba(128, 137, 150, 0.2);-webkit-transform:translateY(-50%);-moz-transform:translateY(-50%);-ms-transform:translateY(-50%);-o-transform:translateY(-50%);transform:translateY(-50%);}
.account-assist .account__desc:after{left:auto;right:0;}
@media only screen and (min-width:768px) and (max-width:991px){.contact-form-wrap .section-heading{margin-bottom:50px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.contact-form-wrap .section-heading{margin-bottom:50px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.contact-form-wrap .section-heading{margin-bottom:50px;}
}
.comment{border-bottom:1px solid #eee;margin-bottom:25px;padding-bottom:25px;position:relative;}
@media only screen and (min-width:480px) and (max-width:767px){.comment{text-align:center;display:block;}
}
@media only screen and (min-width:320px) and (max-width:479px){.comment{text-align:center;display:block;}
}
@media only screen and (min-width:480px) and (max-width:767px){.comment .msg-action-dot{position:absolute;top:0;right:0;}
}
@media only screen and (min-width:320px) and (max-width:479px){.comment .msg-action-dot{position:absolute;top:0;right:0;}
}
.comment-avatar .avatar__img{width:100px;height:100px;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;margin-right:20px;}
@media only screen and (min-width:480px) and (max-width:767px){.comment-avatar .avatar__img{margin-right:0;}
}
@media only screen and (min-width:320px) and (max-width:479px){.comment-avatar .avatar__img{margin-right:0;}
}
@media only screen and (min-width:480px) and (max-width:767px){.comment-body{padding-right:0;}
}
@media only screen and (min-width:320px) and (max-width:479px){.comment-body{padding-right:0;}
}
.meta-data{position:relative;}
@media only screen and (min-width:480px) and (max-width:767px){.meta-data{margin-top:15px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.meta-data{margin-top:15px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.meta-data .meta-data-inner{-ms-flex-pack:justify;justify-content:center;}
}
@media only screen and (min-width:320px) and (max-width:479px){.meta-data .meta-data-inner{-ms-flex-pack:justify;justify-content:center;}
}
.meta-data .comment__author{font-size:20px;text-transform:capitalize;color:#233d63;display:inline-block;font-weight:600;}
.meta-data .comment__author a{color:#233d63;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.meta-data .comment__author a:hover{color:#51be78;}
.meta-data .comment__date{font-size:14px;}
.meta-data .review-stars{position:absolute;right:0;top:0;}
@media only screen and (min-width:480px) and (max-width:767px){.meta-data .review-stars{position:inherit;right:auto;top:auto;margin-top:4px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.meta-data .review-stars{position:inherit;right:auto;top:auto;margin-top:4px;}
}
.meta-data .review-stars li{display:inline-block;color:#F68A03;}
.meta-data .review-stars li:nth-child(4), .meta-data .review-stars li:nth-child(5){color:#7f8897;}
.comment-content{font-size:16px;color:#7f8897;margin-top:8px;margin-bottom:15px;line-height:28px;}
@media (max-width:600px){.comment-content{margin-bottom:20px;}
}
.comment-reply{display:-webkit-flex;display:-ms-flex;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between;}
@media only screen and (min-width:480px) and (max-width:767px){.comment-reply{display:block;text-align:center;}
}
@media only screen and (min-width:320px) and (max-width:479px){.comment-reply{display:block;text-align:center;}
}
.comment-reply .theme-btn{line-height:35px;padding-right:15px;padding-left:15px;-webkit-border-radius:30px;-moz-border-radius:30px;border-radius:30px;font-size:14px;background-color:rgba(127, 136, 151, 0.1);color:#7f8897;border:none;}
.comment-reply .theme-btn:hover{background-color:rgba(127, 136, 151, 0.3);}
@media only screen and (min-width:480px) and (max-width:767px){.helpful__action{margin-top:30px;-ms-flex-pack:justify;justify-content:center;}
}
@media only screen and (min-width:320px) and (max-width:479px){.helpful__action{margin-top:30px;-ms-flex-pack:justify;justify-content:center;}
}
.helpful__action .dropdown-menu{left:auto !important;right:0;}
.helpful__action .helpful__action-text{margin-right:4px;}
.helpful__action .btn{border:1px solid rgba(128, 137, 150, 0.4);-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;padding:0 12px;color:#7f8897;margin-left:4px;margin-right:4px;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;-webkit-box-shadow:0 0 0 0;-moz-box-shadow:0 0 0 0;box-shadow:0 0 0 0;}
.helpful__action .btn:hover{background-color:rgba(128, 137, 150, 0.1);color:#233d63;}
.comments-reply{margin-left:130px;}
@media (max-width:1199px){.comments-reply{margin-left:20px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.comments-reply{margin-left:0;}
}
@media only screen and (min-width:320px) and (max-width:479px){.comments-reply{margin-left:0;}
}
.review-list .review-stars{position:inherit;top:auto;right:auto;}
.review-list .comment-content{margin-top:15px;margin-bottom:20px;}
.clientlogo-area{position:relative;}
.clientlogo-area .stroke-line,
.clientlogo-area .stroke-line2{position:absolute;top:175px;left:50px;width:200px;display:inline-block;-webkit-transform:rotate(-55deg);-moz-transform:rotate(-55deg);-ms-transform:rotate(-55deg);-o-transform:rotate(-55deg);transform:rotate(-55deg);opacity:0.1;}
.clientlogo-area .stroke-line .stroke__line,
.clientlogo-area .stroke-line2 .stroke__line{background-color:#7f8897;height:2px;display:block;}
.clientlogo-area .stroke-line .stroke__line:nth-child(1), .clientlogo-area .stroke-line .stroke__line:nth-child(2),
.clientlogo-area .stroke-line2 .stroke__line:nth-child(1),
.clientlogo-area .stroke-line2 .stroke__line:nth-child(2){margin-bottom:18px;}
.clientlogo-area .stroke-line2{left:auto;right:50px;bottom:0;top:auto;-webkit-transform:rotate(55deg);-moz-transform:rotate(55deg);-ms-transform:rotate(55deg);-o-transform:rotate(55deg);transform:rotate(55deg);}
.client-logo-item{display:inline-block;}
.client-logo-item img{-webkit-filter:grayscale(100%);filter:grayscale(100%);-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.client-logo-item:hover img{-webkit-filter:grayscale(0);filter:grayscale(0);}
@media only screen and (min-width:768px) and (max-width:991px){.client-logo-2{margin-top:50px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.client-logo-2{margin-top:50px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.client-logo-2{margin-top:50px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.client-logo-2 .col-lg-4{width:50%;}
}
.client-logo-item-2{padding:10px 0;}
.client-logo-item-2 img{-webkit-filter:grayscale(0);filter:grayscale(0);}
.testimonial-carousel .owl-dots{text-align:center;margin-top:60px;}
.testimonial-carousel .owl-dots div{width:14px;height:14px;line-height:14px;background-color:rgba(127, 136, 151, 0.4);-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;display:inline-block;margin:0 2px;position:relative;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;-webkit-transform:scale(0.7);-moz-transform:scale(0.7);-ms-transform:scale(0.7);-o-transform:scale(0.7);transform:scale(0.7);}
.testimonial-carousel .owl-dots div.active, .testimonial-carousel .owl-dots div:hover{background-color:#51be78;-webkit-transform:scale(1);-moz-transform:scale(1);-ms-transform:scale(1);-o-transform:scale(1);transform:scale(1);}
.testimonial-item{position:relative;padding:40px 48px 40px 48px;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;background-color:#fff;-webkit-box-shadow:0 0 40px rgba(0, 0, 0, 0.01);-moz-box-shadow:0 0 40px rgba(0, 0, 0, 0.01);box-shadow:0 0 40px rgba(0, 0, 0, 0.01);-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;border:1px solid rgba(127, 136, 151, 0.2);}
@media (max-width:1199px){.testimonial-item{padding:20px;}
}
.testimonial-item .testimonial__desc{margin-top:20px;position:relative;}
.testimonial-item .testimonial__desc:after{position:absolute;content:"”";font-size:100px;line-height:0;bottom:-25px;right:-20px;color:#7f8897;font-weight:700;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;opacity:0.1;}
.testimonial-item .testimonial__desc-desc{line-height:30px;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.testimonial-item .testimonial-header{display:-webkit-flex;display:-ms-flex;display:flex;-ms-flex-align:center;align-items:center;padding-bottom:30px;border-bottom:1px solid rgba(127, 136, 151, 0.2);}
.testimonial-item .testimonial-header img{width:54px;height:54px;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;}
.testimonial-item .testimonial__name{position:relative;padding-left:14px;}
.testimonial-item .testimonial__name-title{font-size:20px;font-weight:600;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;margin-bottom:2px;}
.testimonial-item .testimonial__name-meta{font-size:15px;text-transform:capitalize;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.testimonial-item .review-stars{margin-left:6px;}
.testimonial-item .review-stars li{margin-left:-2px;}
.testimonial-item:hover{background-color:#51be78;border-color:#51be78;}
.testimonial-item:hover .testimonial__desc-desc,
.testimonial-item:hover .testimonial__name-title,
.testimonial-item:hover .testimonial__name-meta{color:#fff;}
.testimonial-item:hover .testimonial__name{border-bottom-color:rgba(255, 255, 255, 0.1);}
.testimonial-item:hover .testimonial__desc:after{color:#fff;}
.testimonial-carousel-2 .owl-nav div{position:absolute;top:50%;-webkit-transform:translateY(-50%);-moz-transform:translateY(-50%);-ms-transform:translateY(-50%);-o-transform:translateY(-50%);transform:translateY(-50%);width:35px;height:35px;line-height:34px;text-align:center;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;background-color:#fff;border:1px solid rgba(127, 136, 151, 0.2);-webkit-box-shadow:0 0 40px rgba(82, 85, 90, 0.1);-moz-box-shadow:0 0 40px rgba(82, 85, 90, 0.1);box-shadow:0 0 40px rgba(82, 85, 90, 0.1);font-size:18px;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
@media (max-width:1366px){.testimonial-carousel-2 .owl-nav div{top:38%;-webkit-transform:translateY(0);-moz-transform:translateY(0);-ms-transform:translateY(0);-o-transform:translateY(0);transform:translateY(0);}
}
@media (max-width:575px){.testimonial-carousel-2 .owl-nav div{top:auto;bottom:-50px;}
}
.testimonial-carousel-2 .owl-nav div:hover{background-color:#51be78;color:#fff;}
.testimonial-carousel-2 .owl-nav div.owl-prev{left:-17px;}
@media (max-width:575px){.testimonial-carousel-2 .owl-nav div.owl-prev{left:40%;-webkit-transform:translateX(-40%);-moz-transform:translateX(-40%);-ms-transform:translateX(-40%);-o-transform:translateX(-40%);transform:translateX(-40%);}
}
.testimonial-carousel-2 .owl-nav div.owl-next{left:auto;right:-17px;}
@media (max-width:575px){.testimonial-carousel-2 .owl-nav div.owl-next{right:40%;-webkit-transform:translateX(40%);-moz-transform:translateX(40%);-ms-transform:translateX(40%);-o-transform:translateX(40%);transform:translateX(40%);}
}
.testimonial-item-layout-2 .testimonial__desc{margin-top:0;}
.testimonial-item-layout-2 .testimonial__desc:after{bottom:0;}
.testimonial-item-layout-2 .testimonial-header{border-bottom:none;padding-bottom:0;padding-top:30px;}
@media only screen and (min-width:768px) and (max-width:991px){.testimonial-subtitle{padding-top:40px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.testimonial-subtitle{padding-top:40px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.testimonial-subtitle{padding-top:40px;}
}
.map-container #map{width:100%;height:500px;}
@media (max-width:481px){.map-container #map{height:400px;}
}
.get-start-area{position:relative;background-image:url("../image/img7.jpg");background-size:cover;background-position:center;background-attachment:fixed;z-index:2;}
.get-start-area:before{position:absolute;left:0;top:0;height:100%;width:100%;content:"";background-color:#233d63;opacity:.95;}
.get-start-area .box-icons,
.get-start-area .box-icons2{position:absolute;left:100px;bottom:75px;z-index:1;}
@media (max-width:575px){.get-start-area .box-icons,
.get-start-area .box-icons2{display:none;}
}
.get-start-area .box-icons div,
.get-start-area .box-icons2 div{position:absolute;border:2px solid rgba(255, 255, 255, 0.1);width:19px;height:19px;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;}
.get-start-area .box-icons div.box-one,
.get-start-area .box-icons2 div.box-one{left:-40px;bottom:50px;}
.get-start-area .box-icons div.box-two,
.get-start-area .box-icons2 div.box-two{left:0px;top:0;width:46px;height:46px;}
.get-start-area .box-icons div.box-three,
.get-start-area .box-icons2 div.box-three{left:50px;top:-150px;width:80px;height:80px;}
.get-start-area .box-icons div.box-four,
.get-start-area .box-icons2 div.box-four{left:-85px;top:-258px;width:97px;height:97px;}
.get-start-area .box-icons div.box-five,
.get-start-area .box-icons2 div.box-five{left:100px;top:2px;width:40px;height:40px;}
.get-start-area .box-icons2{left:auto;right:240px;bottom:auto;top:100px;-webkit-transform:rotate(90deg);-moz-transform:rotate(90deg);-ms-transform:rotate(90deg);-o-transform:rotate(90deg);transform:rotate(90deg);}
.get-start-content .section__title{font-size:60px;line-height:75px;}
@media only screen and (min-width:480px) and (max-width:767px){.get-start-content .section__title{font-size:50px;line-height:60px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.get-start-content .section__title{font-size:50px;line-height:60px;}
}
@media (max-width:425px){.get-start-content .section__title{font-size:40px;line-height:50px;}
}
@media only screen and (min-width:768px) and (max-width:991px){.get-start-area2{text-align:center;}
}
@media only screen and (min-width:480px) and (max-width:767px){.get-start-area2{text-align:center;}
}
@media only screen and (min-width:320px) and (max-width:479px){.get-start-area2{text-align:center;}
}
@media only screen and (min-width:768px) and (max-width:991px){.get-start-area2 .col-lg-10{-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}
.get-start-area2 .section-heading .section__title{font-size:50px;line-height:60px;}
@media (max-width:320px){.get-start-area2 .section-heading .section__title{font-size:40px;line-height:50px;}
}
.get-start-area2 .box-icons{top:75px;bottom:auto;}
.get-start-area2 .box-icons div.box-three{top:150px;}
.get-start-area2 .box-icons div.box-four{top:258px;}
.get-start-area2 .box-icons2{top:auto;bottom:75px;}
.get-start-area2 .box-icons2 div.box-five{left:-130px;}
@media only screen and (min-width:768px) and (max-width:991px){.get-start-area2 .promo-video-btn{margin-top:60px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.get-start-area2 .promo-video-btn{margin-top:60px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.get-start-area2 .promo-video-btn{margin-top:60px;}
}
@media only screen and (min-width:768px) and (max-width:991px){.get-start-area2 .promo-video-btn.justify-content-end{-ms-flex-pack:center !important;justify-content:center !important;}
}
@media only screen and (min-width:480px) and (max-width:767px){.get-start-area2 .promo-video-btn.justify-content-end{-ms-flex-pack:center !important;justify-content:center !important;}
}
@media only screen and (min-width:320px) and (max-width:479px){.get-start-area2 .promo-video-btn.justify-content-end{-ms-flex-pack:center !important;justify-content:center !important;}
}
.register-area{position:relative;z-index:1;}
.register-area:before, .register-area:after{position:absolute;content:'';right:55px;bottom:91px;width:200px;height:200px;background-image:url("../images/dots.png");background-position:center;background-size:cover;z-index:-1;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;opacity:0.3;}
@media only screen and (min-width:768px) and (max-width:991px){.register-area:before, .register-area:after{width:100px;height:100px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.register-area:before, .register-area:after{width:100px;height:100px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.register-area:before, .register-area:after{width:100px;height:100px;}
}
.register-area:after{right:auto;left:280px;bottom:71px;}
@media only screen and (min-width:768px) and (max-width:991px){.register-area:after{left:0;bottom:0;}
}
@media only screen and (min-width:480px) and (max-width:767px){.register-area:after{left:0;bottom:0;}
}
@media only screen and (min-width:320px) and (max-width:479px){.register-area:after{left:0;bottom:0;}
}
.register-heading{padding-left:90px;}
@media only screen and (min-width:768px) and (max-width:991px){.register-heading{margin-top:60px;padding-left:0;}
}
@media only screen and (min-width:480px) and (max-width:767px){.register-heading{margin-top:60px;padding-left:0;}
}
@media only screen and (min-width:320px) and (max-width:479px){.register-heading{margin-top:60px;padding-left:0;}
}
.register-form{background-color:#fff;-webkit-box-shadow:0 0 40px rgba(82, 85, 90, 0.1);-moz-box-shadow:0 0 40px rgba(82, 85, 90, 0.1);box-shadow:0 0 40px rgba(82, 85, 90, 0.1);-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;position:relative;}
.register-form .widget-title{background-color:#51be78;color:#fff;padding:30px 40px 30px 40px;-webkit-border-radius:4px 4px 0 0;-moz-border-radius:4px 4px 0 0;border-radius:4px 4px 0 0;font-size:28px;}
@media (max-width:320px){.register-form .widget-title{padding:20px;font-size:24px;}
}
.register-form form{padding:40px;}
@media (max-width:320px){.register-form form{padding:20px;}
}
.register-form:after{position:absolute;content:'';width:100%;top:0;left:0;height:2px;background-color:rgba(127, 136, 151, 0.1);}
.register-area2 .register-heading{padding-left:0;padding-right:90px;}
@media only screen and (min-width:768px) and (max-width:991px){.register-area2 .register-heading{padding-right:0;margin-top:0;margin-bottom:60px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.register-area2 .register-heading{padding-right:0;margin-top:0;margin-bottom:60px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.register-area2 .register-heading{padding-right:0;margin-top:0;margin-bottom:60px;}
}
.register-area2:after{left:50px;}
.register-area2:before{right:280px;}
.subscriber-area{position:relative;}
.subscriber-area .container{position: relative;z-index: 9;}
.subscriber-area .section__title{margin-bottom: 30px;}
@media only screen and (min-width:768px) and (max-width:991px){.subscriber-area .section-heading{text-align:center;}
}
@media only screen and (min-width:480px) and (max-width:767px){.subscriber-area .section-heading{text-align:center;}
}
@media only screen and (min-width:320px) and (max-width:479px){.subscriber-area .section-heading{text-align:center;}
}
.subscriber-area:after{position:absolute;content:"";top:0;left:0;width:100%;height:100%;background-color:#274672;}
.subscriber-form{height:100%;display:-webkit-flex;display:-ms-flex;display:flex;-ms-flex-align:center;align-items:center;position:relative;z-index:1;}
@media (max-width:320px){.subscriber-form{display:block;}
}
.subscriber-form .contact-form-action{width:100%;}
@media only screen and (min-width:768px) and (max-width:991px){.subscriber-form .contact-form-action{margin-top:40px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.subscriber-form .contact-form-action{margin-top:40px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.subscriber-form .contact-form-action{margin-top:40px;}
}
.subscriber-form .contact-form-action .form-control{margin-right:12px;border:none;}
@media (max-width:320px){.subscriber-form .contact-form-action .form-control{margin-right:0;margin-bottom:15px;}
}
.subscriber-form .contact-form-action .theme-btn{line-height:45px;}
@media (max-width:320px){.subscriber-form .contact-form-action .theme-btn{width:100%;text-align:center;}
}
.subscriber-form .contact-form-action .form-group{margin-bottom:0;}
@media (max-width:320px){.subscriber-form .contact-form-action .form-group{display:block !important;}
}
.footer-form{display:block;}
@media (max-width:375px){.btn-box-inner .theme-btn{padding-right:15px;padding-left:15px;}
}
@media (max-width:575px){.cta-area .column-lmd-half{-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}
.icon-box{background-color:#fff;-webkit-border-radius:100px;-moz-border-radius:100px;border-radius:100px;margin-bottom:30px;position:relative;padding:20px;-webkit-box-shadow:0 0 8px 0px rgba(0, 0, 0, 0.07);-moz-box-shadow:0 0 8px 0px rgba(0, 0, 0, 0.07);box-shadow:0 0 8px 0px rgba(0, 0, 0, 0.07);}
@media (max-width:600px){.icon-box{display:block !important;text-align:center;-webkit-border-radius:8px;-moz-border-radius:8px;border-radius:8px;}
}
.icon-box .icon-element{width:80px;height:80px;line-height:80px;font-size:40px;margin-right:20px;}
@media (max-width:600px){.icon-box .icon-element{margin-right:auto;margin-left:auto;margin-bottom:15px;}
}
.icon-box .info__title{font-weight:500;font-size:18px;color:#7f8897;}
.icon-box .info__count{font-size:26px;font-weight:700;color:#233d63;}
.icon-box-layout-2{-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;}
.icon-box-layout-2 .info__title,
.icon-box-layout-2 .info__count{color:#fff;}
.icon-box-layout-2 .icon-element{background-color:rgba(255, 255, 255, 0.2);color:#fff;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;}
.icon-box-layout-2:hover .icon-element{background-color:#fff;color:#233d63;}
.icon-boxs .icon-element{font-size:30px;width:50px;height:50px;line-height:50px;}
.icon-boxs .icon__title{margin-top:15px;font-weight:600;font-size:20px;}
.icon-boxs:hover .icon-element{background-color:#51be78;color:#fff;}
.icon-bg-1{color:#51be78;background-color:rgba(81, 190, 120, 0.1);}
.icon-bg-2{color:#DD493D;background-color:rgba(221, 73, 61, 0.1);}
.icon-bg-3{color:#3E5B99;background-color:rgba(62, 91, 153, 0.1);}
.info-icon-box{margin-bottom:40px;}
.info-icon-box .icon-element{width:50px;height:50px;line-height:50px;font-size:28px;margin-bottom:12px;}
.info-icon-box .widget-title{font-size:18px;}
.info-icon-box:hover .icon-element{background-color:#51be78;color:#fff;}
.faq-panel{margin-bottom:10px;}
.faq-panel.is-active .faq-heading .faq__title{background-color:#51be78;color:#fff;}
.faq-panel.is-active .faq-heading .faq__title i{-webkit-transform:rotate(-90deg);-moz-transform:rotate(-90deg);-ms-transform:rotate(-90deg);-o-transform:rotate(-90deg);transform:rotate(-90deg);}
.faq-heading .faq__title{color:#233d63;width:100%;cursor:pointer;padding:20px 30px 18px 30px;display:-webkit-flex;display:-ms-flex;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between;font-weight:500;font-size:20px;background-color:rgba(127, 136, 151, 0.1);position:relative;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
@media (max-width:375px){.faq-heading .faq__title{font-size:60px;}
}
@media (max-width:400px){.faq-heading .faq__title{font-size:16px;}
}
@media (max-width:400px){.faq-heading .faq__title{padding-right:20px;padding-left:20px;}
}
.faq-heading .faq__title i{-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.faq-heading .faq__title:hover{background-color:#51be78;color:#fff;}
.faq-content{padding:20px;display:none;}
.faq-content .faq__desc{font-size:16px;color:#7f8897;line-height:28px;}
@media only screen and (min-width:768px) and (max-width:991px){.img-box{margin-top:55px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.img-box{margin-top:55px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.img-box{margin-top:55px;}
}
.img-box img{width:100%;position:absolute;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;}
@media only screen and (min-width:768px) and (max-width:991px){.img-box img{position:inherit;}
}
@media only screen and (min-width:480px) and (max-width:767px){.img-box img{position:inherit;}
}
@media only screen and (min-width:320px) and (max-width:479px){.img-box img{position:inherit;}
}
.img-box img:nth-child(1){top:0;right:0;margin-right:-352px;-webkit-border-radius:0;-moz-border-radius:0;border-radius:0;}
@media only screen and (min-width:768px) and (max-width:991px){.img-box img:nth-child(1){margin-right:auto;right:auto;top:auto;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;margin-bottom:30px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.img-box img:nth-child(1){margin-right:auto;right:auto;top:auto;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;margin-bottom:30px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.img-box img:nth-child(1){margin-right:auto;right:auto;top:auto;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;margin-bottom:30px;}
}
.img-box img:nth-child(2){margin-top:230px;margin-left:60px;border:20px solid #fff;}
@media only screen and (min-width:768px) and (max-width:991px){.img-box img:nth-child(2){margin-top:auto;margin-left:auto;border:none;}
}
@media only screen and (min-width:480px) and (max-width:767px){.img-box img:nth-child(2){margin-top:auto;margin-left:auto;border:none;}
}
@media only screen and (min-width:320px) and (max-width:479px){.img-box img:nth-child(2){margin-top:auto;margin-left:auto;border:none;}
}
.img-box-2{height:100%;position:relative;}
@media only screen and (min-width:768px) and (max-width:991px){.img-box-2{margin-top:0;margin-bottom:60px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.img-box-2{margin-top:0;margin-bottom:60px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.img-box-2{margin-top:0;margin-bottom:60px;}
}
.img-box-2 img{-webkit-box-shadow:0 0 40px rgba(82, 85, 90, 0.2);-moz-box-shadow:0 0 40px rgba(82, 85, 90, 0.2);box-shadow:0 0 40px rgba(82, 85, 90, 0.2);-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;}
@media (max-width:1199px){.img-box-2 img{position:inherit;}
}
.img-box-2 img:nth-child(1){left:0;margin-right:0;width:59%;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;}
@media (max-width:1199px){.img-box-2 img:nth-child(1){width:100%;left:auto;right:auto;margin-right:0;}
}
@media only screen and (min-width:768px) and (max-width:991px){.img-box-2 img:nth-child(1){margin-bottom:0;}
}
@media only screen and (min-width:480px) and (max-width:767px){.img-box-2 img:nth-child(1){margin-bottom:0;}
}
@media only screen and (min-width:320px) and (max-width:479px){.img-box-2 img:nth-child(1){margin-bottom:0;}
}
.img-box-2 img:nth-child(2){margin-left:0;margin-top:0;width:40%;border:none;top:37px;right:90px;}
@media (max-width:1199px){.img-box-2 img:nth-child(2){width:100%;margin-top:30px;top:auto;right:auto;}
}
.img-box-2 img:nth-child(3){bottom:40px;right:0;width:65%;margin-right:90px;}
@media (max-width:1199px){.img-box-2 img:nth-child(3){display:none;}
}
@media only screen and (min-width:768px) and (max-width:991px){.img-box-2 img:nth-child(3){display:block;width:100%;margin-right:0;bottom:auto;right:auto;margin-top:30px;margin-bottom:30px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.img-box-2 img:nth-child(3){display:block;width:100%;margin-right:0;bottom:auto;right:auto;margin-top:30px;margin-bottom:30px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.img-box-2 img:nth-child(3){display:block;width:100%;margin-right:0;bottom:auto;right:auto;margin-top:30px;margin-bottom:30px;}
}
.img-box-2 img:nth-child(4){bottom:0;left:0;width:45%;}
@media (max-width:1199px){.img-box-2 img:nth-child(4){display:none;}
}
@media only screen and (min-width:768px) and (max-width:991px){.img-box-2 img:nth-child(4){display:block;width:100%;left:auto;bottom:auto;}
}
@media only screen and (min-width:480px) and (max-width:767px){.img-box-2 img:nth-child(4){display:block;width:100%;left:auto;bottom:auto;}
}
@media only screen and (min-width:320px) and (max-width:479px){.img-box-2 img:nth-child(4){display:block;width:100%;left:auto;bottom:auto;}
}
.img-box-3{margin-bottom:20px;}
.img-box-3 img{position:inherit;height:100%;}
.img-box-3 img:nth-child(1){top:auto;right:auto;margin-right:0;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;}
.img-box-3 img:nth-child(2){margin-top:0;margin-left:0;border:none;}
.img-box-3 .img-content{width:240px;height:240px;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;position:relative;}
.img-box-3 .img-content-inner{position:absolute;top:0;left:0;width:100%;height:100%;background-color:#233d63;opacity:0.9;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;display:-webkit-flex;display:-ms-flex;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:center;color:#fff;text-align:center;}
.img-box-3 .img-content-inner .widget-title{color:#fff;margin-bottom:4px;}
.img-box-3 .img-content-inner span{font-weight:600;}
@media only screen and (min-width:768px) and (max-width:991px){.about-content-box{padding-left:0;padding-top:20px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.about-content-box{padding-left:0;padding-top:20px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.about-content-box{padding-left:0;padding-top:20px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.about-content-box .list-items{padding-bottom:40px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.about-content-box .list-items{padding-bottom:40px;}
}
.about-content-box .list-items li{padding-left:0;}
.about-content-box .list-items li:after{display:none;}
.img-box-4 img{position:inherit;-webkit-border-radius:12px;-moz-border-radius:12px;border-radius:12px;}
.img-box-4 img:nth-child(1){top:auto;right:auto;margin-right:0;-webkit-border-radius:12px;-moz-border-radius:12px;border-radius:12px;}
@media only screen and (min-width:768px) and (max-width:991px){.img-box-4 img:nth-child(1){margin-bottom:0;}
}
.img-box-5{position:relative;z-index:1;}
.img-box-5 img{position:inherit;-webkit-border-radius:12px;-moz-border-radius:12px;border-radius:12px;}
.img-box-5 img:nth-child(1){top:auto;right:auto;margin-right:0;-webkit-border-radius:12px;-moz-border-radius:12px;border-radius:12px;}
.img-box-5:before, .img-box-5:after{position:absolute;content:'';}
.img-box-5:before{width:200px;height:200px;bottom:-33px;right:-33px;background-image:url("../images/dots.png");background-size:cover;background-position:center;opacity:0.2;z-index:-1;}
.img-box-5:after{top:0;left:35%;height:100%;width:10px;background-color:#fff;}
.about-img-wrap{-webkit-transform:rotate(5deg);-moz-transform:rotate(5deg);-ms-transform:rotate(5deg);-o-transform:rotate(5deg);transform:rotate(5deg);}
@media only screen and (min-width:480px) and (max-width:767px){.about-img-wrap{-webkit-transform:rotate(0);-moz-transform:rotate(0);-ms-transform:rotate(0);-o-transform:rotate(0);transform:rotate(0);}
}
@media only screen and (min-width:320px) and (max-width:479px){.about-img-wrap{-webkit-transform:rotate(0);-moz-transform:rotate(0);-ms-transform:rotate(0);-o-transform:rotate(0);transform:rotate(0);}
}
@media only screen and (min-width:480px) and (max-width:767px){.about-img-wrap .img-box{margin-top:0;}
}
@media only screen and (min-width:320px) and (max-width:479px){.about-img-wrap .img-box{margin-top:0;}
}
@media only screen and (min-width:480px) and (max-width:767px){.about-img-wrap .img-box .img-content{margin-left:auto;margin-right:auto;}
}
@media only screen and (min-width:320px) and (max-width:479px){.about-img-wrap .img-box .img-content{margin-left:auto;margin-right:auto;}
}
.about-img-wrap-2{-webkit-transform:rotate(0);-moz-transform:rotate(0);-ms-transform:rotate(0);-o-transform:rotate(0);transform:rotate(0);}
@media only screen and (min-width:768px) and (max-width:991px){.about-img-wrap-2 .img-box{margin-top:0;}
}
.about-img-wrap-2 .col-lg-6:nth-child(2) .img-box{margin-top:30px;}
@media only screen and (min-width:480px) and (max-width:767px){.about-img-wrap-2 .col-lg-6:nth-child(2) .img-box{margin-top:0;}
}
@media only screen and (min-width:320px) and (max-width:479px){.about-img-wrap-2 .col-lg-6:nth-child(2) .img-box{margin-top:0;}
}
.about-img-wrap-2 .col-lg-6:nth-child(4) .img-box{margin-top:30px;}
@media only screen and (min-width:480px) and (max-width:767px){.about-img-wrap-2 .col-lg-6:nth-child(4) .img-box{margin-top:0;}
}
@media only screen and (min-width:320px) and (max-width:479px){.about-img-wrap-2 .col-lg-6:nth-child(4) .img-box{margin-top:0;}
}
.about-img-wrap-3{-webkit-transform:rotate(0);-moz-transform:rotate(0);-ms-transform:rotate(0);-o-transform:rotate(0);transform:rotate(0);}
@media only screen and (min-width:480px) and (max-width:767px){.about-img-wrap-3 .img-box{margin-top:55px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.about-img-wrap-3 .img-box{margin-top:55px;}
}
.benefit-area{position:relative;z-index:1;}
@media only screen and (min-width:768px) and (max-width:991px){.benefit-area{padding-bottom:120px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.benefit-area{padding-bottom:120px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.benefit-area{padding-bottom:120px;}
}
.benefit-area:after{position:absolute;content:'';right:200px;bottom:45px;width:220px;height:341px;background-image:url("../images/dots.png");background-position:center;background-size:cover;z-index:-1;opacity:0.1;}
@media only screen and (min-width:320px) and (max-width:479px){.benefit-area .btn-box{text-align:center;}
}
.benefit-area2 .benefit-heading{padding-top:0;}
@media (max-width:1199px){.benefit-area2 .benefit-heading{padding-left:30px;}
}
@media only screen and (min-width:768px) and (max-width:991px){.benefit-area2 .benefit-heading{padding-left:0;}
}
@media only screen and (min-width:480px) and (max-width:767px){.benefit-area2 .benefit-heading{padding-left:0;}
}
@media only screen and (min-width:320px) and (max-width:479px){.benefit-area2 .benefit-heading{padding-left:0;}
}
.benefit-area2:after{right:auto;left:290px;bottom:83px;}
.mission-area{position:relative;}
.mission-area:after{position:absolute;content:"";bottom:-25px;left:50%;width:50px;height:50px;background-color:#F7FAFD;-webkit-transform:translateX(-50%) rotate(45deg);-moz-transform:translateX(-50%) rotate(45deg);-ms-transform:translateX(-50%) rotate(45deg);-o-transform:translateX(-50%) rotate(45deg);transform:translateX(-50%) rotate(45deg);}
.story-img-wrap .col-lg-4:nth-child(2) .story-img-item{margin-top:90px;}
@media only screen and (min-width:768px) and (max-width:991px){.story-img-wrap .col-lg-4:nth-child(2) .story-img-item{margin-top:0;}
}
@media only screen and (min-width:480px) and (max-width:767px){.story-img-wrap .col-lg-4:nth-child(2) .story-img-item{margin-top:0;}
}
@media only screen and (min-width:320px) and (max-width:479px){.story-img-wrap .col-lg-4:nth-child(2) .story-img-item{margin-top:0;}
}
.story-img-item{position:relative;width:300px;height:300px;margin-left:auto;margin-right:auto;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;margin-bottom:30px;}
@media only screen and (min-width:320px) and (max-width:479px){.story-img-item{width:280px;height:280px;}
}
.story-img-item img{width:100%;height:100%;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;}
.story-img-item .story-video{position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%, -50%);-moz-transform:translate(-50%, -50%);-ms-transform:translate(-50%, -50%);-o-transform:translate(-50%, -50%);transform:translate(-50%, -50%);z-index:1;}
.story-img-item .story-video .btn-text{color:#fff;}
.story-img-item .story-video .icon-btn{width:80px;height:80px;line-height:80px;font-size:24px;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;border-width:2px;border-color:#fff;}
.story-img-item:after{position:absolute;content:"";top:0;left:0;width:100%;height:100%;background-color:#233d63;opacity:0.4;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;}
.fixed-nav .header-top{height:0;border:none;opacity:0;visibility:hidden;}
.fixed-nav .header-menu-content{position:fixed;top:0;width:100%;-webkit-box-shadow:0 0 40px rgba(82, 85, 90, 0.1);-moz-box-shadow:0 0 40px rgba(82, 85, 90, 0.1);box-shadow:0 0 40px rgba(82, 85, 90, 0.1);}
@media only screen and (min-width:480px) and (max-width:767px){.owl-dot-and-nav .owl-nav{display:none;}
}
@media only screen and (min-width:320px) and (max-width:479px){.owl-dot-and-nav .owl-nav{display:none;}
}
.owl-dot-and-nav .owl-nav div{position:absolute;top:50%;-webkit-transform:translateY(-50%);-moz-transform:translateY(-50%);-ms-transform:translateY(-50%);-o-transform:translateY(-50%);transform:translateY(-50%);width:50px;height:50px;line-height:50px;text-align:center;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;background-color:rgba(255, 255, 255, 0.1);font-size:18px;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.owl-dot-and-nav .owl-nav div:hover{background-color:#51be78;color:#fff;}
.owl-dot-and-nav .owl-nav div.owl-prev{left:20px;}
.owl-dot-and-nav .owl-nav div.owl-next{left:auto;right:20px;}
.owl-dot-and-nav .owl-dots{width:100%;text-align:center;}
.owl-dot-and-nav .owl-dots div{width:14px;height:14px;line-height:14px;border:2px solid #fff;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;display:inline-block;margin:0 3px;-webkit-transition:all 0.3s ease-in;-moz-transition:all 0.3s ease-in;-ms-transition:all 0.3s ease-in;-o-transition:all 0.3s ease-in;transition:all 0.3s ease-in;position:relative;}
.owl-dot-and-nav .owl-dots div.active, .owl-dot-and-nav .owl-dots div:hover{background-color:#51be78;border-color:#51be78;}
.avatar-sm{width:90px !important;height:90px !important;}
.bs-tooltip-auto[x-placement^=bottom] .arrow::before,
.bs-tooltip-bottom .arrow::before{border-bottom-color:#fff;}
.bs-tooltip-bottom > .tooltip-inner{background-color:#fff;color:#233d63;padding:10px 20px;font-weight:500;}
@media only screen and (min-width:480px) and (max-width:767px){.quiz-action-content{display:block !important;}
}
@media only screen and (min-width:320px) and (max-width:479px){.quiz-action-content{display:block !important;}
}
.quiz-nav li{margin-right:20px;}
.quiz-nav li:last-child{margin-right:0;}
.quiz-nav li img{width:40px;margin-right:15px;}
.quiz-nav li a{color:#7f8897;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.quiz-nav li a:hover{color:#51be78;}
.quiz-nav li p{line-height:22px;}
.quiz-nav li p a{color:#233d63;font-size:17px;}
.quiz-course-nav{position:relative;z-index:1;}
.quiz-course-nav li .icon-element{background-color:#fff;color:#233d63;width:50px;height:50px;line-height:50px;font-size:24px;}
.quiz-course-nav::before{position:absolute;content:"";left:0;right:0;top:calc(50% - 1px);height:2px;background-color:#fff;opacity:.1;z-index:-1;}
.quiz-action-nav{-webkit-box-shadow:0 0 40px rgba(82, 85, 90, 0.1);-moz-box-shadow:0 0 40px rgba(82, 85, 90, 0.1);box-shadow:0 0 40px rgba(82, 85, 90, 0.1);}
@media only screen and (min-width:480px) and (max-width:767px){.quiz-nav-btns{margin-top:10px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.quiz-nav-btns{margin-top:10px;}
}
@media only screen and (min-width:768px) and (max-width:991px){.quiz-nav-btns .theme-btn{margin-bottom:10px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.quiz-nav-btns .theme-btn{margin-bottom:10px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.quiz-nav-btns .theme-btn{margin-bottom:10px;}
}
.quiz-content .quiz-desc{width:700px;}
@media only screen and (min-width:768px) and (max-width:991px){.quiz-content .quiz-desc{width:auto;}
}
@media only screen and (min-width:480px) and (max-width:767px){.quiz-content .quiz-desc{width:auto;}
}
@media only screen and (min-width:320px) and (max-width:479px){.quiz-content .quiz-desc{width:auto;}
}
@media only screen and (min-width:768px) and (max-width:991px){.quiz-result-content{margin-bottom:30px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.quiz-result-content{margin-bottom:30px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.quiz-result-content{margin-bottom:30px;}
}
.quiz-result-list .icon-element{width:35px;height:35px;line-height:35px;background-color:#fff;border:1px solid rgba(128, 137, 150, 0.1);font-size:17px;color:#233d63;}
@media only screen and (min-width:768px) and (max-width:991px){.quiz-ans-content .btn-box{margin-bottom:50px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.quiz-ans-content .btn-box{margin-bottom:50px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.quiz-ans-content .btn-box{margin-bottom:50px;}
}
@media (max-width:320px){.quiz-ans-content .btn-box .theme-btn{margin-bottom:10px;}
}
.level-timeline{position:relative;}
@media only screen and (min-width:768px) and (max-width:991px){.level-timeline{margin-top:60px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.level-timeline{margin-top:60px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.level-timeline{margin-top:60px;}
}
.level-timeline .icon-element{position:relative;background-color:#eee;border:1px solid rgba(128, 137, 150, 0.1);font-size:20px;color:#233d63;margin-right:10px;margin-left:10px;}
.level-timeline .level-dot{position:absolute;top:-7px;left:50%;-webkit-transform:translateX(-50%);-moz-transform:translateX(-50%);-ms-transform:translateX(-50%);-o-transform:translateX(-50%);transform:translateX(-50%);}
.level-timeline::before{position:absolute;content:"";background-color:#eee;z-index:-1;width:70%;height:5px;top:50%;-webkit-transform:translate(-50%, -50%);-moz-transform:translate(-50%, -50%);-ms-transform:translate(-50%, -50%);-o-transform:translate(-50%, -50%);transform:translate(-50%, -50%);left:50%;}
.level-dot{width:12px;height:12px;display:inline-block;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;background-color:#eee;border:2px solid #fff;}
.level-active{background-color:#51be78 !important;color:#fff !important;width:60px !important;height:60px !important;line-height:60px !important;border:none;}
.level-active .level-dot{background-color:#51be78;}
@media only screen and (min-width:480px) and (max-width:767px){.tags-item{display:block !important;}
}
@media only screen and (min-width:320px) and (max-width:479px){.tags-item{display:block !important;}
}
.tags-item ul li{margin-right:2px;}
@media (max-width:425px){.tags-item ul li{display:inline-block;}
}
.tags-item ul li span{color:#233d63;font-weight:600;}
@media only screen and (min-width:480px) and (max-width:767px){.tags-item ul.list-items{margin-bottom:20px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.tags-item ul.list-items{margin-bottom:20px;}
}
.author-wrap .comment-avatar .avatar__img{width:140px;height:140px;}
.feature-area{position:relative;z-index:2;}
.feature-content-wrap{margin-top:-60px;}
@media (max-width:481px){.feature-content-wrap{margin-top:0px;}
}
@media (max-width:480px){.feature-content-wrap{margin-top:0px;}
}
@media (max-width:425px){.feature-content-wrap{margin-top:0px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.feature-content-wrap .col-sm-6{-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}
@media only screen and (min-width:320px) and (max-width:479px){.feature-content-wrap .col-sm-6{-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}
@media (max-width:575px){.course-area.padding-top-120px{padding-bottom:90px;}
}
.description-wrap .list-items li{font-size:15px;}
.course-carousel .owl-nav div{background-color:#fff;border:1px solid rgba(127, 136, 151, 0.2);-webkit-box-shadow:0 0 40px rgba(82, 85, 90, 0.1);-moz-box-shadow:0 0 40px rgba(82, 85, 90, 0.1);box-shadow:0 0 40px rgba(82, 85, 90, 0.1);}
@media (max-width:1366px){.course-carousel .owl-nav div{top:38%;-webkit-transform:translateY(0);-moz-transform:translateY(0);-ms-transform:translateY(0);-o-transform:translateY(0);transform:translateY(0);}
}
@media (max-width:575px){.course-carousel .owl-nav div{top:auto;bottom:-50px;}
}
.course-carousel .owl-nav div:hover{background-color:#51be78;color:#fff;}
.course-carousel .owl-nav div.owl-prev{left:-100px;}
@media (max-width:1366px){.course-carousel .owl-nav div.owl-prev{left:-25px;}
}
@media (max-width:575px){.course-carousel .owl-nav div.owl-prev{left:40%;-webkit-transform:translateX(-40%);-moz-transform:translateX(-40%);-ms-transform:translateX(-40%);-o-transform:translateX(-40%);transform:translateX(-40%);}
}
.course-carousel .owl-nav div.owl-next{right:-100px;}
@media (max-width:1366px){.course-carousel .owl-nav div.owl-next{right:-25px;}
}
@media (max-width:575px){.course-carousel .owl-nav div.owl-next{right:40%;-webkit-transform:translateX(40%);-moz-transform:translateX(40%);-ms-transform:translateX(40%);-o-transform:translateX(40%);transform:translateX(40%);}
}
.post-overview-card{background-color:#F7FAFD;border:1px solid rgba(127, 136, 151, 0.1);padding:25px 30px 18px 30px;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;}
.post-overview-card .list-items{display:-webkit-flex;display:-ms-flex;display:flex;-ms-flex-wrap:wrap;flex-wrap:wrap;}
@media (max-width:425px){.post-overview-card .list-items{display:block;}
}
.post-overview-card .list-items li{margin-bottom:12px;width:45%;margin-right:33px;padding-left:0;}
@media (max-width:1199px){.post-overview-card .list-items li{width:auto;margin-right:15px;}
}
@media (max-width:425px){.post-overview-card .list-items li{margin-right:0;}
}
.post-overview-card .list-items li:after{display:none;}
.curriculum-header{margin-bottom:30px;}
@media only screen and (min-width:480px) and (max-width:767px){.curriculum-header{display:block !important;}
}
@media only screen and (min-width:320px) and (max-width:479px){.curriculum-header{display:block !important;}
}
@media only screen and (min-width:480px) and (max-width:767px){.curriculum-header .curriculum-header-right{margin-top:8px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.curriculum-header .curriculum-header-right{margin-top:8px;}
}
.curriculum-header .curriculum-header-right span{margin-right:10px;}
.curriculum-header .curriculum-header-right span:last-child{margin-right:0;}
.curriculum-header .curriculum-header-right span strong{color:#233d63;}
.accordion-shared .card{border:none;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;margin-bottom:12px;}
.accordion-shared .card:first-of-type{border-bottom:none;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;}
.accordion-shared .card:not(:first-of-type):not(:last-of-type){border-bottom:none;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;}
.accordion-shared .card-header{padding:0;text-align:left;background-color:transparent;border:none;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;}
.accordion-shared .card-header .btn-link{display:block;width:100%;text-align:left;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;padding:10px 20px 10px 45px;border:none;color:#233d63;text-decoration:none;font-size:17px;position:relative;font-weight:600;background-color:rgba(127, 136, 151, 0.09);}
.accordion-shared .card-header .btn-link .fa{display:none;position:absolute;left:20px;top:50%;-webkit-transform:translateY(-50%);-moz-transform:translateY(-50%);-ms-transform:translateY(-50%);-o-transform:translateY(-50%);transform:translateY(-50%);color:#7f8897;font-size:18px;font-weight:600;}
.accordion-shared .card-header .btn-link span{color:#7f8897;font-size:16px;font-weight:500;}
.accordion-shared .card-header .btn-link[aria-expanded=true] .fa-angle-up{display:block;}
.accordion-shared .card-header .btn-link[aria-expanded=false] .fa-angle-down{display:block;}
.accordion-shared .card-body .badge-label{padding:0 10px;-webkit-border-radius:30px;-moz-border-radius:30px;border-radius:30px;line-height:21px;text-transform:capitalize;margin-left:5px;}
.accordion-shared .card-body .badge-label.badge-secondary{color:#7f8897;background-color:rgba(127, 136, 151, 0.1);}
.accordion-shared .card-body .list-items li{padding-top:4px;padding-bottom:4px;margin-bottom:0;padding-left:0;}
.accordion-shared .card-body .list-items li a .fa{opacity:0.6;}
.accordion-shared .card-body .list-items li:last-child{padding-bottom:0;}
.accordion-shared .card-body .list-items li:after{display:none;}
.accordion-shared-2 .card-header{-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.accordion-shared-2 .card-header:hover{background-color:#51be78;}
.accordion-shared-2 .card-header:hover .btn-link{color:#fff;}
.accordion-shared-2 .card-header:hover .btn-link .fa{color:#fff;}
.accordion-shared-2 .card-header .btn-link{padding:20px 30px;background-color:rgba(127, 136, 151, 0.1);}
.accordion-shared-2 .card-header .btn-link .fa{left:auto;right:30px;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.accordion-shared-2 .card-header .btn-link[aria-expanded=true]{background-color:#51be78;color:#fff;}
.accordion-shared-2 .card-header .btn-link[aria-expanded=true] .fa{color:#fff;}
.view-more-courses .owl-dots{text-align:center;}
.view-more-courses .owl-dots div{width:14px;height:14px;line-height:14px;background-color:rgba(127, 136, 151, 0.4);-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;display:inline-block;margin:0 2px;position:relative;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;-webkit-transform:scale(0.7);-moz-transform:scale(0.7);-ms-transform:scale(0.7);-o-transform:scale(0.7);transform:scale(0.7);}
.view-more-courses .owl-dots div.active, .view-more-courses .owl-dots div:hover{background-color:#51be78;-webkit-transform:scale(1);-moz-transform:scale(1);-ms-transform:scale(1);-o-transform:scale(1);transform:scale(1);}
.section-tab .nav-tabs{border:none;}
.section-tab .nav-tabs li{margin:0 3px;}
@media (max-width:480px){.section-tab .nav-tabs li{margin-bottom:5px;}
}
.section-tab .nav-tabs li .theme-btn{color:#51be78;text-transform:capitalize;padding-right:17px;padding-left:17px;line-height:40px;background-color:rgba(81, 190, 120, 0.1);border:none;}
.section-tab .nav-tabs li .theme-btn.active{background-color:#51be78;color:#fff;}
.section-tab-2 .nav-tabs{border-bottom:1px solid rgba(127, 136, 151, 0.2);}
.section-tab-2 .nav-tabs li{margin-right:14px;padding-bottom:14px;}
.section-tab-2 .nav-tabs li a{color:#7f8897;position:relative;padding-bottom:19px;}
.section-tab-2 .nav-tabs li a:after{position:absolute;content:"";bottom:3px;left:50%;-webkit-transform:translateX(-50%);-moz-transform:translateX(-50%);-ms-transform:translateX(-50%);-o-transform:translateX(-50%);transform:translateX(-50%);width:50px;height:3px;opacity:0;visibility:hidden;background-color:#51be78;-webkit-border-radius:4px 4px 0 0;-moz-border-radius:4px 4px 0 0;border-radius:4px 4px 0 0;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.section-tab-2 .nav-tabs li a.active{color:#51be78;}
.section-tab-2 .nav-tabs li a.active:after{opacity:1;visibility:visible;}
.cart-head .cart__title{font-size:16px;text-transform:capitalize;color:#233d63;font-weight:600;}
.cart-body img{width:100px;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;}
.cart-body .cart-product-desc img{margin-right:20px;border:1px solid #eee;-webkit-border-radius:2px;-moz-border-radius:2px;border-radius:2px;}
.cart-body .cart-product-desc .widget-title{-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;display:block;font-size:16px;color:#233d63;}
.cart-body .cart-product-desc .widget-title:hover{color:#51be78;}
.cart-body .cart-product-desc p{color:#7f8897;line-height:24px;font-size:15px;}
.cart-body .cart-product-desc p a{color:#51be78;}
.cart-body .item__price{color:#233d63;font-weight:700;font-size:17px;}
.cart-body .input-number-group .input-number-decrement{margin-left:0;}
.button-remove{background-color:rgba(127, 136, 151, 0.1);border:none;width:35px;height:35px;text-align:center;line-height:0;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;color:#7f8897;position:absolute;left:50%;top:50%;-webkit-transform:translate(-50%, -50%);-moz-transform:translate(-50%, -50%);-ms-transform:translate(-50%, -50%);-o-transform:translate(-50%, -50%);transform:translate(-50%, -50%);outline:none;}
.button-remove .fa-close{margin-top:-1px;}
.button-remove:hover{background-color:#51be78;color:#fff;}
.shopping-cart-wrap .table thead > tr > td,
.shopping-cart-wrap .table thead > tr > th{border-bottom-width:1px;}
.shopping-cart-wrap .table tr td{padding:15px 20px;vertical-align:middle;line-height:inherit;position:relative;}
.shopping-cart-detail-item{background-color:#F7FAFD;-webkit-border-radius:8px;-moz-border-radius:8px;border-radius:8px;padding:30px 30px 33px 30px;margin-bottom:30px;}
.shopping-cart-content .list-items li{padding-left:0;border-bottom:1px solid rgba(127, 136, 151, 0.1);padding-bottom:10px;margin-bottom:10px;}
.shopping-cart-content .list-items li:after{display:none;}
.checkout-radio-list .checkout-item .radio-trigger{background-color:transparent;-webkit-box-shadow:0 0 0 0;-moz-box-shadow:0 0 0 0;box-shadow:0 0 0 0;-webkit-border-radius:0;-moz-border-radius:0;border-radius:0;padding:0 0 0 30px;}
.checkout-radio-list .checkout-item .radio-trigger .checkmark{border:none;-webkit-border-radius:0;-moz-border-radius:0;border-radius:0;position:inherit;top:2px;left:-30px;width:auto;height:auto;}
.checkout-radio-list .checkout-item .radio-trigger .checkmark:before{top:0;left:0;}
.checkout-radio-list .checkout-item .radio-trigger .checkmark:after{top:5px;left:5px;}
@media (max-width:375px){.shopping-list li{display:block !important;}
}
.shopping-list .shopping-img{width:100px;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;-ms-flex-negative:0;flex-shrink:0;}
.shopping-list .shopping-img img{width:100%;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;}
.shopping-list .shopping-link{margin-right:auto;padding-left:20px;}
@media (max-width:375px){.shopping-list .shopping-link{padding-left:0;padding-top:10px;padding-bottom:5px;line-height:20px;}
}
.shopping-list .shopping-link a{color:#233d63;line-height:24px;}
@media (max-width:375px){.shopping-list .shopping-link a{line-height:20px;}
}
.shopping-list .shopping-price span{display:block;color:#233d63;font-weight:600;line-height:20px;}
.shopping-list .shopping-price .before-price{color:#7f8897;}
.secure-connection i{color:rgba(127, 136, 151, 0.4);}
.checkout-item-list .card-header,
.checkout-item-list .card{-webkit-border-radius:0;-moz-border-radius:0;border-radius:0;border:none;background-color:transparent;}
.checkout-item-list .card:first-of-type{padding-top:15px;}
.checkout-item-list .card:last-of-type{padding-bottom:15px;}
.checkout-item-list .card-body{padding:0 30px 0 30px;}
.checkout-item-list .card-body p{line-height:26px;font-size:15px;}
.checkout-item-list .card .card-header{margin-bottom:0;padding:10px 30px;}
.checkout-item .widget-title{font-size:17px;font-weight:600;}
@media (max-width:575px){.modal-form .modal-dialog.modal-lg{width:auto;}
}
.modal-form .modal-title{white-space:nowrap;overflow:hidden;text-overflow:ellipsis;padding-right:30px;}
.modal-form .modal-top{border-bottom:1px solid #eee;margin-bottom:25px;padding:25px 30px 23px 30px;display:-webkit-flex;display:-ms-flex;display:flex;-ms-flex-pack:justify;justify-content:space-between;position:relative;}
.modal-form .modal-top .close-arrow{opacity:1;top:20px;position:absolute;right:20px;}
.modal-form .modal-top .close-arrow span{font-size:20px;color:#233d63;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.modal-form .modal-top .close-arrow span:hover{color:#51be78;-webkit-transform:rotate(90deg);-moz-transform:rotate(90deg);-ms-transform:rotate(90deg);-o-transform:rotate(90deg);transform:rotate(90deg);}
.modal-form .modal-top .modal-sub{font-weight:500;line-height:26px;}
.modal-form .alert-content .warning-icon{color:#F68A03;font-size:60px;display:inline-block;}
.modal-form .alert-content .modal-title{line-height:25px;}
.modal-form .modal-body{padding:20px 30px 30px 30px;}
.modal-form .modal-text p{margin-bottom:15px;}
.modal-form .contact-form-action{padding:0 30px 30px 30px;}
.modal-form .btn-box .theme-btn{line-height:40px;}
@media (max-width:400px){.modal-form .btn-box.text-right{text-align:left;}
}
.copy-to-clipboard-modal .contact-form-action{padding:0;}
.success-message{background-color:#38BB0C;color:#fff;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;padding:3px 6px;position:absolute;top:-10px;left:45%;-webkit-transform:translateX(-50%);-moz-transform:translateX(-50%);-ms-transform:translateX(-50%);-o-transform:translateX(-50%);transform:translateX(-50%);-webkit-transition:all 0.4s;-moz-transition:all 0.4s;-ms-transition:all 0.4s;-o-transition:all 0.4s;transition:all 0.4s;opacity:0;visibility:hidden;}
.success-message.active{top:-40px;opacity:1;visibility:visible;}
.success-message:after{position:absolute;content:"";bottom:-4px;left:50%;-webkit-transform:translateX(-50%) rotate(45deg);-moz-transform:translateX(-50%) rotate(45deg);-ms-transform:translateX(-50%) rotate(45deg);-o-transform:translateX(-50%) rotate(45deg);transform:translateX(-50%) rotate(45deg);background-color:#38BB0C;width:8px;height:8px;}
.copy-to-clipboard{position:relative;}
.copy-to-clipboard .contact-form-action .form-control{padding:10px 20px;}
.copy-to-clipboard .copy-tooltip .theme-btn{margin-left:4px;text-transform:inherit;}
.copy-tooltip{position:relative;display:inline-block;}
.copy-tooltip .tooltip-text{width:140px;background-color:#233d63;color:#fff;text-align:center;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;line-height:30px;position:absolute;z-index:1;bottom:120%;left:50%;margin-left:-70px;display:none;}
.copy-tooltip .tooltip-text:after{content:"";position:absolute;top:100%;left:50%;margin-left:-5px;border-width:5px;border-style:solid;border-color:#233d63 transparent transparent transparent;}
.copy-tooltip:hover .tooltip-text{display:block;}
.card-item{-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;margin-bottom:30px;background-color:#fff;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.card-item:hover{-webkit-box-shadow:0 0 40px rgba(82, 85, 90, 0.1);-moz-box-shadow:0 0 40px rgba(82, 85, 90, 0.1);box-shadow:0 0 40px rgba(82, 85, 90, 0.1);}
.card-image{position:relative;}
.card-image .play-button,
.card-image .my-course-action-dot,
.card-image .card-badge{position:absolute;top:0;left:0;z-index:1;}
.card-image .play-button{top:50%;left:50%;-webkit-transform:translate(-50%, -50%) scale(0.7);-moz-transform:translate(-50%, -50%) scale(0.7);-ms-transform:translate(-50%, -50%) scale(0.7);-o-transform:translate(-50%, -50%) scale(0.7);transform:translate(-50%, -50%) scale(0.7);-webkit-transition:all 0.3s ease;-moz-transition:all 0.3s ease;-ms-transition:all 0.3s ease;-o-transition:all 0.3s ease;transition:all 0.3s ease;opacity:0;visibility:hidden;}
.card-image .my-course-action-dot{left:auto;right:10px;top:10px;}
.card-image .my-course-action-dot .dropdown-header{padding:5px 14px;font-size:16px;color:#7f8897;}
.card-image .my-course-action-dot .collection-link{color:#7f8897;}
.card-image .my-course-action-dot .collection-link .la{color:#51be78;}
.card-image .my-course-action-dot .collection-link .la.active{display:none;}
.card-image .my-course-action-dot .dropdown-menu{left:auto !important;right:0;min-width:240px;border:1px solid rgba(127, 136, 151, 0.2);}
.card-image .my-course-action-dot .btn{background-color:#fff;color:#7f8897;padding:0 2px;font-size:22px;-webkit-box-shadow:0 0 0 0;-moz-box-shadow:0 0 0 0;box-shadow:0 0 0 0;}
.card-image .my-course-action-dot .btn:hover{color:#233d63;}
.card-image:after{position:absolute;bottom:0;left:30px;content:'';width:0;height:0;border-left:12px solid transparent;border-right:12px solid transparent;border-bottom:12px solid #fff;z-index:1;}
.card-image:hover:before{opacity:0.1;visibility:visible;}
.card-image:hover .play-button{-webkit-transform:translate(-50%, -50%) scale(1);-moz-transform:translate(-50%, -50%) scale(1);-ms-transform:translate(-50%, -50%) scale(1);-o-transform:translate(-50%, -50%) scale(1);transform:translate(-50%, -50%) scale(1);opacity:1;visibility:visible;}
.card__img{display:block;position:relative;z-index:1;}
.card__img img{width:100%;-webkit-border-radius:4px 4px 0 0;-moz-border-radius:4px 4px 0 0;border-radius:4px 4px 0 0;}
.card-content{border:1px solid rgba(127, 136, 151, 0.2);-webkit-border-radius:0 0 4px 4px;-moz-border-radius:0 0 4px 4px;border-radius:0 0 4px 4px;padding:29px 30px 30px 30px;border-top:transparent;}
.card__label{display:-webkit-flex;display:-ms-flex;display:flex;-ms-flex-align:center;align-items:center;}
.card__label-text{background-color:rgba(81, 190, 120, 0.1);display:inline-block;-webkit-border-radius:30px;-moz-border-radius:30px;border-radius:30px;color:#51be78;padding:1px 12px;text-transform:capitalize;font-size:15px;font-weight:400;}
.card__collection-icon{margin-left:auto;color:#7f8897;font-size:22px;position:relative;}
.card__collection-icon:hover{color:#51be78;}
.card__title{font-size:17px;font-weight:700;margin-top:10px;margin-bottom:9px;line-height:24px;}
.card__title a{color:#233d63;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.card__title a:hover{color:#51be78;}
.card__author{line-height:26px;}
.card__author a{color:#7f8897;text-transform:capitalize;font-weight:500;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.card__author a:hover{color:#51be78;}
.card-action{margin-bottom:25px;}
.card-action .card-duration li{width:100%;border-right:1px solid rgba(127, 136, 151, 0.2);padding:0 15px;}
.card-action .card-duration li:first-child{padding-left:0;}
.card-action .card-duration li:last-child{border-right:none;padding-right:0;text-align:right;}
.card-action .card-duration li span{display:block;}
.card-action .card-duration li span.meta__date{color:#233d63;font-weight:500;}
.card-action .card-duration li span.meta__text{font-weight:500;font-size:15px;}
.card-price-wrap .card__price{color:#233d63;font-weight:600;font-size:26px;}
.card-price-wrap .text-btn{color:#7f8897;}
.card-price-wrap .text-btn:hover{color:#51be78;}
.card-list-layout{display:-webkit-flex;display:-ms-flex;display:flex;background-color:transparent;}
@media (max-width:575px){.card-list-layout{display:block;}
}
.card-list-layout .card__img img{-webkit-border-radius:4px 0 0 4px;-moz-border-radius:4px 0 0 4px;border-radius:4px 0 0 4px;height:325px;object-fit:cover;}
@media (max-width:575px){.card-list-layout .card__img img{height:auto;-webkit-border-radius:4px 4px 0 0;-moz-border-radius:4px 4px 0 0;border-radius:4px 4px 0 0;}
}
.card-list-layout .card-bookmark-img .card__img img{height:295px;}
.card-list-layout .card-image{width:33%;}
@media (max-width:575px){.card-list-layout .card-image{width:auto;}
}
.card-list-layout .card-image:after{top:35px;left:auto;right:-6px;-webkit-transform:rotate(-90deg);-moz-transform:rotate(-90deg);-ms-transform:rotate(-90deg);-o-transform:rotate(-90deg);transform:rotate(-90deg);}
.card-list-layout .card-content{width:68%;border-left:transparent;border-top:1px solid rgba(127, 136, 151, 0.2);-webkit-border-radius:0 4px 4px 0;-moz-border-radius:0 4px 4px 0;border-radius:0 4px 4px 0;}
@media (max-width:575px){.card-list-layout .card-content{width:auto;border-left:1px solid rgba(127, 136, 151, 0.2);-webkit-border-radius:0 0 4px 4px;-moz-border-radius:0 0 4px 4px;border-radius:0 0 4px 4px;}
}
.card-list-layout .card-action .card-duration li{width:auto;}
.card-list-layout .card-action .card-duration li span{display:inline-block;}
.card-list-layout:hover{-webkit-box-shadow:0 0 0 0;-moz-box-shadow:0 0 0 0;box-shadow:0 0 0 0;}
@media only screen and (min-width:480px) and (max-width:767px){.card-box-shared-body .card-list-layout{display:block;}
}
@media only screen and (min-width:480px) and (max-width:767px){.card-box-shared-body .card-list-layout .card__img img{height:auto;-webkit-border-radius:4px 4px 0 0;-moz-border-radius:4px 4px 0 0;border-radius:4px 4px 0 0;}
}
@media only screen and (min-width:480px) and (max-width:767px){.card-box-shared-body .card-list-layout .card-image{width:auto;}
}
@media (max-width:425px){.card-box-shared-body .card-list-layout .card-duration{display:block !important;}
}
.card-box-shared-body .card-list-layout .card-duration li{padding-right:0;padding-left:0;border-right:none;}
.card-box-shared-body .card-list-layout .card-duration li:last-child{text-align:left;}
@media (max-width:425px){.card-box-shared-body .card-list-layout .card-price-wrap{display:block !important;}
}
@media (max-width:425px){.card-box-shared-body .card-list-layout .card-price-wrap .edit-action{margin-left:0;margin-top:10px;}
}
@media (max-width:375px){.card-box-shared-body .card-list-layout .card-price-wrap .edit-action .edit-list li{margin-bottom:5px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.card-box-shared-body .card-list-layout .card-content{width:auto;border-left:1px solid rgba(127, 136, 151, 0.2);-webkit-border-radius:0 0 4px 4px;-moz-border-radius:0 0 4px 4px;border-radius:0 0 4px 4px;}
}
.edit-action{margin-left:20px;}
.card-box-shared,
.dashboard-shared{background-color:#fff;-webkit-border-radius:8px;-moz-border-radius:8px;border-radius:8px;margin-bottom:30px;position:relative;padding:20px;-webkit-box-shadow:0 0 8px 0px rgba(0, 0, 0, 0.07);-moz-box-shadow:0 0 8px 0px rgba(0, 0, 0, 0.07);box-shadow:0 0 8px 0px rgba(0, 0, 0, 0.07);}
.dashboard-shared .mess__body{max-height:408px;}
.card-box-shared{border:1px solid rgba(127, 136, 151, 0.2);-webkit-box-shadow:0 0 8px 0px rgba(0, 0, 0, 0.05);-moz-box-shadow:0 0 8px 0px rgba(0, 0, 0, 0.05);box-shadow:0 0 8px 0px rgba(0, 0, 0, 0.05);padding:0;}
.card-box-shared-title{padding:30px;border-bottom:1px solid rgba(127, 136, 151, 0.2);}
.card-box-shared-body{padding:30px;}
@media (max-width:480px){.card-box-shared-body .section-tab .nav-tabs{white-space:nowrap;overflow-y:hidden;overflow-x:auto;display:block;}
}
.card-box-shared-body .section-tab .nav-tabs li{display:inline-block;}
@media (max-width:480px){.card-box-shared-body .section-tab .nav-tabs li{margin-bottom:0;}
}
.header-menu-area{position:relative;z-index:1023;background-color:#fff;}
.header-menu-area .container-fluid{padding-right:0;padding-left:0;}
@media (max-width:1600px){.header-menu-area .container-fluid{padding-right:15px;padding-left:15px;}
}
.header-top{border-bottom:1px solid rgba(127, 136, 151, 0.1);padding-right:150px;padding-left:150px;}
@media (max-width:1600px){.header-top{padding-right:0;padding-left:0;}
}
@media only screen and (min-width:768px) and (max-width:991px){.header-top{text-align:center;}
}
@media only screen and (min-width:480px) and (max-width:767px){.header-top{text-align:center;}
}
@media only screen and (min-width:320px) and (max-width:479px){.header-top{text-align:center;}
}
@media (max-width:375px){.header-widget{-ms-flex-wrap:wrap;flex-wrap:wrap;}
}
@media only screen and (min-width:768px) and (max-width:991px){.header-widget.justify-content-end{-ms-flex-pack:center !important;justify-content:center !important;}
}
@media only screen and (min-width:480px) and (max-width:767px){.header-widget.justify-content-end{-ms-flex-pack:center !important;justify-content:center !important;}
}
@media only screen and (min-width:320px) and (max-width:479px){.header-widget.justify-content-end{-ms-flex-pack:center !important;justify-content:center !important;}
}
.header-action-list li{color:#233d63;display:inline-block;margin-right:15px;font-size:14px;border-right:1px solid rgba(127, 136, 151, 0.1);padding-right:15px;padding-top:10px;padding-bottom:10px;}
.header-action-list li:last-child{margin-right:0;padding-right:0;border-right:0;}
.header-action-list li a{display:block;color:#233d63;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.header-action-list li a:hover{color:#51be78;}
.header-social-profile{border-right:1px solid rgba(127, 136, 151, 0.1);padding-right:20px;margin-right:15px;height:48px;display:-webkit-flex;display:-ms-flex;display:flex;-ms-flex-align:center;align-items:center;}
.header-social-profile li{padding-right:15px;font-size:14px;}
.header-social-profile li:last-child{padding-right:0;}
.header-social-profile li a{display:block;color:#233d63;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.header-social-profile li a:hover{color:#51be78;}
.shop-cart{border-right:1px solid rgba(127, 136, 151, 0.1);padding-right:15px;margin-right:15px;height:48px;display:-webkit-flex;display:-ms-flex;display:flex;-ms-flex-align:center;align-items:center;}
@media only screen and (min-width:768px) and (max-width:991px){.shop-cart{text-align:left;}
}
@media only screen and (min-width:480px) and (max-width:767px){.shop-cart{text-align:left;}
}
@media only screen and (min-width:320px) and (max-width:479px){.shop-cart{text-align:left;}
}
.shop-cart > ul{height:100%;}
.shop-cart > ul > li{position:relative;height:100%;}
.shop-cart > ul > li:hover .cart-dropdown-menu{opacity:1;visibility:visible;top:50px;}
.shop-cart-btn{font-size:20px;height:100%;cursor:pointer;color:#233d63;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.shop-cart-btn:hover{color:#51be78;}
.product-count{width:18px;height:18px;line-height:17px;text-align:center;background-color:#51be78;color:#fff;font-size:14px;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;display:inline-block;}
.cart-dropdown-menu{position:absolute;right:0;top:65px;width:325px;background-color:#fff;padding:20px;-webkit-border-radius:8px;-moz-border-radius:8px;border-radius:8px;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;-webkit-box-shadow:0 0 40px rgba(82, 85, 90, 0.2);-moz-box-shadow:0 0 40px rgba(82, 85, 90, 0.2);box-shadow:0 0 40px rgba(82, 85, 90, 0.2);opacity:0;visibility:hidden;z-index:3;max-height:400px;overflow-y: scroll;}
@media (max-width:600px){.cart-dropdown-menu{right:-145px;}
}
@media (max-width:375px){.cart-dropdown-menu{right:-90px;}
}
@media (max-width:320px){.cart-dropdown-menu{right:-80px;width:300px;}
}
.cart-dropdown-menu li{display:-webkit-flex;display:-ms-flex;display:flex;margin-bottom:15px;padding-bottom:15px;border-bottom:1px solid rgba(127, 136, 151, 0.1);}
.cart-dropdown-menu li:last-child{padding-bottom:0;margin-bottom:0;border-bottom:0;}
.cart-dropdown-menu li .cart-link{width:90px;height:80px;-ms-flex-negative:0;flex-shrink:0;margin-right:15px;}
.cart-dropdown-menu li .cart-link img{width:100%;height:100%;-webkit-border-radius:3px;-moz-border-radius:3px;border-radius:3px;}
.cart-dropdown-menu li .cart-info{line-height:22px;}
.cart-dropdown-menu li .cart-info a{color:#233d63;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.cart-dropdown-menu li .cart-info a:hover{color:#51be78;}
.cart-dropdown-menu li .cart-info .cart__author{display:block;}
.cart-dropdown-menu li .cart-info .cart__price{font-weight:600;color:#233d63;display: block;}
.cart-dropdown-menu li .cart-info .cart__price .before-price{font-size:14px;margin-left:4px;}
.cart-dropdown-menu li .cart-total{color:#233d63;font-weight:600;width:100%;}
.cart-dropdown-menu li .cart-total .before-price{margin-left:8px;}
.cart-dropdown-menu:after{top:-6px;content:"";position:absolute;background-color:#fff;right:20px;width:13px;height:13px;-webkit-transform:rotate(45deg);-moz-transform:rotate(45deg);-ms-transform:rotate(45deg);-o-transform:rotate(45deg);transform:rotate(45deg);-webkit-box-shadow:-2px -2px 10px -5px rgba(0, 0, 0, 0.2);-moz-box-shadow:-2px -2px 10px -5px rgba(0, 0, 0, 0.2);box-shadow:-2px -2px 10px -5px rgba(0, 0, 0, 0.2);}
@media (max-width:600px){.cart-dropdown-menu:after{right:50%;-webkit-transform:translateX(50%) rotate(45deg);-moz-transform:translateX(50%) rotate(45deg);-ms-transform:translateX(50%) rotate(45deg);-o-transform:translateX(50%) rotate(45deg);transform:translateX(50%) rotate(45deg);}
}
@media (max-width:375px){.cart-dropdown-menu:after{right:100px;-webkit-transform:translateX(0) rotate(45deg);-moz-transform:translateX(0) rotate(45deg);-ms-transform:translateX(0) rotate(45deg);-o-transform:translateX(0) rotate(45deg);transform:translateX(0) rotate(45deg);}
}
.header-menu-content{padding-right:150px;padding-left:150px;-webkit-transition:all 0.5s;-moz-transition:all 0.5s;-ms-transition:all 0.5s;-o-transition:all 0.5s;transition:all 0.5s;background-color:#fff;}
@media (max-width:1600px){.header-menu-content{padding-right:0;padding-left:0;}
}
.side-menu-open,
.menu-toggler{width:40px;height:40px;line-height:40px;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;background-color:rgba(128, 137, 150, 0.1);color:#233d63;text-align:center;cursor:pointer;position:relative;font-size:24px;-ms-flex-negative:0;flex-shrink:0;display:none;z-index:2;}
@media only screen and (min-width:768px) and (max-width:991px){.side-menu-open,
.menu-toggler{display:block;}
}
@media only screen and (min-width:480px) and (max-width:767px){.side-menu-open,
.menu-toggler{display:block;}
}
@media only screen and (min-width:320px) and (max-width:479px){.side-menu-open,
.menu-toggler{display:block;}
}
.side-menu-open .la-times,
.menu-toggler .la-times{opacity:0;visibility:hidden;position:absolute;left:50%;top:50%;-webkit-transform:translate(-50%, -50%);-moz-transform:translate(-50%, -50%);-ms-transform:translate(-50%, -50%);-o-transform:translate(-50%, -50%);transform:translate(-50%, -50%);-webkit-transition:all 0.2s;-moz-transition:all 0.2s;-ms-transition:all 0.2s;-o-transition:all 0.2s;transition:all 0.2s;}
.side-menu-open .la-bars,
.menu-toggler .la-bars{-webkit-transition:all 0.2s;-moz-transition:all 0.2s;-ms-transition:all 0.2s;-o-transition:all 0.2s;transition:all 0.2s;}
.side-menu-open.active .la-bars,
.menu-toggler.active .la-bars{opacity:0;visibility:hidden;}
.side-menu-open.active .la-times,
.menu-toggler.active .la-times{opacity:1;visibility:visible;}
.sub-nav-toggler{width:35px;height:35px;text-align:center;line-height:35px;color:#233d63;cursor:pointer;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;display:none;-webkit-border-radius:5px;-moz-border-radius:5px;border-radius:5px;border:none;outline:none;z-index:2;background-color:transparent;}
.sub-nav-toggler .la-angle-down{font-size:16px;}
@media only screen and (min-width:768px) and (max-width:991px){.sub-nav-toggler .la-angle-down{display:block;}
}
@media only screen and (min-width:480px) and (max-width:767px){.sub-nav-toggler .la-angle-down{display:block;}
}
@media only screen and (min-width:320px) and (max-width:479px){.sub-nav-toggler .la-angle-down{display:block;}
}
@media only screen and (min-width:768px) and (max-width:991px){.sub-nav-toggler{display:block;}
}
@media only screen and (min-width:480px) and (max-width:767px){.sub-nav-toggler{display:block;}
}
@media only screen and (min-width:320px) and (max-width:479px){.sub-nav-toggler{display:block;}
}
.sub-nav-toggler:hover{background-color:rgba(128, 137, 150, 0.1);}
.logo-box{padding-top:20px;padding-bottom:20px;display:-webkit-flex;display:-ms-flex;display:flex;-ms-flex-align:center;align-items:center;position:relative;}
@media only screen and (min-width:768px) and (max-width:991px){.logo-box{-ms-flex-pack:justify;justify-content:space-between;}.logo-box img{max-height: 41px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.logo-box{-ms-flex-pack:justify;justify-content:space-between;}.logo-box img{max-height: 41px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.logo-box{-ms-flex-pack:justify;justify-content:space-between;}.logo-box img{max-height: 41px;}
}
.logo-box .logo{display:inline-block;position: relative;z-index: 99;}
@media (max-width:1280px){.menu-category{margin-right:35px;border-right:1px solid rgba(128, 137, 150, 0.1);padding-right:35px;}
}
@media only screen and (min-width:768px) and (max-width:991px){.menu-category{margin-right:60px;padding-right:0;border-right:0;margin-top:-55px;text-align:right;}
}
@media only screen and (min-width:480px) and (max-width:767px){.menu-category{margin-right:60px;padding-right:0;border-right:0;margin-top:-55px;text-align:right;}
}
@media only screen and (min-width:320px) and (max-width:479px){.menu-category{margin-right:60px;padding-right:0;border-right:0;margin-top:-55px;text-align:right;}
}
@media (max-width:320px){.menu-category{margin-right:50px;}
}
.menu-category > ul > li{position:relative;}
@media only screen and (min-width:768px) and (max-width:991px){.menu-category > ul > li{display:inline-block;}
}
@media only screen and (min-width:480px) and (max-width:767px){.menu-category > ul > li{display:inline-block;}
}
@media only screen and (min-width:320px) and (max-width:479px){.menu-category > ul > li{display:inline-block;}
}
.menu-category > ul > li a{font-size:15px;color:#233d63;text-transform:uppercase;font-weight:500;padding-bottom:41px;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.menu-category > ul > li a:hover{color:#51be78;}
@media (max-width:320px){.menu-category > ul > li a .la-th-large{display:none;}
}
.menu-category > ul > li .cat-dropdown-menu{position:absolute;left:0;top:65px;width:320px;background-color:#fff;display:block;padding:25px 0 25px 0;-webkit-border-radius:8px;-moz-border-radius:8px;border-radius:8px;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;-webkit-box-shadow:0 0 40px rgba(82, 85, 90, 0.2);-moz-box-shadow:0 0 40px rgba(82, 85, 90, 0.2);box-shadow:0 0 40px rgba(82, 85, 90, 0.2);opacity:0;visibility:hidden;z-index:1;}
@media only screen and (min-width:768px) and (max-width:991px){.menu-category > ul > li .cat-dropdown-menu{left:auto;right:0;overflow-x:hidden;height:370px;text-align:left;}
}
@media only screen and (min-width:480px) and (max-width:767px){.menu-category > ul > li .cat-dropdown-menu{left:auto;right:0;overflow-x:hidden;height:370px;text-align:left;}
}
@media only screen and (min-width:320px) and (max-width:479px){.menu-category > ul > li .cat-dropdown-menu{left:auto;right:0;overflow-x:hidden;height:370px;text-align:left;}
}
@media (max-width:425px){.menu-category > ul > li .cat-dropdown-menu{width:290px;}
}
@media (max-width:320px){.menu-category > ul > li .cat-dropdown-menu{right:-50px;}
}
.menu-category > ul > li .cat-dropdown-menu > li{margin-bottom:8px;position:relative;}
.menu-category > ul > li .cat-dropdown-menu > li:last-child{margin-bottom:0;}
.menu-category > ul > li .cat-dropdown-menu > li > a{display:-webkit-flex;display:-ms-flex;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between;padding-right:30px;padding-left:30px;padding-bottom:0;}
@media only screen and (min-width:768px) and (max-width:991px){.menu-category > ul > li .cat-dropdown-menu > li > a .la-angle-right{display:none;}
}
@media only screen and (min-width:480px) and (max-width:767px){.menu-category > ul > li .cat-dropdown-menu > li > a .la-angle-right{display:none;}
}
@media only screen and (min-width:320px) and (max-width:479px){.menu-category > ul > li .cat-dropdown-menu > li > a .la-angle-right{display:none;}
}
.menu-category > ul > li .cat-dropdown-menu > li .sub-menu{position:absolute;top:-24px;left:96%;width:280px;background-color:#fff;padding:25px 0 25px 0;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;-webkit-box-shadow:0 0 40px rgba(82, 85, 90, 0.1);-moz-box-shadow:0 0 40px rgba(82, 85, 90, 0.1);box-shadow:0 0 40px rgba(82, 85, 90, 0.1);visibility:hidden;opacity:0;}
@media only screen and (min-width:768px) and (max-width:991px){.menu-category > ul > li .cat-dropdown-menu > li .sub-menu{padding:0;margin-top:10px;opacity:1;visibility:visible;left:auto;top:auto;position:inherit;width:100%;display:none;-webkit-transition:all 0s;-moz-transition:all 0s;-ms-transition:all 0s;-o-transition:all 0s;transition:all 0s;-webkit-box-shadow:0 0 0 0;-moz-box-shadow:0 0 0 0;box-shadow:0 0 0 0;}
}
@media only screen and (min-width:480px) and (max-width:767px){.menu-category > ul > li .cat-dropdown-menu > li .sub-menu{padding:0;margin-top:10px;opacity:1;visibility:visible;left:auto;top:auto;position:inherit;width:100%;display:none;-webkit-transition:all 0s;-moz-transition:all 0s;-ms-transition:all 0s;-o-transition:all 0s;transition:all 0s;-webkit-box-shadow:0 0 0 0;-moz-box-shadow:0 0 0 0;box-shadow:0 0 0 0;}
}
@media only screen and (min-width:320px) and (max-width:479px){.menu-category > ul > li .cat-dropdown-menu > li .sub-menu{padding:0;margin-top:10px;opacity:1;visibility:visible;left:auto;top:auto;position:inherit;width:100%;display:none;-webkit-transition:all 0s;-moz-transition:all 0s;-ms-transition:all 0s;-o-transition:all 0s;transition:all 0s;-webkit-box-shadow:0 0 0 0;-moz-box-shadow:0 0 0 0;box-shadow:0 0 0 0;}
}
.menu-category > ul > li .cat-dropdown-menu > li .sub-menu li{margin-bottom:5px;}
.menu-category > ul > li .cat-dropdown-menu > li .sub-menu li:last-child{margin-bottom:0;}
.menu-category > ul > li .cat-dropdown-menu > li .sub-menu li a{color:#7f8897;text-transform:capitalize;position:relative;display:inline-block;padding-right:0;padding-left:0;margin-left:30px;padding-bottom:0;}
.menu-category > ul > li .cat-dropdown-menu > li .sub-menu li a:after{width:0;height:1px;position:absolute;content:'';bottom:1px;right:0;background-color:#51be78;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.menu-category > ul > li .cat-dropdown-menu > li .sub-menu li a:hover{color:#51be78;}
.menu-category > ul > li .cat-dropdown-menu > li .sub-menu li a:hover:after{width:100%;right:auto;left:0;}
.menu-category > ul > li .cat-dropdown-menu > li .sub-menu.active{display:block;}
@media only screen and (min-width:480px) and (max-width:767px){.menu-category > ul > li .cat-dropdown-menu > li.active a{color:#51be78;}
}
@media only screen and (min-width:320px) and (max-width:479px){.menu-category > ul > li .cat-dropdown-menu > li.active a{color:#51be78;}
}
.menu-category > ul > li .cat-dropdown-menu:after{top:-6px;content:"";position:absolute;background-color:#fff;left:30px;width:13px;height:13px;-webkit-transform:rotate(45deg);-moz-transform:rotate(45deg);-ms-transform:rotate(45deg);-o-transform:rotate(45deg);transform:rotate(45deg);-webkit-box-shadow:-2px -2px 10px -5px rgba(0, 0, 0, 0.2);-moz-box-shadow:-2px -2px 10px -5px rgba(0, 0, 0, 0.2);box-shadow:-2px -2px 10px -5px rgba(0, 0, 0, 0.2);border-top:1px solid rgba(127, 136, 151, 0.2);border-left:1px solid rgba(127, 136, 151, 0.2);}
@media only screen and (min-width:480px) and (max-width:767px){.menu-category > ul > li .cat-dropdown-menu:after{left:auto;right:30px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.menu-category > ul > li .cat-dropdown-menu:after{left:auto;right:30px;}
}
.menu-category > ul > li:hover .cat-dropdown-menu{opacity:1;visibility:visible;top:43px;}
.menu-wrapper{display:-webkit-flex;display:-ms-flex;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:end;justify-content:flex-end;padding-top:20px;padding-bottom:20px;}
@media only screen and (min-width:768px) and (max-width:991px){.menu-wrapper{display:block;padding-top:0;padding-bottom:0;}
}
@media only screen and (min-width:480px) and (max-width:767px){.menu-wrapper{display:block;padding-top:0;padding-bottom:0;}
}
@media only screen and (min-width:320px) and (max-width:479px){.menu-wrapper{display:block;padding-top:0;padding-bottom:0;}
}
.menu-wrapper .contact-form-action{width:400px;margin-right:35px;margin-left:35px;}
@media (max-width:1199px){.menu-wrapper .contact-form-action{width:100%;}
}
@media (max-width:1366px){.menu-wrapper .contact-form-action{width:210px;margin-right:20px;margin-left:20px;}
}
@media (max-width:1280px){.menu-wrapper .contact-form-action{display:none;}
}
.menu-wrapper .contact-form-action form .form-group{margin:0;}
.menu-wrapper .contact-form-action form .form-group .form-control{padding-left:20px;}
.main-menu{margin-right:35px;}
@media only screen and (min-width:768px) and (max-width:991px){.main-menu{display:none;margin-right:0;padding-top:30px;padding-bottom:15px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.main-menu{display:none;margin-right:0;padding-top:30px;padding-bottom:15px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.main-menu{display:none;margin-right:0;padding-top:30px;padding-bottom:15px;}
}
.main-menu > ul{display:-webkit-flex;display:-ms-flex;display:flex;}
@media only screen and (min-width:768px) and (max-width:991px){.main-menu > ul{display:block;}
}
@media only screen and (min-width:480px) and (max-width:767px){.main-menu > ul{display:block;}
}
@media only screen and (min-width:320px) and (max-width:479px){.main-menu > ul{display:block;}
}
.main-menu > ul > li{margin-right:34px;text-transform:uppercase;position:relative;}
@media (max-width:1199px){.main-menu > ul > li{margin-right:20px;}
}
@media only screen and (min-width:768px) and (max-width:991px){.main-menu > ul > li{margin-right:0;}
}
@media only screen and (min-width:480px) and (max-width:767px){.main-menu > ul > li{margin-right:0;}
}
@media only screen and (min-width:320px) and (max-width:479px){.main-menu > ul > li{margin-right:0;}
}
.main-menu > ul > li a{font-size:15px;color:#233d63;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;position:relative;padding-bottom:33px;}
@media only screen and (min-width:768px) and (max-width:991px){.main-menu > ul > li a{display:-webkit-flex;display:-ms-flex;display:flex;-ms-flex-pack:justify;justify-content:space-between;padding-bottom:0;margin-bottom:10px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.main-menu > ul > li a{display:-webkit-flex;display:-ms-flex;display:flex;-ms-flex-pack:justify;justify-content:space-between;padding-bottom:0;margin-bottom:10px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.main-menu > ul > li a{display:-webkit-flex;display:-ms-flex;display:flex;-ms-flex-pack:justify;justify-content:space-between;padding-bottom:0;margin-bottom:10px;}
}
.main-menu > ul > li a:after{width:0;height:1px;position:absolute;content:'';bottom:33px;right:0;background-color:#51be78;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
@media only screen and (min-width:768px) and (max-width:991px){.main-menu > ul > li a:after{display:none;}
}
@media only screen and (min-width:480px) and (max-width:767px){.main-menu > ul > li a:after{display:none;}
}
@media only screen and (min-width:320px) and (max-width:479px){.main-menu > ul > li a:after{display:none;}
}
.main-menu > ul > li a:hover{color:#51be78;}
.main-menu > ul > li a:hover:after{width:100%;right:auto;left:0;}
.main-menu > ul > li:last-child{margin-right:0;}
.main-menu > ul > li .dropdown-menu-item{position:absolute;left:0;top:60px;width:220px;background-color:#fff;padding:25px 30px 25px 30px;-webkit-border-radius:8px;-moz-border-radius:8px;border-radius:8px;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;-webkit-box-shadow:0 0 40px rgba(82, 85, 90, 0.2);-moz-box-shadow:0 0 40px rgba(82, 85, 90, 0.2);box-shadow:0 0 40px rgba(82, 85, 90, 0.2);opacity:0;visibility:hidden;}
@media only screen and (min-width:768px) and (max-width:991px){.main-menu > ul > li .dropdown-menu-item{display:none;opacity:1;visibility:visible;position:inherit;top:auto;left:auto;width:100%;background-color:transparent;-webkit-border-radius:0;-moz-border-radius:0;border-radius:0;-webkit-box-shadow:0 0 0 0;-moz-box-shadow:0 0 0 0;box-shadow:0 0 0 0;padding:0 20px 15px 20px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.main-menu > ul > li .dropdown-menu-item{display:none;opacity:1;visibility:visible;position:inherit;top:auto;left:auto;width:100%;background-color:transparent;-webkit-border-radius:0;-moz-border-radius:0;border-radius:0;-webkit-box-shadow:0 0 0 0;-moz-box-shadow:0 0 0 0;box-shadow:0 0 0 0;padding:0 20px 20px 20px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.main-menu > ul > li .dropdown-menu-item{display:none;opacity:1;visibility:visible;position:inherit;top:auto;left:auto;width:100%;background-color:transparent;-webkit-border-radius:0;-moz-border-radius:0;border-radius:0;-webkit-box-shadow:0 0 0 0;-moz-box-shadow:0 0 0 0;box-shadow:0 0 0 0;padding:0 20px 15px 20px;}
}
.main-menu > ul > li .dropdown-menu-item li{display:block;margin-right:0;margin-bottom:4px;text-transform:capitalize;}
.main-menu > ul > li .dropdown-menu-item li:last-child{margin-bottom:0;}
.main-menu > ul > li .dropdown-menu-item li a{color:#7f8897;display:block;position:relative;padding-bottom:0;margin-bottom:0;}
.main-menu > ul > li .dropdown-menu-item li a:after{bottom:5px;}
.main-menu > ul > li .dropdown-menu-item li a:hover{color:#51be78;}
.main-menu > ul > li .dropdown-menu-item:after{top:-6px;content:"";position:absolute;background-color:#fff;left:30px;width:13px;height:13px;-webkit-transform:rotate(45deg);-moz-transform:rotate(45deg);-ms-transform:rotate(45deg);-o-transform:rotate(45deg);transform:rotate(45deg);-webkit-box-shadow:-2px -2px 10px -5px rgba(0, 0, 0, 0.2);-moz-box-shadow:-2px -2px 10px -5px rgba(0, 0, 0, 0.2);box-shadow:-2px -2px 10px -5px rgba(0, 0, 0, 0.2);}
@media only screen and (min-width:768px) and (max-width:991px){.main-menu > ul > li .dropdown-menu-item:after{display:none;}
}
@media only screen and (min-width:480px) and (max-width:767px){.main-menu > ul > li .dropdown-menu-item:after{display:none;}
}
@media only screen and (min-width:320px) and (max-width:479px){.main-menu > ul > li .dropdown-menu-item:after{display:none;}
}
@media (min-width:992px){.menu-category > ul > li .cat-dropdown-menu > li:hover .sub-menu{opacity:1;visibility:visible;left:100%;}
.main-menu > ul > li:hover .dropdown-menu-item{opacity:1;visibility:visible;top:50px;}
}
@media only screen and (min-width:768px) and (max-width:991px){.logo-right-button{display:none;}
}
@media only screen and (min-width:480px) and (max-width:767px){.logo-right-button{display:none;}
}
@media only screen and (min-width:320px) and (max-width:479px){.logo-right-button{display:none;}
}
.logo-right-button .user-action li{display:inline-block;text-transform:uppercase;margin-right:4px;}
.logo-right-button .user-action li:last-child{margin-right:0;}
.logo-right-button .user-action li a{display:block;color:#233d63;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.logo-right-button .user-action li a:hover{color:#51be78;}
.logo-right-button .user-action li .theme-btn{color:#fff;line-height:40px;}
.logo-right-button .user-action li .theme-btn:hover{color:#51be78;}
.logo-right-button-2{border-left:1px solid rgba(127, 136, 151, 0.1);padding-left:35px;}
@media (max-width:1199px){.logo-right-button-2{padding-left:0;border-left:none;}
}
.user-nav-container,
.dashboard-nav-container{position:fixed;top:0;left:0;width:330px;height:100%;background-color:#233d63;overflow-x:hidden;z-index:999;-webkit-transition:all 0.4s cubic-bezier(0.7, 0, 0.2, 1);-moz-transition:all 0.4s cubic-bezier(0.7, 0, 0.2, 1);-ms-transition:all 0.4s cubic-bezier(0.7, 0, 0.2, 1);-o-transition:all 0.4s cubic-bezier(0.7, 0, 0.2, 1);transition:all 0.4s cubic-bezier(0.7, 0, 0.2, 1);-webkit-box-shadow:0 0 40px rgba(82, 85, 90, 0.1);-moz-box-shadow:0 0 40px rgba(82, 85, 90, 0.1);box-shadow:0 0 40px rgba(82, 85, 90, 0.1);-webkit-transform:translateX(-100%);-moz-transform:translateX(-100%);-ms-transform:translateX(-100%);-o-transform:translateX(-100%);transform:translateX(-100%);}
.user-nav-container.active,
.dashboard-nav-container.active{-webkit-transform:translateX(0);-moz-transform:translateX(0);-ms-transform:translateX(0);-o-transform:translateX(0);transform:translateX(0);}
.humburger-menu .humburger-menu-lines{display:inline-block;text-align:right;width:22px;height:22px;cursor:pointer;position:absolute;top:25px;right:18px;opacity:.5;}
.humburger-menu .humburger-menu-lines:before, .humburger-menu .humburger-menu-lines:after{position:absolute;content:'';height:2px;width:100%;top:10px;left:0;background-color:#fff;-webkit-transform:rotate(-43deg);-moz-transform:rotate(-43deg);-ms-transform:rotate(-43deg);-o-transform:rotate(-43deg);transform:rotate(-43deg);-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.humburger-menu .humburger-menu-lines:after{-webkit-transform:rotate(43deg);-moz-transform:rotate(43deg);-ms-transform:rotate(43deg);-o-transform:rotate(43deg);transform:rotate(43deg);}
.humburger-menu .humburger-menu-lines:hover:before, .humburger-menu .humburger-menu-lines:hover:after{-webkit-transform:rotate(0);-moz-transform:rotate(0);-ms-transform:rotate(0);-o-transform:rotate(0);transform:rotate(0);}
.side-menu-wrap{margin-top:100px;}
.side-menu-wrap .side-menu-ul .sidenav__item{position:relative;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.side-menu-wrap .side-menu-ul .sidenav__item a{color:#fff;text-transform:uppercase;display:block;padding:14px 20px;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;font-weight:500;}
.side-menu-wrap .side-menu-ul .sidenav__item .menu-plus-icon{position:absolute;top:0;right:0;width:50px;height:56px;line-height:56px;cursor:pointer;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.side-menu-wrap .side-menu-ul .sidenav__item .menu-plus-icon:before, .side-menu-wrap .side-menu-ul .sidenav__item .menu-plus-icon:after{position:absolute;top:24px;right:20px;content:'';width:11px;height:2px;background-color:#fff;-webkit-transform:rotate(-90deg);-moz-transform:rotate(-90deg);-ms-transform:rotate(-90deg);-o-transform:rotate(-90deg);transform:rotate(-90deg);-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.side-menu-wrap .side-menu-ul .sidenav__item .menu-plus-icon:after{-webkit-transform:rotate(0);-moz-transform:rotate(0);-ms-transform:rotate(0);-o-transform:rotate(0);transform:rotate(0);}
.side-menu-wrap .side-menu-ul .sidenav__item .menu-plus-icon:hover{background-color:rgba(255, 255, 255, 0.1);}
.side-menu-wrap .side-menu-ul .sidenav__item .side-sub-menu{display:none;}
.side-menu-wrap .side-menu-ul .sidenav__item .side-sub-menu li{border-bottom:1px solid rgba(255, 255, 255, 0.12);}
.side-menu-wrap .side-menu-ul .sidenav__item .side-sub-menu li:first-child{border-top:1px solid rgba(255, 255, 255, 0.12);}
.side-menu-wrap .side-menu-ul .sidenav__item .side-sub-menu li a{padding-left:30px;padding-top:12px;padding-bottom:12px;position:relative;text-transform:capitalize;}
.side-menu-wrap .side-menu-ul .sidenav__item .side-sub-menu li a:hover{color:#51be78;}
.side-menu-wrap .side-menu-ul .sidenav__item:after{position:absolute;content:'';bottom:0;left:0;width:100%;height:1px;background-color:rgba(255, 255, 255, 0.12);}
.side-menu-wrap .side-menu-ul .sidenav__item:hover > a{background-color:rgba(255, 255, 255, 0.12);}
.side-menu-wrap .side-menu-ul .sidenav__item.active .menu-plus-icon:before{-webkit-transform:rotate(0deg);-moz-transform:rotate(0deg);-ms-transform:rotate(0deg);-o-transform:rotate(0deg);transform:rotate(0deg);}
.side-btn-box{text-align:center;margin-top:40px;}
.side-btn-box .theme-btn{line-height:40px;background-color:rgba(255, 255, 255, 0.1);border:none;color:#fff;}
.side-btn-box .theme-btn:hover{background-color:#51be78;}
.side-btn-box span{display:inline-block;margin-right:10px;margin-left:10px;font-size:22px;}
.user-nav-container{left:auto;right:0;background-color:#fff;overflow-x:hidden;z-index:999;-webkit-transform:translateX(100%);-moz-transform:translateX(100%);-ms-transform:translateX(100%);-o-transform:translateX(100%);transform:translateX(100%);}
.user-nav-container .humburger-menu .humburger-menu-lines{top:10px;width:18px;height:18px;}
.user-nav-container .humburger-menu .humburger-menu-lines:before, .user-nav-container .humburger-menu .humburger-menu-lines:after{background-color:#233d63;}
.user-nav-container .section-tab .nav-tabs{padding:50px 20px 0 20px;}
.user-nav-container .section-tab .nav-tabs li{margin-bottom:0;}
.user-nav-container .user-panel-content{margin-top:10px;}
.user-nav-container .user-sidebar-item .mess__body{max-height:inherit;overflow-x:inherit;overflow-y:inherit;}
.user-nav-container .user-action-item .mess__title{border:none;}
.user-nav-container.active{-webkit-transform:translateX(0);-moz-transform:translateX(0);-ms-transform:translateX(0);-o-transform:translateX(0);transform:translateX(0);}
.course-dashboard-header{background-color:#233d63;}
.course-dashboard-title{flex:1;margin-left:40px;color:#fff;}
@media (max-width:768px){.course-dashboard-title{margin-left:10px;line-height:25px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.course-dashboard-title{display:inline-block;}
}
@media (max-width:575px){.course-dashboard-title{display:block;margin-left:0;}
}
.course-dashboard-title a{color:#fff;}
.course-dashboard-menu-content{padding-right:0;padding-left:0;background-color:#233d63;}
.course-dashboard-menu-content .container-fluid{padding-right:15px;padding-left:15px;}
.course-dashboard-menu-content .logo-box{width:200px;border-right:1px solid rgba(255, 255, 255, 0.1);}
@media (max-width:768px){.course-dashboard-menu-content .logo-box{width:160px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.course-dashboard-menu-content .logo-box{width:auto;display:inline-block;border-right:none;}
}
@media (max-width:575px){.course-dashboard-menu-content .logo-box{width:100%;display:block;border-right:none;}
}
.course-dashboard-menu-content .main-menu-content{height:80px;}
@media only screen and (min-width:480px) and (max-width:767px){.course-dashboard-menu-content .main-menu-content{display:block !important;height:auto;padding-top:15px;padding-bottom:15px;text-align:center;}
}
@media (max-width:575px){.course-dashboard-menu-content .main-menu-content{display:block !important;height:auto;padding-top:15px;padding-bottom:15px;text-align:center;}
}
.course-dashboard-menu-content .menu-wrapper{width:30%;}
@media (max-width:1024px){.course-dashboard-menu-content .menu-wrapper{width:auto;}
}
@media only screen and (min-width:480px) and (max-width:767px){.course-dashboard-menu-content .menu-wrapper{padding-top:20px;display:block;}
}
@media (max-width:575px){.course-dashboard-menu-content .menu-wrapper{padding-top:20px;display:block;}
}
@media (max-width:575px){.course-dashboard-menu-content .logo-right-button{width:100%;}
}
@media (max-width:320px){.course-dashboard-menu-content .logo-right-button ul{display:block !important;}
}
@media only screen and (min-width:480px) and (max-width:767px){.course-dashboard-menu-content .logo-right-button ul{-ms-flex-pack:justify;justify-content:center;}
}
@media only screen and (min-width:320px) and (max-width:479px){.course-dashboard-menu-content .logo-right-button ul{-ms-flex-pack:justify;justify-content:center;}
}
.course-dashboard-menu-content .logo-right-button ul li{margin-right:14px;}
@media (max-width:320px){.course-dashboard-menu-content .logo-right-button ul li{display:inline-block;margin-right:5px;}
}
.course-dashboard-menu-content .logo-right-button ul li:last-child{margin-right:0;}
@media (max-width:375px){.course-dashboard-menu-content .logo-right-button ul li:last-child{vertical-align:bottom;}
}
.course-dashboard-menu-content .logo-right-button ul li .msg-action-dot .action-dot{display:block;color:#fff;font-size:30px;width:40px;height:40px;line-height:40px;text-align:center;background-color:rgba(127, 136, 151, 0.1);border:1px solid rgba(127, 126, 151, 0.2);-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;}
.course-dashboard-menu-content .logo-right-button ul li .msg-action-dot .action-dot:hover{background-color:#51be78;}
.course-dashboard-menu-content .logo-right-button ul li .msg-action-dot .dropdown-menu{margin-top:45px;right:0;left:auto !important;}
.course-dashboard-menu-content .logo-right-button .theme-btn{color:#fff;line-height:40px;}
@media (max-width:320px){.course-dashboard-menu-content .logo-right-button .theme-btn{padding-right:10px;padding-left:10px;}
}
@media (max-width:320px){.main-menu-content-2 .logo-box .logo{margin-bottom:0;display:inline-block;text-align:left;}
}
.dashboard-menu-content{padding-right:40px;padding-left:40px;-webkit-box-shadow:0 0 40px rgba(82, 85, 90, 0.1);-moz-box-shadow:0 0 40px rgba(82, 85, 90, 0.1);box-shadow:0 0 40px rgba(82, 85, 90, 0.1);}
@media (max-width:1440px){.dashboard-menu-content{padding-right:0;padding-left:0;}
}
.dashboard-menu-content .logo-box{width:305px;height:100%;border-right:1px solid rgba(127, 136, 151, 0.2);}
@media (max-width:1440px){.dashboard-menu-content .logo-box{width:auto;border-right:none;}
}
@media (max-width:320px){.dashboard-menu-content .logo-box .logo{margin-bottom:0;}
}
@media only screen and (min-width:768px) and (max-width:991px){.dashboard-menu-content .main-menu{padding-top:0;}
}
@media only screen and (min-width:480px) and (max-width:767px){.dashboard-menu-content .main-menu{padding-top:0;}
}
@media only screen and (min-width:320px) and (max-width:479px){.dashboard-menu-content .main-menu{padding-top:0;}
}
@media (max-width:1440px){.dashboard-menu-content .menu-wrapper .contact-form-action{margin-left:0;width:300px;}
}
.dashboard-menu-content .menu-wrapper .contact-form-action .form-control{background-color:rgba(127, 136, 151, 0.08);border-color:transparent;}
.dashboard-menu-content .menu-wrapper .contact-form-action .form-control:focus{border-color:rgba(127, 136, 151, 0.4);}
@media only screen and (min-width:768px) and (max-width:991px){.dashboard-menu-content .logo-right-button{display:none !important;}
}
@media only screen and (min-width:480px) and (max-width:767px){.dashboard-menu-content .logo-right-button{display:none !important;}
}
@media only screen and (min-width:320px) and (max-width:479px){.dashboard-menu-content .logo-right-button{display:none !important;}
}
@media (max-width:600px){.dashboard-menu-content .logo-right-button .cart-dropdown-menu{right:0;}
}
@media (max-width:425px){.dashboard-menu-content .logo-right-button .cart-dropdown-menu{width:300px;}
}
@media (max-width:320px){.dashboard-menu-content .logo-right-button .cart-dropdown-menu{right:-35px;}
}
@media (max-width:600px){.dashboard-menu-content .logo-right-button .cart-dropdown-menu:after{right:30px;-webkit-transform:translateX(0) rotate(45deg);-moz-transform:translateX(0) rotate(45deg);-ms-transform:translateX(0) rotate(45deg);-o-transform:translateX(0) rotate(45deg);transform:translateX(0) rotate(45deg);}
}
@media (max-width:375px){.dashboard-menu-content .logo-right-button .course-cart .cart-dropdown-menu{right:-75px;}
}
@media (max-width:320px){.dashboard-menu-content .logo-right-button .course-cart .cart-dropdown-menu{right:-110px;}
}
@media (max-width:375px){.dashboard-menu-content .logo-right-button .course-cart .cart-dropdown-menu:after{right:50%;-webkit-transform:translateX(50%) rotate(45deg);-moz-transform:translateX(50%) rotate(45deg);-ms-transform:translateX(50%) rotate(45deg);-o-transform:translateX(50%) rotate(45deg);transform:translateX(50%) rotate(45deg);}
}
.dashboard-menu-content .logo-right-button .dropdown-menu{left:auto !important;-webkit-transform:translate3d(0, 0, 0) !important;-moz-transform:translate3d(0, 0, 0) !important;-ms-transform:translate3d(0, 0, 0) !important;-o-transform:translate3d(0, 0, 0) !important;transform:translate3d(0, 0, 0) !important;-webkit-box-shadow:0 2px 40px rgba(82, 85, 90, 0.1);-moz-box-shadow:0 2px 40px rgba(82, 85, 90, 0.1);box-shadow:0 2px 40px rgba(82, 85, 90, 0.1);right:0;margin-top:59px;width:310px;padding:0;border:1px solid rgba(127, 136, 151, 0.1);-webkit-border-radius:8px;-moz-border-radius:8px;border-radius:8px;}
@media only screen and (min-width:768px) and (max-width:991px){.dashboard-menu-content .logo-right-button .dropdown-menu{right:-70px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.dashboard-menu-content .logo-right-button .dropdown-menu{right:-70px;}
}
@media (max-width:375px){.dashboard-menu-content .logo-right-button .dropdown-menu{width:300px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.dashboard-menu-content .logo-right-button .dropdown-menu{right:-70px;}
}
.dashboard-menu-content .logo-right-button .dropdown-menu.show{-webkit-transform:translate3d(0, 0, 0) !important;-moz-transform:translate3d(0, 0, 0) !important;-ms-transform:translate3d(0, 0, 0) !important;-o-transform:translate3d(0, 0, 0) !important;transform:translate3d(0, 0, 0) !important;}
.dashboard-menu-content .logo-right-button .header-action-button{margin-right:20px;}
@media only screen and (min-width:768px) and (max-width:991px){.dashboard-menu-content .logo-right-button .header-action-button{margin-right:0;}
}
@media only screen and (min-width:480px) and (max-width:767px){.dashboard-menu-content .logo-right-button .header-action-button{margin-right:0;}
}
@media only screen and (min-width:320px) and (max-width:479px){.dashboard-menu-content .logo-right-button .header-action-button{margin-right:0;}
}
@media (max-width:375px){.dashboard-menu-content .logo-right-button .header-action-button .notification-wrap .dropdown-menu{right:-100px;}
}
.dashboard-menu-content .logo-right-button .header-action-button .user-action-wrap .dropdown-menu{width:280px;}
.dashboard-menu-content .logo-right-button .header-action-button.active{display:block;}
@media (max-width:1280px){.my-course-menu-content .logo-box{width:auto;border-right:none;}
}
@media only screen and (min-width:768px) and (max-width:991px){.my-course-menu-content .logo-right-button{padding-top:20px;padding-bottom:20px;-ms-flex-pack:end;justify-content:flex-end;}
}
@media only screen and (min-width:480px) and (max-width:767px){.my-course-menu-content .logo-right-button{padding-top:20px;padding-bottom:20px;-ms-flex-pack:end;justify-content:flex-end;}
}
@media only screen and (min-width:320px) and (max-width:479px){.my-course-menu-content .logo-right-button{padding-top:20px;padding-bottom:20px;-ms-flex-pack:end;justify-content:flex-end;}
}
.dot-status{position:relative;}
.dot-status:before{position:absolute;content:"";bottom:0;right:0;height:14px;width:14px;background-color:#eee;display:block;border:2px solid #fff;box-shadow:0 1px 3px rgba(0, 0, 0, 0.2);-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;}
.notification-btn{position:relative;border:none;width:40px;height:40px;line-height:40px;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;background-color:rgba(81, 190, 120, 0.1);color:#51be78;font-size:20px;outline:none !important;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.notification-btn:after{display:none;}
.notification-btn:hover{background-color:#51be78;color:#fff;}
.notification-btn[aria-expanded="true"]{background-color:#51be78;color:#fff;}
.notification-item .quantity{position:absolute;display:inline-block;top:-7px;right:-6px;height:22px;width:22px;line-height:19px;text-align:center;background-color:#51be78;color:#fff;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;font-size:11px;border:2px solid #fff;font-weight:700;}
.dashboard-nav-trigger{padding:30px 15px 0px 15px;display:none;}
@media (max-width:1024px){.dashboard-nav-trigger{display:block;}
}
.dashboard-nav-trigger-btn{background-color:rgba(127, 136, 151, 0.1);border:1px solid rgba(127, 136, 151, 0.2);padding:10px 15px 10px 45px;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;cursor:pointer;color:#7f8897;font-size:16px;display:inline-block;position:relative;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.dashboard-nav-trigger-btn .la{font-size:20px;position:absolute;top:50%;left:15px;-webkit-transform:translateY(-50%);-moz-transform:translateY(-50%);-ms-transform:translateY(-50%);-o-transform:translateY(-50%);transform:translateY(-50%);}
.dashboard-nav-trigger-btn:hover{background-color:rgba(127, 136, 151, 0.2);border:1px solid rgba(127, 136, 151, 0.5);color:#233d63;}
.dashboard-sidebar .dashboard-nav-container{-webkit-transform:translateX(0);-moz-transform:translateX(0);-ms-transform:translateX(0);-o-transform:translateX(0);transform:translateX(0);background-color:#fff;width:344px;padding-top:140px;}
@media (max-width:1440px){.dashboard-sidebar .dashboard-nav-container{width:300px;}
}
@media (max-width:1024px){.dashboard-sidebar .dashboard-nav-container{-webkit-transform:translateX(-100%);-moz-transform:translateX(-100%);-ms-transform:translateX(-100%);-o-transform:translateX(-100%);transform:translateX(-100%);}
}
.dashboard-sidebar .dashboard-nav-container .humburger-menu .humburger-menu-lines{opacity:0;visibility:hidden;top:120px;width:18px;height:18px;}
@media (max-width:1024px){.dashboard-sidebar .dashboard-nav-container .humburger-menu .humburger-menu-lines{opacity:0.5;visibility:visible;}
}
.dashboard-sidebar .dashboard-nav-container .humburger-menu .humburger-menu-lines:before, .dashboard-sidebar .dashboard-nav-container .humburger-menu .humburger-menu-lines:after{background-color:#233d63;}
.dashboard-sidebar .dashboard-nav-container .side-menu-wrap{margin-top:0;}
@media (max-width:1024px){.dashboard-sidebar .dashboard-nav-container .side-menu-wrap{margin-top:20px;}
}
.dashboard-sidebar .dashboard-nav-container .side-menu-ul .sidenav__item{margin-right:20px;-webkit-border-radius:0 100px 100px 0;-moz-border-radius:0 100px 100px 0;border-radius:0 100px 100px 0;}
.dashboard-sidebar .dashboard-nav-container .side-menu-ul .sidenav__item a{color:#233d63;text-transform:capitalize;display:-webkit-flex;display:-ms-flex;display:flex;-ms-flex-align:center;align-items:center;-webkit-border-radius:0 100px 100px 0;-moz-border-radius:0 100px 100px 0;border-radius:0 100px 100px 0;padding-top:10px;padding-bottom:10px;}
.dashboard-sidebar .dashboard-nav-container .side-menu-ul .sidenav__item a i{margin-right:10px;font-size:18px;}
.dashboard-sidebar .dashboard-nav-container .side-menu-ul .sidenav__item:hover > a{color:#51be78;background-color:inherit;}
.dashboard-sidebar .dashboard-nav-container .side-menu-ul .sidenav__item.page-active, .dashboard-sidebar .dashboard-nav-container .side-menu-ul .sidenav__item.active{background-color:#51be78;}
.dashboard-sidebar .dashboard-nav-container .side-menu-ul .sidenav__item.page-active > a, .dashboard-sidebar .dashboard-nav-container .side-menu-ul .sidenav__item.active > a{color:#fff;}
.dashboard-sidebar .dashboard-nav-container .side-menu-ul .sidenav__item:after{display:none;}
.dashboard-sidebar .dashboard-nav-container.active{-webkit-transform:translateX(0);-moz-transform:translateX(0);-ms-transform:translateX(0);-o-transform:translateX(0);transform:translateX(0);}
.dashboard-content-wrap{height:100vh;padding-top:45px;padding-left:368px;padding-right:30px;}
@media (max-width:1440px){.dashboard-content-wrap{padding-left:330px;}
}
@media (max-width:1024px){.dashboard-content-wrap{padding-left:0;padding-right:0;}
}
@media (max-width:575px){.user-bread-content{margin-bottom:30px;}
}
@media (max-width:375px){.user-bread-content{display:block !important;}
}
.user-bread-content .bread-img-wrap{-ms-flex-negative:0;flex-shrink:0;}
.user-bread-content .section-heading .section__title{color:#233d63;}
@media (max-width:480px){.breadcrumb-content.dashboard-bread-content .bread-img-wrap{margin-right:20px;margin-bottom:0;}
}
@media (max-width:375px){.breadcrumb-content.dashboard-bread-content .bread-img-wrap{margin-right:0;margin-bottom:20px;}
}
@media (max-width:575px){.dashboard-bread-content{display:block !important;}
}
.dashboard-bread-content .upload-btn-box{width:270px;}
.dashboard-bread-content .upload-btn-box .jFiler-input{margin-left:auto;}
@media (max-width:575px){.dashboard-bread-content .upload-btn-box .jFiler-input{margin-left:0;}
}
.user-pro-img{position:relative;width:200px;margin-right:30px;}
.user-pro-img img{width:100%;}
.payment-option .radio-trigger{background-color:#fff;-webkit-box-shadow:0 0 8px 0px rgba(0, 0, 0, 0.03);-moz-box-shadow:0 0 8px 0px rgba(0, 0, 0, 0.03);box-shadow:0 0 8px 0px rgba(0, 0, 0, 0.03);padding:20px 20px 20px 55px;-webkit-border-radius:8px;-moz-border-radius:8px;border-radius:8px;width:100%;height:100%;}
.payment-option .radio-trigger .checkmark{top:0;left:0;width:100%;height:100%;-webkit-border-radius:8px;-moz-border-radius:8px;border-radius:8px;border:1px solid rgba(127, 136, 151, 0.2);}
.payment-option .radio-trigger .checkmark:after{top:28px;left:28px;width:10px;height:10px;}
.payment-option .radio-trigger .checkmark:before{width:20px;height:20px;position:absolute;content:"";border:2px solid rgba(127, 136, 151, 0.3);top:23px;left:23px;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.profile-detail .list-items li{padding-left:0;border-bottom:1px solid rgba(127, 136, 151, 0.1);padding-bottom:15px;margin-bottom:15px;display:-webkit-flex;display:-ms-flex;display:flex;}
@media (max-width:575px){.profile-detail .list-items li{display:block;}
}
.profile-detail .list-items li:last-child{margin-bottom:0;padding-bottom:0;border-bottom:none;}
.profile-detail .list-items li .profile-name{color:#233d63;font-weight:600;width:300px;vertical-align:top;}
@media (max-width:575px){.profile-detail .list-items li .profile-name{width:auto;display:block;margin-bottom:4px;}
}
.profile-detail .list-items li .profile-desc{width:500px;}
@media only screen and (min-width:768px) and (max-width:991px){.profile-detail .list-items li .profile-desc{width:400px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.profile-detail .list-items li .profile-desc{width:400px;}
}
@media (max-width:575px){.profile-detail .list-items li .profile-desc{width:auto;}
}
.profile-detail .list-items li:after{display:none;}
.dashboard-message-wrapper{border:1px solid rgba(127, 136, 151, 0.2);-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;background-color:#fff;-webkit-box-shadow:0 0 18px 0 rgba(0, 0, 0, 0.01);-moz-box-shadow:0 0 18px 0 rgba(0, 0, 0, 0.01);box-shadow:0 0 18px 0 rgba(0, 0, 0, 0.01);}
@media only screen and (min-width:480px) and (max-width:767px){.dashboard-message-wrapper{display:block !important;}
}
@media only screen and (min-width:320px) and (max-width:479px){.dashboard-message-wrapper{display:block !important;}
}
.message-sidebar{width:320px;border-right:1px solid rgba(127, 136, 151, 0.2);}
@media (max-width:1199px){.message-sidebar{width:250px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.message-sidebar{width:auto;}
}
@media only screen and (min-width:320px) and (max-width:479px){.message-sidebar{width:auto;}
}
.message-search{padding:27px 30px;border-bottom:1px solid rgba(127, 136, 151, 0.2);}
.message-search .contact-form-action .form-control{padding-left:20px;background-color:rgba(127, 136, 151, 0.08);-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;border-color:transparent;padding-top:10px;padding-bottom:10px;}
.message-search .contact-form-action .form-control:focus{border-color:rgba(127, 136, 151, 0.4);}
.message-content .mess__item{padding-right:30px;padding-left:30px;}
@media (max-width:375px){.message-content .mess__item{padding-right:15px;padding-left:15px;}
}
.message-content .mess__item:hover{background-color:transparent;}
@media (max-width:375px){.message-header .mess__item{display:block !important;}
}
@media (max-width:375px){.message-header .info-list{margin-top:15px;}
}
.message-inbox-item .mess__body{max-height:435px;}
.message-inbox-item .mess__item{padding:20px 30px 0 30px;border-bottom:none;}
.message-inbox-item .mess__item .avatar{width:50px;height:50px;}
.message-inbox-item .mess__item .avatar img{width:50px;height:50px;}
.message-inbox-item .content .widget-title{padding-top:0;}
.message-inbox-item .content p{margin-bottom:0;}
.message-inbox{position:relative;}
.message-inbox:after{position:absolute;content:"";top:0;left:0;width:3px;height:100%;background-color:#fff;-webkit-border-radius:0 4px 4px 0;-moz-border-radius:0 4px 4px 0;border-radius:0 4px 4px 0;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.message-inbox:hover{background-color:rgba(81, 190, 120, 0.05);}
.message-inbox:hover:after{background-color:#51be78;}
.message-active{background-color:rgba(81, 190, 120, 0.05);}
.message-active:after{background-color:#51be78;}
.msg-action-bar{padding:14px 30px;font-size:13px;border-top:1px solid rgba(127, 136, 151, 0.2);}
@media (max-width:375px){.msg-action-bar{padding-right:15px;padding-left:15px;}
}
.msg-action-bar a{color:#233d63;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;background-color:rgba(127, 136, 151, 0.1);padding:0 8px;-webkit-border-radius:30px;-moz-border-radius:30px;border-radius:30px;}
.msg-action-bar a:hover{background-color:rgba(127, 136, 151, 0.2);}
.comment-action-dot .dropdown-menu{left:auto !important;-webkit-box-shadow:0 2px 40px rgba(82, 85, 90, 0.1);-moz-box-shadow:0 2px 40px rgba(82, 85, 90, 0.1);box-shadow:0 2px 40px rgba(82, 85, 90, 0.1);right:0;margin-top:30px;}
@media (max-width:1440px){.data-table{display:block !important;}
}
@media (max-width:1440px){.data-list{margin-bottom:20px;}
}
.data-list .theme-btn{background-color:#fff;color:#7f8897;text-transform:capitalize;margin-left:-1px;border-width:1px;border-color:rgba(127, 136, 151, 0.2);cursor:pointer;-webkit-border-radius:0;-moz-border-radius:0;border-radius:0;}
.data-list .theme-btn:first-child{-webkit-border-radius:4px 0 0 4px;-moz-border-radius:4px 0 0 4px;border-radius:4px 0 0 4px;}
.data-list .theme-btn:last-child{-webkit-border-radius:0 4px 4px 0;-moz-border-radius:0 4px 4px 0;border-radius:0 4px 4px 0;}
.data-list .theme-btn.active{background-color:#51be78;color:#fff;}
.data-search .theme-btn{font-size:22px;padding-right:15px;padding-left:15px;line-height:46px;}
.statement-table .table{color:#233d63;margin-bottom:0;}
.statement-table .table thead{background-color:rgba(127, 126, 151, 0.1);}
.statement-table .table thead th{border:none;}
.statement-table .table tbody th,
.statement-table .table tbody td{padding-top:20px;padding-bottom:20px;border-top:none;border-bottom:1px solid rgba(127, 126, 151, 0.1);}
.balance-info li,
.statement-info li{color:#7f8897;font-weight:500;padding-left:0;}
.balance-info li:after,
.statement-info li:after{display:none;}
.statement-info .list-items li img{width:120px;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;margin-right:10px;}
.purchase-table .table th,
.purchase-table .table td{vertical-align:middle;}
.skill{margin-bottom:20px;}
.skill .widget-title{font-size:17px;}
.skill span{color:#233d63;}
.skill span:last-of-type{float:right;font-size:16px;color:#7f8897;font-weight:600;}
.progress_bg{background-color:rgba(127, 136, 151, 0.1);-webkit-border-radius:30px;-moz-border-radius:30px;border-radius:30px;margin-top:10px;}
.progress_bg .progress_bar{height:10px;background-color:#51be78;-webkit-border-radius:30px;-moz-border-radius:30px;border-radius:30px;position:relative;}
.progress_bg .progress_bar:after{position:absolute;content:'';top:-4px;right:-1px;width:18px;height:18px;background-color:#51be78;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;-webkit-box-shadow:0 0 1px rgba(0, 0, 0, 0.2);-moz-box-shadow:0 0 1px rgba(0, 0, 0, 0.2);box-shadow:0 0 1px rgba(0, 0, 0, 0.2);}
.progress-item{display:-webkit-flex;display:-ms-flex;display:flex;-ms-flex-align:center;align-items:center;margin-bottom:5px;position:relative;}
@media (max-width:1199px){.progress-item{display:block;}
}
.skillbar-box{width:50%;}
@media (max-width:1199px){.skillbar-box{width:100%;}
}
.skillbar{position:relative;display:block;width:100%;background-color:#eee;height:5px;-webkit-border-radius:30px;-moz-border-radius:30px;border-radius:30px;-webkit-transition:all 0.4s linear;-moz-transition:all 0.4s linear;-ms-transition:all 0.4s linear;-o-transition:all 0.4s linear;transition:all 0.4s linear;-webkit-transition-property:width, background-color;-moz-transition-property:width, background-color;-ms-transition-property:width, background-color;-o-transition-property:width, background-color;transition-property:width, background-color;}
.skillbar-title{font-size:15px;width:30%;}
@media (max-width:1199px){.skillbar-title{width:100%;}
}
.skillbar-bar{height:5px;width:0;background-color:#7f8897;-webkit-border-radius:30px;-moz-border-radius:30px;border-radius:30px;}
.skillbar-bar-bg-1{background-color:#51be78;}
.skillbar-bar-bg-2{background-color:#7E3CF9;}
.skillbar-bar-bg-3{background-color:#F68A03;}
.skillbar-bar-bg-4{background-color:#42be6f;}
.skillbar-bar-bg-5{background-color:#38BB0C;}
.skillbar-bar-bg-6{background-color:#3AAAE1;}
.skill-bar-percent{width:20%;text-align:right;font-size:15px;font-weight:600;color:#233d63;}
@media (max-width:1199px){.skill-bar-percent{position:absolute;width:auto;top:0;right:0;}
}
.course-complete-bar .skillbar-title{width:8%;}
.course-complete-bar .skill-bar-percent{width:5%;}
.course-complete-bar-2 .skillbar-title{width:24%;}
@media (max-width:1199px){.course-complete-bar-2 .skillbar-title{width:100%;}
}
.course-complete-bar-2 .skillbar-box{width:55%;}
@media (max-width:1199px){.course-complete-bar-2 .skillbar-box{width:100%;}
}
.slider-area{position:relative;z-index:5;}
.slider-area .owl-dots{position:relative;bottom:140px;}
.slider-area:hover .owl-nav div.owl-prev{left:40px;}
@media (max-width:1280px){.slider-area:hover .owl-nav div.owl-prev{left:20px;}
}
.slider-area:hover .owl-nav div.owl-next{right:40px;}
@media (max-width:1280px){.slider-area:hover .owl-nav div.owl-next{right:20px;}
}
.single-slide-item{position:relative;background-size:contain;color:#fff;/*padding-top:250px;padding-bottom:330px;*/z-index:2;background-repeat: no-repeat;background-position: center right;}
@media only screen and (min-width:480px) and (max-width:767px){.single-slide-item{padding-top:200px;padding-bottom:300px;}
}
@media (max-width:480px){.single-slide-item{padding-top:140px;padding-bottom:250px;}
}
.single-slide-item .section-heading{position:relative;z-index:2;}
.single-slide-item .section-heading .section__title{font-size:50px;line-height:60px;font-weight:700;color:#fff;margin-bottom:20px;}
@media only screen and (min-width:768px) and (max-width:991px){.single-slide-item .section-heading .section__title{font-size:50px;line-height:60px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.single-slide-item .section-heading .section__title{font-size:50px;line-height:60px;}
}
@media (max-width:481px){.single-slide-item .section-heading .section__title{font-size:36px;line-height:50px;}
}
.single-slide-item .section-heading .section__desc{font-size:18px;line-height:34px;margin-bottom:36px;}
.single-slide-item .btn-box{position:relative;z-index:2;}
@media (max-width:375px){.single-slide-item .btn-box{text-align:center;-ms-flex-wrap:wrap;flex-wrap:wrap;-ms-flex-pack:justify;justify-content:center;}
}
@media (max-width:375px){.single-slide-item .hero-btn-right .btn-text{margin-top:0;margin-bottom:20px;}
}
.single-slide-item:after{position:absolute;left:0;top:0;height:100%;width:100%;background-color:#233d63;opacity:.90;content:"";z-index:-1;}
.single-slide-item:before{position:absolute;content:'';top:0;left:0;width:100%;height:100%;background-image:url("../images/shape.png");background-size:cover;background-position:center;z-index:1;opacity:0.1;}
.hero-slide .section-heading .section__title,
.hero-slide .section-heading .section__desc{-webkit-transform:translateY(70px);-moz-transform:translateY(70px);-ms-transform:translateY(70px);-o-transform:translateY(70px);transform:translateY(70px);opacity:0;visibility:hidden;}
.hero-slide .btn-box{opacity:0;visibility:hidden;-webkit-transform:translateY(60px);-moz-transform:translateY(60px);-ms-transform:translateY(60px);-o-transform:translateY(60px);transform:translateY(60px);}
.hero-slide .owl-item.active .section-heading .section__title,
.hero-slide .owl-item.active .section-heading .section__desc{opacity:1;visibility:visible;-webkit-transform:translateY(0);-moz-transform:translateY(0);-ms-transform:translateY(0);-o-transform:translateY(0);transform:translateY(0);}
.hero-slide .owl-item.active .section-heading .section__title{-webkit-transition:all 700ms ease;-moz-transition:all 700ms ease;-ms-transition:all 700ms ease;-o-transition:all 700ms ease;transition:all 700ms ease;-webkit-transition-delay:300ms;-moz-transition-delay:300ms;-ms-transition-delay:300ms;-o-transition-delay:300ms;transition-delay:300ms;}
.hero-slide .owl-item.active .section-heading .section__desc{-webkit-transition:all 700ms ease;-moz-transition:all 700ms ease;-ms-transition:all 700ms ease;-o-transition:all 700ms ease;transition:all 700ms ease;-webkit-transition-delay:700ms;-moz-transition-delay:700ms;-ms-transition-delay:700ms;-o-transition-delay:700ms;transition-delay:700ms;}
.hero-slide .owl-item.active .btn-box{opacity:1;visibility:visible;-webkit-transform:translateY(0);-moz-transform:translateY(0);-ms-transform:translateY(0);-o-transform:translateY(0);transform:translateY(0);-webkit-transition:all 700ms ease;-moz-transition:all 700ms ease;-ms-transition:all 700ms ease;-o-transition:all 700ms ease;transition:all 700ms ease;-webkit-transition-delay:1100ms;-moz-transition-delay:1100ms;-ms-transition-delay:1100ms;-o-transition-delay:1100ms;transition-delay:1100ms;}
.single-slide-item-2{padding-top:100px;padding-bottom:0;}
@media (max-width:320px){.single-slide-item-2{padding-top:150px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.single-slide-item-2 .section-heading{text-align:center;}
}
@media only screen and (min-width:320px) and (max-width:479px){.single-slide-item-2 .section-heading{text-align:center;}
}
@media (max-width:481px){.single-slide-item-2 .section-heading .section__title{font-size:36px;line-height:50px;}
}
.single-slide-item-2:before{display:none;}
.single-slide-item-2:after{opacity:0.7;}
.single-slide-item-3{padding-top:140px;padding-bottom:150px;}
@media (max-width:425px){.single-slide-item-3{padding-top:120px;padding-bottom:130px;}
}
.single-slide-item-3 .section-heading .section__title{font-size:50px;line-height:60px;}
@media (max-width:425px){.single-slide-item-3 .section-heading .section__desc{font-size:16px;line-height:28px !important;}
}
.single-slide-item-3:before{display:none;}
.single-slide-item-3:after{opacity:0.9;}
@media only screen and (min-width:768px) and (max-width:991px){.hero-category{margin-top:50px !important;}
}
@media only screen and (min-width:480px) and (max-width:767px){.hero-category{margin-top:50px !important;}
}
@media only screen and (min-width:320px) and (max-width:479px){.hero-category{margin-top:50px !important;}
}
.hero-category .row{margin-right:-5px;margin-left:-5px;}
.hero-category .col-lg-4{padding-right:5px;padding-left:5px;}
.hero-search-form .contact-form-action{padding-right:40px;}
@media only screen and (min-width:480px) and (max-width:767px){.hero-search-form .contact-form-action{padding-right:0;}
}
@media only screen and (min-width:320px) and (max-width:479px){.hero-search-form .contact-form-action{padding-right:0;}
}
.hero-search-form .contact-form-action .section__meta{text-transform:inherit;background-color:#fff;color:#233d63;padding-top:0;padding-bottom:0;margin-bottom:10px;font-size:14px;font-weight:400;}
.hero-search-form .contact-form-action .form-control{padding-left:20px;border:none;height: auto;}
.our-post-content{background:#429b85;background:-moz-linear-gradient(-45deg, #429b85 0%, #5f7b8c 100%);background:-webkit-linear-gradient(-45deg, #429b85 0%, #5f7b8c 100%);background:linear-gradient(135deg, #429b85 0%, #5f7b8c 100%);margin-top:150px;padding:30px 0;position:relative;overflow:hidden;}
@media only screen and (min-width:768px) and (max-width:991px){.our-post-content{padding-bottom:0;}
}
@media only screen and (min-width:480px) and (max-width:767px){.our-post-content{padding-bottom:0;}
}
@media only screen and (min-width:320px) and (max-width:479px){.our-post-content{padding-bottom:0;}
}
@media only screen and (min-width:768px) and (max-width:991px){.our-post-content .how-we-work-wrap .col-lg-4{-ms-flex:0 0 50%;flex:0 0 50%;max-width:50%;}
}
.our-post-content .hw-circle{width:50px;height:50px;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;position:absolute;border:8px solid #fff;opacity:0.1;}
.our-post-content .hw-circle:nth-child(1){top:-20px;left:30px;}
.our-post-content .hw-circle:nth-child(2){top:73%;left:50%;}
.our-post-content .hw-circle:nth-child(3){top:5px;right:10%;}
.our-post-item{display:-webkit-flex;display:-ms-flex;display:flex;-ms-flex-align:center;align-items:center;}
@media (max-width:1199px){.our-post-item{display:block;text-align:center;}
}
@media only screen and (min-width:768px) and (max-width:991px){.our-post-item{margin-bottom:30px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.our-post-item{margin-bottom:30px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.our-post-item{margin-bottom:30px;}
}
.our-post-item .icon-element{font-size:30px;width:55px;height:55px;line-height:55px;color:#fff;background-color:rgba(255, 255, 255, 0.1);}
.our-post-item .widget-title{color:#fff;font-size:20px;font-weight:600;}
.our-post-item .our__text{margin-left:14px;}
@media (max-width:1199px){.our-post-item .our__text{margin-left:0;margin-top:20px;}
}
.our-post-item .our__text p{font-size:15px;}
.our-post-item:hover .icon-element{background-color:#fff;color:#51be78;}
.breadcrumb-area{background-color:#F7FAFD;height:200px;display:-webkit-flex;display:-ms-flex;display:flex;-ms-flex-align:center;align-items:center;position:relative;text-align:center;z-index:2;color:#fff;background-size:cover;background-position:center;}
@media (max-width:375px){.breadcrumb-area{height:200px;}
}
.breadcrumb-area:before{position:absolute;content:'';top:0;left:0;width:100%;height:100%;background-color:#233d63;opacity:0.9;z-index:-1;}
.breadcrumb-content{position:relative;z-index:3;}
.breadcrumb-content .bread-img-wrap{margin-right:20px;width:110px;height:110px;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;}
@media (max-width:480px){.breadcrumb-content .bread-img-wrap{margin-right:0;margin-bottom:20px;width:100px;height:100px;}
}
.breadcrumb-content .bread-img-wrap img{width:100%;height:100%;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;}
.breadcrumb-content .section__title{font-size:50px;text-transform:capitalize;font-weight:700;color:#fff;}
@media only screen and (min-width:768px) and (max-width:991px){.breadcrumb-content .section__title{font-size:50px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.breadcrumb-content .section__title{font-size:50px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.breadcrumb-content .section__title{font-size:40px;}
}
.breadcrumb-content .widget-title{color:#fff;font-size:20px;line-height:30px;font-weight:500;}
.breadcrumb-content .breadcrumb__list{margin-top:8px;}
.breadcrumb-content .breadcrumb__list li{display:inline-block;text-transform:capitalize;color:#F7FAFD;font-size:16px;font-weight:500;position:relative;padding-right:22px;}
.breadcrumb-content .breadcrumb__list li:last-child{padding-right:0;}
.breadcrumb-content .breadcrumb__list li a{color:#F7FAFD;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.breadcrumb-content .breadcrumb__list li a:hover{color:#51be78;}
.breadcrumb-content .breadcrumb__list li.active__list-item:after{position:absolute;content:'.';top:-6px;right:0;padding:0 6px;font-size:20px;}
.breadcrumb-detail-area{text-align:inherit;min-height:250px;}
/*@media (max-width:768px){.breadcrumb-detail-area{height:470px;}
}
@media (max-width:425px){.breadcrumb-detail-area{height:550px;}
}
@media (max-width:320px){.breadcrumb-detail-area{height:600px;}
}*/
.breadcrumb-content.breadcrumb-detail-content{padding-right:400px;}
.product-mob-action{display: none;}
@media only screen and (min-width:768px) and (max-width:991px){.breadcrumb-content.breadcrumb-detail-content{padding-right:0;}
}
@media only screen and (min-width:480px) and (max-width:767px){.breadcrumb-content.breadcrumb-detail-content{padding-right:0;}
}
@media only screen and (min-width:320px) and (max-width:479px){.breadcrumb-content.breadcrumb-detail-content{padding-right:0;}
}
@media only screen and (max-width:767px){
.sidebar-component .preview-video-and-details{display: none !important;}
.sidebar-component .product-mob-action{display: block;position: fixed;left:0px;bottom:0px;width:100%;z-index: 99;padding:10px;box-shadow: 0px 0px 18px 10px #c5c5c5;background-color: #ffffff;}
.sidebar-component .product-mob-action .theme-btn{margin-bottom: 0px !important;}
.product-mob-action .preview-course__price span{line-height: 52px;}
.product-mob-action .preview-course__price .price-current{font-size: 24px;color:#ff8d08;padding-right: 10px;}
.product-mob-action .preview-course__price .price-before{font-size: 16px;color:#989898;text-decoration:line-through;}
}
.breadcrumb-content.breadcrumb-detail-content .section__title{font-size:32px;}
.breadcrumb-content.breadcrumb-detail-content .widget-title{font-size:17px;}
.breadcrumb-content.breadcrumb-detail-content .breadcrumb__list li{text-transform:inherit;padding-right:10px;padding-bottom:3px;line-height:22px;font-size:15px;}
.breadcrumb-content.breadcrumb-detail-content .breadcrumb__list li:last-child{padding-bottom:0;}
.breadcrumb-content.breadcrumb-detail-content .breadcrumb__list li .la{color:#F68A03;margin-left:-1px;}
.breadcrumb-content.breadcrumb-detail-content .breadcrumb__list li .la-globe{color:#fff;margin-left:0;}
.about-breadcrumb{background-image:url("../images/about-breadcrumb.jpg");text-align:inherit;height:550px;}
.breadcrumb-content.about-breadcrumb-content .section__title{font-size:60px;line-height:75px;}
@media only screen and (min-width:480px) and (max-width:767px){.breadcrumb-content.about-breadcrumb-content .section__title{font-size:50px;line-height:65px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.breadcrumb-content.about-breadcrumb-content .section__title{font-size:50px;line-height:60px;}
}
.breadcrumb-content.about-breadcrumb-content .btn-box{margin-top:48px;display:inline-block;}
.breadcrumb-content.about-breadcrumb-content .btn-box .video-play-btn{display:-webkit-flex;display:-ms-flex;display:flex;-ms-flex-align:center;align-items:center;color:#fff;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.breadcrumb-content.about-breadcrumb-content .btn-box .video-play-btn:hover{color:#51be78;}
.breadcrumb-content.about-breadcrumb-content .btn-box .watch-video-btn{font-size:24px;width:70px;height:70px;line-height:70px;margin-right:15px;}
.breadcrumb-content.about-breadcrumb-content .btn-box .watch-video-btn:hover{color:#51be78;}
.breadcrumb-content.about-breadcrumb-content .btn-box .watch-video-btn:hover span{color:#51be78;}
.my-courses-bread{height:auto;text-align:left;display:block;padding-top:50px;}
.my-courses-bread:before{opacity:0.97;}
.my-courses-bread-content{padding-bottom:25px;}
.my-courses-bread-content .section__title{font-size:38px;}
.my-courses-tab .section-tab-2 .nav-tabs{border-bottom:none;}
.my-courses-tab .section-tab-2 .nav-tabs li{margin-bottom:0;}
@media (max-width:320px){.my-courses-tab .section-tab-2 .nav-tabs li{display:inline-block;}
}
.my-courses-tab .section-tab-2 .nav-tabs li a{color:#fff;padding-bottom:17px;}
.my-courses-tab .section-tab-2 .nav-tabs li a:after{bottom:2px;}
.my-courses-tab .section-tab-2 .nav-tabs li a.active{color:#51be78;}
.error-area{position:relative;}
.error-area:before, .error-area:after{position:absolute;content:'';top:55px;left:55px;width:200px;height:200px;background-image:url(../images/dots.png);background-position:center;background-size:cover;z-index:-1;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;opacity:0.3;}
.error-area:after{bottom:55px;right:55px;left:auto;top:auto;}
.error-content svg{width:300px;fill:#7f8897;}
.sidebar{position:relative;}
@media only screen and (min-width:768px) and (max-width:991px){.sidebar{margin-top:60px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.sidebar{margin-top:60px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.sidebar{margin-top:60px;}
}
.sidebar-widget{margin-bottom:30px;border:1px solid rgba(127, 136, 151, 0.2);-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;padding:30px;-webkit-box-shadow:0 0 40px rgba(82, 85, 90, 0.06);-moz-box-shadow:0 0 40px rgba(82, 85, 90, 0.06);box-shadow:0 0 40px rgba(82, 85, 90, 0.06);background-color:#fff;}
.sidebar-widget:last-child{margin-bottom:0;}
.sidebar-widget .contact-form-action .form-control{padding-left:20px;}
.sidebar-widget .contact-form-action .message-control{height:100px;}
.sidebar-widget .contact-form-action .form-group{margin-bottom:0;}
.sidebar-widget .widget-title{font-size:20px;margin-bottom:14px;}
.sidebar-widget .section-divider{width:50px;margin-top:0;margin-bottom:20px;}
.sidebar-widget .section-divider:after{top:-0.1px;width:5px;-webkit-animation-name:dot-move2;animation-name:dot-move2;}
.sidebar-widget .list-items li:after{width:9px;height:9px;background-color:transparent;border:1px solid rgba(127, 136, 151, 0.5);-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.sidebar-widget .list-items li:last-child{margin-bottom:8px;}
.sidebar-widget .list-items li a{display:block;color:#7f8897;text-transform:capitalize;font-weight:500;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.sidebar-widget .list-items li a:hover{color:#51be78;}
.sidebar-widget .list-items li:hover:after{background-color:#51be78;border-color:#51be78;}
.sidebar-widget .filter-by-level li:last-child .custom-checkbox{margin-bottom:0;}
.sidebar-widget .sort-ordering{width:100%;}
.sidebar-widget .rating-shared li{display:block;padding-right:0;padding-bottom:10px;}
.sidebar-widget .rating-shared li .review-label{display:inline-block;margin-top:4px;float:left;margin-right:28px;}
.tag-widget .list-items li{padding-left:0;display:inline-block;margin-bottom:4px;}
.tag-widget .list-items li:after{display:none;}
.tag-widget .list-items li a{border:1px solid rgba(127, 136, 152, 0.2);padding:4px 14px;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;display:block;}
.tag-widget .list-items li a:hover{background-color:#51be78;color:#fff;border-color:#51be78;}
.sidebar-feature .list-items li{padding-left:0;border-bottom:1px solid rgba(127, 136, 151, 0.1);padding-bottom:15px;display:-webkit-flex;display:-ms-flex;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between;}
.sidebar-feature .list-items li:after{display:none;}
.sidebar-feature .list-items li:last-child{padding-bottom:0;border-bottom:0;}
.sidebar-preview{padding:0;border:none;}
.sidebar-component{position:relative;z-index:3;margin-top:-93px;}
@media only screen and (min-width:768px) and (max-width:991px){.sidebar-component{margin-top:0;}
}
@media only screen and (min-width:480px) and (max-width:767px){.sidebar-component{margin-top:0;}
}
@media only screen and (min-width:320px) and (max-width:479px){.sidebar-component{margin-top:0;}
}
.sidebar-component .sidebar-preview{-webkit-border-radius:8px 8px 0 0;-moz-border-radius:8px 8px 0 0;border-radius:8px 8px 0 0;}
.sidebar-preview-titles{background-color:#51be78;padding:20px 30px;-webkit-border-radius:8px 8px 0 0;-moz-border-radius:8px 8px 0 0;border-radius:8px 8px 0 0;}
.sidebar-preview-titles .widget-title{color:#fff;margin-bottom:0;}
.sidebar-preview-titles .section-divider{background-color:#fff;margin:0;}
.sidebar-preview-titles .section-divider:after{background-color:#51be78;}
.preview-video-and-details{border:1px solid rgba(127, 136, 151, 0.2);}
.preview-course-video{padding:4px 4px 0 4px;position:relative;}
.preview-course-video img{width:100%;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;}
.preview-course-video .play-button{position:absolute;top:50%;left:50%;-webkit-transform:translate(-50%, -50%);-moz-transform:translate(-50%, -50%);-ms-transform:translate(-50%, -50%);-o-transform:translate(-50%, -50%);transform:translate(-50%, -50%);-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.preview-course-video:hover .play-button{-webkit-transform:translate(-50%, -50%) scale(1.1);-moz-transform:translate(-50%, -50%) scale(1.1);-ms-transform:translate(-50%, -50%) scale(1.1);-o-transform:translate(-50%, -50%) scale(1.1);transform:translate(-50%, -50%) scale(1.1);}
.video-content-btn .btn{color:#233d63;-webkit-box-shadow:0 0 0 0;-moz-box-shadow:0 0 0 0;box-shadow:0 0 0 0;}
.video-content-btn .btn:hover{background-color:rgba(127, 136, 151, 0.1);}
.bookmark-icon.active:before{content:"\f233";}
.business-content a{color:#51be78;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.business-content a:hover{color:#233d63;}
.preview-course-content{padding:30px 30px 30px 30px;border-top:0;}
.preview-course-content .preview-course__price{margin-bottom:16px;}
.preview-course-content .preview-course__price span{margin-right:7px;font-size:18px;}
.preview-course-content .preview-course__price span.price-current{color:#233d63;font-size:40px;font-weight:600;margin-right:10px;}
@media (max-width:1199px){.preview-course-content .preview-course__price span.price-current{margin-right:0;margin-bottom:10px;}
}
.preview-course-content .preview-course__price span.price-before{text-decoration:line-through;}
.preview-course-content .preview-course__price span.price-discount{margin-right:0;}
@media (max-width:1199px){.preview-course-content .preview-course__price.d-flex{display:block !important;}
}
.preview-course-content .preview-price-discount__text{margin-bottom:30px;}
.preview-course-content .preview-price-discount__text .discount-left__text-text{color:#F68A03;font-weight:500;}
.preview-course-content .preview-course-incentives .preview-course-incentives__text{text-align:center;}
.preview-course-content .preview-course-incentives .list-items li{padding-left:0;}
.preview-course-content .preview-course-incentives .list-items li:after{display:none;}
@media (max-width:1199px){.review-content.d-flex{display:block !important;}
}
.stats-average__count .stats-average__count-count{color:#233d63;font-size:70px;font-weight:600;}
.stats-average__rating{margin-left:10px;}
.stats-average__rating .review-stars{margin-right:5px;}
.stats-average__rating .review-stars li{font-size:18px;}
.course-rating-text{text-align:center;margin-top:5px;}
@media (max-width:1199px){.course-rating-text{text-align:left;margin-bottom:30px;}
}
.course-rating-text .course-rating-text__text{margin-left:60px;}
@media (max-width:1199px){.course-rating-text .course-rating-text__text{margin-left:0;}
}
.review-rating-widget{margin-left:50px;width:100%;}
@media (max-width:1199px){.review-rating-widget{margin-left:0;}
}
.review-rating-rate__items{margin-bottom:10px;}
@media (max-width:375px){.review-rating-rate__items{margin-bottom:15px;}
}
.review-rating-rate__items:last-child{margin-bottom:0;}
.review-rating-inner__item{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;}
@media (max-width:375px){.review-rating-inner__item{display:block !important;}
}
.review-rating-inner__item .review-rating-rate__item-text{text-transform:uppercase;font-weight:500;font-size:15px;margin-right:15px;}
@media (max-width:375px){.review-rating-inner__item .review-rating-rate__item-text{margin-bottom:5px;}
}
.review-rating-inner__item .review-rating-rate__item-fill{width:310px;height:20px;background-color:rgba(127, 136, 151, 0.1);-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;margin-right:15px;}
@media (max-width:480px){.review-rating-inner__item .review-rating-rate__item-fill{width:290px;}
}
@media (max-width:425px){.review-rating-inner__item .review-rating-rate__item-fill{width:260px;}
}
@media (max-width:375px){.review-rating-inner__item .review-rating-rate__item-fill{width:100%;margin-right:0;margin-bottom:5px;}
}
.review-rating-inner__item .review-rating-rate__item-fill .review-rating-rate__item-fill__fill{background-color:#F68A03;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;display:block;width:77%;height:100%;}
.review-rating-inner__item .review-rating-rate__item-fill .rating-fill-width2{width:54%;}
.review-rating-inner__item .review-rating-rate__item-fill .rating-fill-width3{width:14%;}
.review-rating-inner__item .review-rating-rate__item-fill .rating-fill-width4{width:5%;}
.review-rating-inner__item .review-rating-rate__item-fill .rating-fill-width5{width:2%;}
.review-rating-inner__item .review-rating-rate__item-percent-text{opacity:0.8;}
@media only screen and (min-width:768px) and (max-width:991px){.course-dashboard-container{display:block !important;}
}
@media only screen and (min-width:480px) and (max-width:767px){.course-dashboard-container{display:block !important;}
}
@media only screen and (min-width:320px) and (max-width:479px){.course-dashboard-container{display:block !important;}
}
.course-dashboard-column{width:75%;-webkit-transition:all 0.5s;-moz-transition:all 0.5s;-ms-transition:all 0.5s;-o-transition:all 0.5s;transition:all 0.5s;}
@media (max-width:1366px){.course-dashboard-column{width:calc(100% - 400px);}
}
@media (max-width:1199px){.course-dashboard-column{width:calc(100% - 350px);}
}
@media only screen and (min-width:768px) and (max-width:991px){.course-dashboard-column{width:100%;}
}
@media only screen and (min-width:480px) and (max-width:767px){.course-dashboard-column{width:100%;}
}
@media only screen and (min-width:320px) and (max-width:479px){.course-dashboard-column{width:100%;}
}
.course-dashboard-column.active{width:100%;}
.lecture-video-detail{position:relative;z-index:3;}
.lecture-tab-body .section-tab{background-color:rgba(127, 136, 151, 0.08);}
.lecture-tab-body .section-tab .nav-tabs{padding:20px 30px 0 30px;}
@media (max-width:480px){.lecture-tab-body .section-tab .nav-tabs{padding-right:15px;padding-left:15px;white-space:nowrap;overflow-y:hidden;overflow-x:auto;width:100%;flex-wrap:inherit;display:block;position:relative;}
}
.lecture-tab-body .section-tab .nav-tabs li{padding-bottom:20px;margin-right:25px;}
@media (max-width:480px){.lecture-tab-body .section-tab .nav-tabs li{display:inline-block;padding-bottom:16px;}
}
.lecture-tab-body .section-tab .nav-tabs li.mobile-course-tab{display:none;}
@media only screen and (min-width:768px) and (max-width:991px){.lecture-tab-body .section-tab .nav-tabs li.mobile-course-tab{display:inline-block;}
}
@media only screen and (min-width:480px) and (max-width:767px){.lecture-tab-body .section-tab .nav-tabs li.mobile-course-tab{display:inline-block;}
}
@media only screen and (min-width:320px) and (max-width:479px){.lecture-tab-body .section-tab .nav-tabs li.mobile-course-tab{display:inline-block;}
}
.lecture-tab-body .section-tab .nav-tabs li a{padding-bottom:25px;}
.search-course-form{padding-top:10px;display:none;}
.search-course-form.active{display:block;}
.search-course-form .contact-form-action{padding-right:20px;padding-left:20px;}
.search-course-form .contact-form-action .form-group{position:relative;}
.search-course-form .contact-form-action .form-group:after{position:absolute;content:"";top:-6px;left:20px;width:12px;height:12px;background-color:#fff;-webkit-transform:rotate(45deg);-moz-transform:rotate(45deg);-ms-transform:rotate(45deg);-o-transform:rotate(45deg);transform:rotate(45deg);border-top:1px solid rgba(127, 136, 151, 0.2);border-left:1px solid rgba(127, 136, 151, 0.2);}
.lecture-video-detail-body{padding:20px 50px 50px 50px;}
@media (max-width:1366px){.lecture-video-detail-body{padding-right:0;padding-left:0;}
}
@media only screen and (min-width:768px) and (max-width:991px){.mobile-course-content-wrap{padding-top:30px;padding-right:50px;padding-left:50px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.mobile-course-content-wrap{padding-top:30px;padding-right:50px;padding-left:50px;}
}
@media (max-width:575px){.mobile-course-content-wrap{padding-top:30px;padding-right:15px;padding-left:15px;}
}
.lecture-overview-wrap{width:960px;margin-left:auto;margin-right:auto;}
@media (max-width:1366px){.lecture-overview-wrap{width:700px;}
}
@media (max-width:1199px){.lecture-overview-wrap{width:600px;}
}
@media only screen and (min-width:768px) and (max-width:991px){.lecture-overview-wrap{width:auto;padding-right:50px;padding-left:50px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.lecture-overview-wrap{width:auto;padding-right:50px;padding-left:50px;}
}
@media (max-width:575px){.lecture-overview-wrap{width:auto;padding-right:15px;padding-left:15px;}
}
.lecture-overview-item{margin-bottom:30px;margin-top:30px;}
.lecture-overview-item .list-items li{padding-left:0;margin-bottom:5px;}
.lecture-overview-item .list-items li:after{display:none;}
.lecture-overview-item .list-items li span{color:#233d63;margin-right:5px;}
.lecture-overview-item .contact-form-action .form-control{padding-left:20px;background-color:rgba(127, 136, 151, 0.1);border-color:transparent;}
.lecture-overview-item .contact-form-action .form-control:focus{border-color:#51be78;}
.lecture-overview-item .contact-form-action .form-group .search-icon{background-color:#51be78;color:#fff;-webkit-border-radius:0 0 4px 4px;-moz-border-radius:0 0 4px 4px;border-radius:0 0 4px 4px;}
@media (max-width:375px){.question-overview-result-header{display:block !important;}
}
@media (max-width:480px){.lecture-overview-stats-wrap{display:block !important;}
}
.lecture-overview-stats-wrap > *.lecture-overview-stats-wide-item{width:75%;}
@media (max-width:480px){.lecture-overview-stats-wrap > *.lecture-overview-stats-wide-item{width:auto;}
}
.lecture-overview-stats-wrap > *{width:33%;}
@media (max-width:480px){.lecture-overview-stats-wrap > *{width:auto;}
}
.lecture-overview-stats-wrap > *:first-child{width:25%;}
@media (max-width:480px){.lecture-overview-stats-wrap > *:first-child{width:auto;margin-bottom:12px;}
}
.lecture-description p{line-height:28px;padding-bottom:20px;}
.lecture-description .list-items{padding-bottom:20px;}
.lecture-description .list-items li{padding-left:18px;}
.lecture-description .list-items li:after{display:block;}
.lecture-owner-avatar{width:60px;height:60px;-ms-flex-negative:0;flex-shrink:0;margin-right:15px;}
.lecture-owner-avatar img{width:100%;height:100%;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;}
.lecture-owner-decription p{padding-bottom:10px;}
.lecture-owner-decription p strong{font-weight:600;color:#233d63;margin-right:4px;}
@media (max-width:575px){.question-overview-filter-wrap{display:block !important;}
}
.question-overview-filter-item{flex:1;margin-right:8px;}
@media (max-width:575px){.question-overview-filter-item{width:100%;margin-right:0;margin-bottom:8px;}
}
.question-overview-filter-item:last-child{margin-right:0;}
.question-overview-filter-item .msg-action-dot .dropdown-menu{min-width:100%;left:auto !important;right:0;margin-top:55px;border:1px solid rgba(127, 136, 151, 0.2);}
.question-overview-filter-item .msg-action-dot .dropdown-menu .custom-checkbox{margin-bottom:0;}
.question-overview-filter-item .msg-action-dot .theme-btn{width:100%;background-color:#fff;line-height:51px;font-weight:500;text-transform:inherit;font-size:16px;}
.question-overview-filter-item .msg-action-dot .theme-btn:before{position:absolute;top:50%;-webkit-transform:translateY(-50%);-moz-transform:translateY(-50%);-ms-transform:translateY(-50%);-o-transform:translateY(-50%);transform:translateY(-50%);font-family:"LineAwesome";font-size:15px;color:#7f8897;pointer-events:none;display:inline-block;content:"\f110";right:15px;}
.question-overview-filter-item .msg-action-dot .theme-btn:focus{border-color:#51be78;}
.question-overview-filter-item .msg-action-dot .theme-btn:hover{color:#7f8897;}
.lecture-quest-wrap{position:relative;width:750px;}
@media (max-width:1366px){.lecture-quest-wrap{width:700px;}
}
@media (max-width:1199px){.lecture-quest-wrap{width:600px;}
}
@media only screen and (min-width:768px) and (max-width:991px){.lecture-quest-wrap{width:auto;}
}
@media only screen and (min-width:480px) and (max-width:767px){.lecture-quest-wrap{width:auto;}
}
@media only screen and (min-width:320px) and (max-width:479px){.lecture-quest-wrap{width:auto;}
}
.replay-question-wrap,
.new-question-wrap{background-color:#fff;padding-top:30px;display:none;width:650px;margin-left:auto;margin-right:auto;}
@media only screen and (min-width:480px) and (max-width:767px){.replay-question-wrap,
.new-question-wrap{width:auto;}
}
@media only screen and (min-width:320px) and (max-width:479px){.replay-question-wrap,
.new-question-wrap{width:auto;}
}
.replay-question-wrap.active,
.new-question-wrap.active{display:block;}
.replay-question-wrap{width:auto;}
.replay-question-body .question-list-item .comment{border-bottom:none;}
.replay-question-body .question-list-item .comment .comment-body{overflow:inherit;}
.replay-question-body .question-list-item .comment .comment-content{overflow:inherit;text-overflow:inherit;white-space:inherit;}
.replay-question-body .question-list-item .comment:hover{background-color:transparent;}
.replay-question-body .number-upvotes .dropdown-menu{left:auto !important;right:0 !important;border:1px solid rgba(127, 136, 151, 0.1);}
.replay-question-body .number-upvotes .action-dot:hover{color:#233d63;}
.question-replay-separator-wrap .btn{color:#7f8897;font-weight:600;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;outline:none;-webkit-box-shadow:0 0 0 0;-moz-box-shadow:0 0 0 0;box-shadow:0 0 0 0;}
.question-replay-separator-wrap .btn:hover{color:#233d63;}
.question-answer-wrap .comment__author a{color:#51be78;}
.question-answer-wrap .comment__author a:hover{color:#233d63;}
.question-replay-body .message-control{height:100px;-webkit-border-radius:0 0 4px 4px;-moz-border-radius:0 0 4px 4px;border-radius:0 0 4px 4px;}
.replay-action-bar{border:1px solid rgba(127, 136, 151, 0.2);background-color:rgba(127, 136, 151, 0.1);border-bottom:0;-webkit-border-radius:4px 4px 0 0;-moz-border-radius:4px 4px 0 0;border-radius:4px 4px 0 0;}
.replay-action-bar .btn-group .btn{color:#233d63;-webkit-box-shadow:0 0 0 0;-moz-box-shadow:0 0 0 0;box-shadow:0 0 0 0;}
.replay-action-bar .btn-group .btn:first-child{-webkit-border-radius:4px 0 0 0;-moz-border-radius:4px 0 0 0;border-radius:4px 0 0 0;}
.replay-action-bar .btn-group .btn:last-child{-webkit-border-radius:0;-moz-border-radius:0;border-radius:0;}
.replay-action-bar .btn-group .btn:hover{background-color:#51be78;color:#fff;}
.upload-image-box .la-photo{font-size:120px;color:rgba(127, 136, 151, 0.7);}
.upload-image-box .upload-btn-box{padding-top:20px;}
.upload-image-box .upload-btn-box .jFiler-input{width:auto;}
.upload-image-box .upload-btn-box .jFiler-input .jFiler-input-button{cursor:pointer;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;background-color:rgba(127, 136, 151, 0.1);font-size:15px;}
.upload-image-box .upload-btn-box .jFiler-input .jFiler-input-button:before{display:none;}
.upload-image-box .upload-btn-box .jFiler-input .jFiler-input-button:after{content:"Click here to upload file";-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.upload-image-box .upload-btn-box .jFiler-input .jFiler-input-button:hover:after{color:#233d63;}
.upload-image-box .upload-btn-box .jFiler-items{text-align:left;}
.question-overview-result-wrap.active{display:none;}
.question-result-item .btn{color:#7f8897;font-weight:600;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.question-result-item .btn:hover{color:#233d63;background-color:rgba(127, 136, 151, 0.1);}
.question-list-item .comment{padding:15px;margin-bottom:0;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
@media only screen and (min-width:480px) and (max-width:767px){.question-list-item .comment{display:-webkit-flex;display:-ms-flex;display:flex;text-align:inherit;}
}
@media only screen and (min-width:320px) and (max-width:479px){.question-list-item .comment{text-align:inherit;}
}
.question-list-item .comment:hover{background-color:rgba(127, 136, 151, 0.05);}
.question-list-item .comment-avatar .avatar__img{width:40px;margin-top:5px;height:40px;}
@media only screen and (min-width:480px) and (max-width:767px){.question-list-item .comment-avatar .avatar__img{margin-right:15px;}
}
.question-list-item .comment-body{overflow:hidden;width:100%;}
.question-list-item .comment-content{overflow:hidden;text-overflow:ellipsis;white-space:nowrap;margin-top:-2px;margin-bottom:10px;font-size:15px;}
@media only screen and (min-width:480px) and (max-width:767px){.question-list-item .meta-data{margin-top:0;}
}
.question-list-item .meta-data .comment__author{font-size:16px;text-transform:inherit;}
.question-list-item .comment__meta{font-size:13px;display:-webkit-flex;display:-ms-flex;display:flex;}
.question-list-item .comment__meta a{color:#51be78;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.question-list-item .comment__meta a:hover{color:#233d63;}
.question-list-item .comment__meta span{position:relative;padding-right:7px;padding-left:4px;}
.question-list-item .comment__meta span:first-child{padding-left:0;}
.question-list-item .comment__meta span:last-child{padding-right:0;}
.question-list-item .comment__meta span:last-child:after{display:none;}
.question-list-item .comment__meta span:after{position:absolute;content:"";top:13px;right:0;width:3px;height:3px;background-color:rgba(127, 136, 151, 0.3);-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;}
.question-meta-content{overflow:hidden;padding-right:100px;}
@media only screen and (min-width:480px) and (max-width:767px){.question-meta-content{padding-right:20px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.question-meta-content{padding-right:20px;}
}
.question-meta-content a{display:block;}
.number-upvotes{color:#7f8897;}
.number-upvotes span{font-weight:600;}
.number-upvotes button{border:none;margin-left:10px;font-size:14px;color:#7f8897;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;width:25px;height:25px;line-height:25px;-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;background-color:transparent;outline:none;}
.number-upvotes button:hover{color:#233d63;background-color:rgba(127, 136, 151, 0.2);}
.lecture-announcement-wrap{width:700px;}
@media (max-width:1199px){.lecture-announcement-wrap{width:600px;}
}
@media only screen and (min-width:768px) and (max-width:991px){.lecture-announcement-wrap{width:auto;}
}
@media only screen and (min-width:480px) and (max-width:767px){.lecture-announcement-wrap{width:auto;}
}
@media only screen and (min-width:320px) and (max-width:479px){.lecture-announcement-wrap{width:auto;}
}
.lecture-announcement-form .contact-form-action{width:100%;}
.reset-btn-box .theme-btn{background-color:transparent;line-height:40px;border-width:1px;border-color:transparent;color:#7f8897;text-transform:capitalize;padding-left:14px;padding-right:14px;}
.reset-btn-box .theme-btn:hover{background-color:rgba(127, 136, 151, 0.1);border-color:rgba(127, 136, 151, 0.2);color:#233d63;}
.rating-wrap .rating-btn{color:#7f8897;font-weight:500;background-color:rgba(127, 136, 151, 0.1);-webkit-box-shadow:0 0 0 0;-moz-box-shadow:0 0 0 0;box-shadow:0 0 0 0;}
.rating-wrap .rating-btn:hover{background-color:rgba(127, 136, 151, 0.1);border-color:rgba(127, 136, 151, 0.2);color:#233d63;}
@media only screen and (min-width:768px) and (max-width:991px){.my-course-filter-wrap{display:block !important;}
}
@media only screen and (min-width:480px) and (max-width:767px){.my-course-filter-wrap{display:block !important;}
}
@media only screen and (min-width:320px) and (max-width:479px){.my-course-filter-wrap{display:block !important;}
}
.my-course-sort-by-content{width:17%;}
@media only screen and (min-width:768px) and (max-width:991px){.my-course-sort-by-content{width:100%;}
}
@media only screen and (min-width:480px) and (max-width:767px){.my-course-sort-by-content{width:100%;}
}
@media only screen and (min-width:320px) and (max-width:479px){.my-course-sort-by-content{width:100%;}
}
.my-course-filter-by-content{padding-left:10px;width:50%;}
@media only screen and (min-width:768px) and (max-width:991px){.my-course-filter-by-content{padding:10px 0 10px 0;width:100%;}
}
@media only screen and (min-width:480px) and (max-width:767px){.my-course-filter-by-content{padding:10px 0 10px 0;width:100%;}
}
@media only screen and (min-width:320px) and (max-width:479px){.my-course-filter-by-content{padding:10px 0 10px 0;width:100%;}
}
.my-course-filter-by-content .question-overview-filter-item .sort-ordering{width:33%;margin-right:10px;}
@media only screen and (min-width:480px) and (max-width:767px){.my-course-filter-by-content .question-overview-filter-item .d-flex{display:block !important;}
}
@media only screen and (min-width:320px) and (max-width:479px){.my-course-filter-by-content .question-overview-filter-item .d-flex{display:block !important;}
}
@media only screen and (min-width:480px) and (max-width:767px){.my-course-filter-by-content .question-overview-filter-item .sort-ordering{width:100%;margin-right:0;margin-bottom:10px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.my-course-filter-by-content .question-overview-filter-item .sort-ordering{width:100%;margin-right:0;margin-bottom:10px;}
}
.my-course-search-content{width:33%;padding-left:30px;}
@media only screen and (min-width:768px) and (max-width:991px){.my-course-search-content{width:100%;padding-left:0;}
}
@media only screen and (min-width:480px) and (max-width:767px){.my-course-search-content{width:100%;padding-left:0;}
}
@media only screen and (min-width:320px) and (max-width:479px){.my-course-search-content{width:100%;padding-left:0;}
}
.course-alert-info .alert-dismissible .close{padding:18px 20px 22px 20px;}
.course-alert-info .alert i{font-size:40px;margin-right:20px;}
.course-alert-info .alert .alert-link{-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.course-alert-info .alert .alert-link:hover{color:#51be78;}
.my-collection-action-wrap{padding-top:4px;}
.my-collection-action-wrap .icon-element{width:30px;height:30px;line-height:30px;background-color:rgba(127, 136, 151, 0.1);color:#7f8897;font-size:18px;margin-right:3px;cursor:pointer;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.my-collection-action-wrap .icon-element:hover{background-color:rgba(127, 136, 151, 0.2);color:#233d63;}
@media (max-width:575px){.my-wishlist-info{display:block !important;}
}
@media (max-width:575px){.my-wishlist-info .lecture-overview-item{padding-top:20px;}
}
.my-wishlist-info .lecture-overview-item .my-course-search-content{width:300px;padding-left:0;}
.course-dashboard-side-heading{padding:20px;border-top:2px solid rgba(127, 136, 151, 0.6);position:relative;}
.sidebar-open,
.sidebar-close{background-color:transparent;border:none;font-size:22px;color:#233d63;outline:none;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.sidebar-open:hover,
.sidebar-close:hover{-webkit-transform:rotate(90deg);-moz-transform:rotate(90deg);-ms-transform:rotate(90deg);-o-transform:rotate(90deg);transform:rotate(90deg);color:#51be78;}
.sidebar-open{padding:10px 16px;background-color:#233d63;color:#fff;-webkit-border-radius:4px 0 0 4px;-moz-border-radius:4px 0 0 4px;border-radius:4px 0 0 4px;position:absolute;top:20px;left:-40px;opacity:0;visibility:hidden;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;font-size:15px;font-weight:600;z-index:-1;}
.sidebar-open .la{margin-right:5px;}
.sidebar-open.active{opacity:1;visibility:visible;}
.sidebar-open:hover{-webkit-transform:rotate(0);-moz-transform:rotate(0);-ms-transform:rotate(0);-o-transform:rotate(0);transform:rotate(0);color:#fff;left:-155px;}
.course-item-list-accordion .card{-webkit-border-radius:0;-moz-border-radius:0;border-radius:0;border-left:0;border-right:0;overflow:inherit;}
.course-item-list-accordion .card-header{padding:0;-webkit-border-radius:0;-moz-border-radius:0;border-radius:0;background-color:rgba(127, 136, 151, 0.07);-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;border-bottom:none;}
.course-item-list-accordion .card-header:hover{background-color:rgba(127, 136, 151, 0.2);}
.course-item-list-accordion .card-header .btn{width:100%;text-align:left;padding:10px 20px;color:#233d63;text-decoration:none;position:relative;}
.course-item-list-accordion .card-header .btn:after{position:absolute;content:"\f107";font-family:"FontAwesome";top:50%;right:20px;-webkit-transform:translateY(-50%);-moz-transform:translateY(-50%);-ms-transform:translateY(-50%);-o-transform:translateY(-50%);transform:translateY(-50%);font-size:24px;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.course-item-list-accordion .card-header .btn[aria-expanded=true]:after{content:"\f106";}
.course-item-list-accordion .card-body{padding:0;}
.course-duration span{font-size:14px;color:#7f8897;position:relative;padding-right:12px;padding-left:2px;}
.course-duration span:first-child{padding-left:0;}
.course-duration span:last-child{padding-right:0;}
.course-duration span:last-child:after{display:none;}
.course-duration span:after{position:absolute;content:"";top:8px;right:0;width:5px;height:5px;background-color:rgba(127, 136, 151, 0.4);-webkit-border-radius:50%;-moz-border-radius:50%;border-radius:50%;}
.lecture-viewer-container{position:relative;}
.lecture-viewer-text-wrap{position:absolute;top:0;left:0;width:100%;height:100%;background-color:#fff;z-index:4;display:none;padding-bottom:50px;}
.lecture-viewer-text-wrap.active{display:block;}
.lecture-viewer-text-content{overflow:auto;height:100%;padding:80px 100px 0 100px;}
@media (max-width:1440px){.lecture-viewer-text-content{padding:50px 80px 0 80px;}
}
@media (max-width:575px){.lecture-viewer-text-content{padding:30px 30px 0 30px;}
}
.lecture-viewer-text-body{width:700px;height:100%;margin-left:auto;margin-right:auto;}
@media (max-width:1440px){.lecture-viewer-text-body{width:auto;}
}
@media (max-width:575px){.lecture-viewer-text-body .widget-title{font-size:24px !important;padding-bottom:10px !important;}
}
.lecture-viewer-content-detail .list-items li{padding-left:0;font-size:17px;line-height:32px;margin-bottom:12px;}
.lecture-viewer-content-detail .list-items li strong{color:#233d63;}
.lecture-viewer-content-detail .list-items li:after{display:none;}
.course-list .course-item-link{cursor:pointer;padding:12px 20px;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.course-list .course-item-link.active{background-color:rgba(81, 190, 120, 0.1);}
.course-list .course-item-link:hover{background-color:rgba(127, 136, 151, 0.1);}
.course-list .course-item-link .courser-item-meta-wrap{display:-webkit-flex;display:-ms-flex;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:justify;justify-content:space-between;}
.course-list .course-item-link .msg-action-dot .dropdown-menu{left:auto !important;right:0;border:1px solid rgba(127, 136, 151, 0.2);margin-top:35px;}
.course-list .course-item-link .msg-action-dot .theme-btn{line-height:30px;font-size:14px;padding-right:10px;padding-left:10px;margin-top:2px;background-color:#fff;}
.course-list .course-item-link .msg-action-dot .theme-btn:hover{border-color:rgba(35, 61, 99, 0.2);color:#233d63;}
.course-list .course-item-content-wrap{display:-webkit-flex;display:-ms-flex;display:flex;}
.course-list .course-item-content-wrap .custom-checkbox{margin-bottom:0;margin-right:4px;}
.course-list .course-item-content-wrap .custom-checkbox input[type=checkbox]:not(:checked) + label:before,
.course-list .course-item-content-wrap .custom-checkbox input[type=checkbox]:checked + label:before{top:-5px;}
.course-list .course-item-content-wrap .custom-checkbox input[type=checkbox]:not(:checked) + label:after,
.course-list .course-item-content-wrap .custom-checkbox input[type=checkbox]:checked + label:after{top:-9px;}
.course-list .course-item-content-wrap .course-item-content{width:100%;}
.course-list .course-item-content-wrap .course-item-content .course-item-meta{font-size:14px;}
.course-list .course-item-content-wrap .course-item-content .course-item-meta i{margin-right:3px;}
.course-dashboard-sidebar-column{width:25%;height:100%;position:fixed;top:80px;right:0;background-color:#fff;-webkit-transition:all 0.5s;-moz-transition:all 0.5s;-ms-transition:all 0.5s;-o-transition:all 0.5s;transition:all 0.5s;z-index:5;-webkit-box-shadow:0 0 8px 0px rgba(0, 0, 0, 0.05);-moz-box-shadow:0 0 8px 0px rgba(0, 0, 0, 0.05);box-shadow:0 0 8px 0px rgba(0, 0, 0, 0.05);border:1px solid rgba(127, 136, 151, 0.2);border-top:0;}
@media (max-width:1366px){.course-dashboard-sidebar-column{width:400px;}
}
@media (max-width:1199px){.course-dashboard-sidebar-column{width:350px;}
}
@media only screen and (min-width:768px) and (max-width:991px){.course-dashboard-sidebar-column{display:none;}
}
@media only screen and (min-width:480px) and (max-width:767px){.course-dashboard-sidebar-column{display:none;}
}
@media only screen and (min-width:320px) and (max-width:479px){.course-dashboard-sidebar-column{display:none;}
}
.course-dashboard-sidebar-column.active{right:-25%;}
@media (max-width:1366px){.course-dashboard-sidebar-column.active{right:-400px;}
}
@media (max-width:1199px){.course-dashboard-sidebar-column.active{right:-350px;}
}
.course-dashboard-sidebar-wrap{height:100%;overflow-x:hidden;padding-bottom:90px;background-color:#fff;z-index:5;}
@media (max-width:1199px){.footer-area .container-fluid.padding-right-150px{padding-right:15px;padding-left:15px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.footer-area .column-lld-half,
.footer-area .column-lld-third{-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}
@media only screen and (min-width:320px) and (max-width:479px){.footer-area .column-lld-half,
.footer-area .column-lld-third{-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}
.footer-widget{margin-bottom:40px;}
.footer-widget .widget-title{color:#fff;font-size:20px;}
.footer-widget .section-divider{background-color:rgba(255, 255, 255, 0.1);width:50px;margin-top:20px;}
.footer-widget .section-divider:after{top:-0.1px;width:5px;-webkit-animation-name:dot-move2;animation-name:dot-move2;}
.footer-widget .footer-address{margin-top:38px;}
.footer-widget .footer-address a{color:#9ea6ba;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.footer-widget .footer-address a:hover{color:#51be78;}
.footer-widget .footer-address li{display:block;color:#9ea6ba;line-height:24px;margin-bottom:7px;}
.footer-widget .social-profile{margin-top:17px;}
.footer-widget .social-profile li{font-size:14px;}
.footer-widget .social-profile li a{width:35px;height:35px;line-height:35px;color:#fff;background-color:rgba(255, 255, 255, 0.1);}
.footer-widget .social-profile li a:hover{background-color:#51be78;}
.footer-widget .list-items li{margin-bottom:8px;}
.footer-widget .list-items li:last-child{margin-bottom:0;}
.footer-widget .list-items li a{color:#9ea6ba;text-transform:capitalize;}
.footer-widget .list-items li:hover a{color:#51be78;}
.footer-widget .list-items li:hover:after{background-color:#51be78;}
.footer-widget .footer-address li{padding-left:0;}
.footer-widget .footer-address li:after{display:none;}
.footer-widget .btn-box li{margin-bottom:12px;}
.footer-widget .btn-box li:last-child{margin-bottom:0;}
.footer-widget .btn-box .theme-btn{width:100%;background-color:rgba(255, 255, 255, 0.1);border:none;display:-webkit-flex;display:-ms-flex;display:flex;-ms-flex-align:center;align-items:center;text-transform:inherit;line-height:inherit;padding-top:15px;padding-bottom:15px;font-size:16px;}
.footer-widget .btn-box .theme-btn .icon-element{font-size:24px;color:#233d63;background-color:#fff;width:40px;height:40px;line-height:40px;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.footer-widget .btn-box .theme-btn .app-titles{margin-left:10px;line-height:16px;}
.footer-widget .btn-box .theme-btn .app-titles .app__subtitle{font-size:11px;display:block;}
.footer-widget .btn-box .theme-btn:hover{background-color:#fff;color:#51be78;}
.footer-widget .btn-box .theme-btn:hover .icon-element{background-color:#51be78;color:#fff;}
@media (max-width:320px){.footer-widget .btn-box-2{display:block !important;}
}
.footer-widget .btn-box-2 li{margin-bottom:0;margin-right:8px;}
@media (max-width:320px){.footer-widget .btn-box-2 li{margin-bottom:8px;}
}
.footer-widget .btn-box-2 .theme-btn{padding:7px 20px;}
.footer-widget .btn-box-2 .theme-btn .la{font-size:24px;}
.copyright-content{margin-top:50px;padding-top:40px;border-top:1px solid rgba(255, 255, 255, 0.1);position:relative;}
@media only screen and (min-width:768px) and (max-width:991px){.copyright-content{margin-top:40px;text-align:center;}
}
@media only screen and (min-width:480px) and (max-width:767px){.copyright-content{margin-top:40px;text-align:center;}
}
@media only screen and (min-width:320px) and (max-width:479px){.copyright-content{margin-top:40px;text-align:center;}
}
.copyright-content .footer__logo{margin-right:40px;}
.copyright-content .list-items{margin-left:30px;}
.copyright-content .list-items li{display:inline-block;margin-right:10px;padding-left:0;}
.copyright-content .list-items li:after{display:none;}
.copyright-content .list-items li:last-child{margin-right:0;}
.copyright-content .list-items li:hover a{color:#51be78;}
.copyright-content .list-items li:hover:after{background-color:#51be78;}
.copyright-content .copyright-content-inner{display:-webkit-flex;display:-ms-flex;display:flex;-ms-flex-align:center;align-items:center;}
.copyright-content .copy__desc{color:#9ea6ba;}
@media only screen and (min-width:768px) and (max-width:991px){.copyright-content .copy__desc{margin-bottom:30px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.copyright-content .copy__desc{margin-bottom:30px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.copyright-content .copy__desc{margin-bottom:30px;}
}
.copyright-content .copy__desc a{color:#9ea6ba;-webkit-transition:all 0.3s;-moz-transition:all 0.3s;-ms-transition:all 0.3s;-o-transition:all 0.3s;transition:all 0.3s;}
.copyright-content .copy__desc a:hover{color:#51be78;}
.copyright-content .sort-ordering{width:170px;}
@media only screen and (min-width:768px) and (max-width:991px){.copyright-content .sort-ordering{margin-left:auto;margin-right:auto;}
}
@media only screen and (min-width:480px) and (max-width:767px){.copyright-content .sort-ordering{margin-left:auto;margin-right:auto;}
}
@media only screen and (min-width:320px) and (max-width:479px){.copyright-content .sort-ordering{margin-left:auto;margin-right:auto;}
}
.copyright-content .sort-ordering .bootstrap-select.show .dropdown-toggle{border-color:rgba(255, 255, 255, 0.5) !important;}
.copyright-content .sort-ordering .dropdown-toggle{border-color:rgba(255, 255, 255, 0.2) !important;background-color:transparent !important;color:#9ea6ba !important;}
.copyright-content-2{margin-top:0;padding-top:0;}
.copyright-content-2 .footer__logo{margin-right:20px;}
@media only screen and (min-width:768px) and (max-width:991px){.copyright-content-2 .copyright-content-inner{margin-bottom:20px;-ms-flex-pack:justify;justify-content:center;}
}
@media only screen and (min-width:480px) and (max-width:767px){.copyright-content-2 .copyright-content-inner{margin-bottom:20px;-ms-flex-pack:justify;justify-content:center;}
}
@media only screen and (min-width:320px) and (max-width:479px){.copyright-content-2 .copyright-content-inner{margin-bottom:20px;-ms-flex-pack:justify;justify-content:center;}
}
@media (max-width:320px){.copyright-content-2 .copyright-content-inner{display:block;}
}
.copyright-content-2 .copyright-content-inner .copy__desc{font-size:14px;}
@media only screen and (min-width:768px) and (max-width:991px){.copyright-content-2 .copyright-content-inner .copy__desc{margin-bottom:0;}
}
@media only screen and (min-width:480px) and (max-width:767px){.copyright-content-2 .copyright-content-inner .copy__desc{margin-bottom:0;}
}
@media only screen and (min-width:320px) and (max-width:479px){.copyright-content-2 .copyright-content-inner .copy__desc{margin-bottom:0;}
}
.copyright-content-2 .list-items li{font-size:14px;}
.copyright-content-2 .sort-ordering{width:auto;}
@media (max-width:1366px){.copyright-content-2 .sort-ordering{margin-top:30px;}
}
@media only screen and (min-width:768px) and (max-width:991px){.copyright-content-2 .sort-ordering{margin-top:20px;}
}
@media only screen and (min-width:480px) and (max-width:767px){.copyright-content-2 .sort-ordering{margin-top:20px;}
}
@media only screen and (min-width:320px) and (max-width:479px){.copyright-content-2 .sort-ordering{margin-top:20px;}
}
.copyright-content-2 .sort-ordering .bootstrap-select.show .dropdown-toggle{border-color:#51be78 !important;}
.copyright-content-2 .sort-ordering .dropdown-toggle{border-color:rgba(127, 136, 151, 0.2) !important;color:#7f8897 !important;}
@media only screen and (min-width:480px) and (max-width:767px){.copyright-content-3 .column-td-full{-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}
@media only screen and (min-width:320px) and (max-width:479px){.copyright-content-3 .column-td-full{-ms-flex:0 0 100%;flex:0 0 100%;max-width:100%;}
}
@media only screen and (min-width:768px) and (max-width:991px){.copyright-content-3 .copyright-content-inner{margin-bottom:20px;-ms-flex-pack:justify;justify-content:center;}
}
@media only screen and (min-width:480px) and (max-width:767px){.copyright-content-3 .copyright-content-inner{margin-bottom:20px;-ms-flex-pack:justify;justify-content:center;}
}
@media only screen and (min-width:320px) and (max-width:479px){.copyright-content-3 .copyright-content-inner{margin-bottom:20px;-ms-flex-pack:justify;justify-content:center;}
}
@media (max-width:425px){.copyright-content-3 .copyright-content-inner{display:block;}
}
@media (max-width:425px){.copyright-content-3 .copyright-content-inner .footer__logo{margin-right:0;}
}
@media only screen and (min-width:768px) and (max-width:991px){.copyright-content-3 .copyright-content-inner .copy__desc{margin-bottom:0;}
}
@media only screen and (min-width:480px) and (max-width:767px){.copyright-content-3 .copyright-content-inner .copy__desc{margin-bottom:0;}
}
@media only screen and (min-width:320px) and (max-width:479px){.copyright-content-3 .copyright-content-inner .copy__desc{margin-bottom:0;}
}
@media (max-width:425px){.copyright-content-3 .copyright-content-inner .copy__desc{margin-top:10px;}
}
@media (max-width:1440px){.copyright-content-3 .sort-ordering{margin-top:30px;}
}
.price-old{padding-left:10px;text-decoration: line-through;padding-top:2px;}
.card-preview .card__title{height: 48px;overflow: hidden;}
.card-preview{border:1px solid rgba(127, 136, 151, 0.2)}
.card-preview .card-content{border:0px;}
.card-deck .card{text-align: center;}
.card-deck .card i{font-size: 50px;}
.btn-group .btn{border-radius:0px;}
#headersearch{position: relative;}
#headersearch input{padding: 10px 20px 10px 15px;height: 35px;}

#result-search-autocomplete {
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 9999;
    background-color: #ffffff;
    border: 1px solid #dedede;
    min-width:300px;
    box-shadow: 0 0.3rem 0.5rem rgba(0,0,0,0.3), 0 0.5rem 1rem rgba(0,0,0,0.3);
}
.hero-search-form #result-search-autocomplete .result-search-heading, #headersearch #result-search-autocomplete .result-search-heading {
    color: #000000;
}
.hero-search-form #result-search-autocomplete .show-result, #headersearch #result-search-autocomplete .show-result {
	overflow-y: scroll;
    list-style-type: none;
    padding: 0px;
    margin: 0px;
    max-height: 400px;
    overflow-x: hidden;
}
.hero-search-form .result-search-heading span, #headersearch .result-search-heading span {
    display: inline-block;
    padding: 5px;
}
.hero-search-form .result-search-heading a, #headersearch .result-search-heading a {
    float: right;
    color: red;
    padding: 5px 20px;
}
.hero-search-form #result-search-autocomplete .show-result li, #headersearch #result-search-autocomplete .show-result li {
    margin: 0px;
    padding:0px;
    border-bottom: 1px solid #dedede;
    display: block;
}
.hero-search-form #result-search-autocomplete .show-result li a, #headersearch #result-search-autocomplete .show-result li a {
    display: block;
    padding: 5px;
    font-size: 14px;
    color: #51be78;
}
.hero-search-form #result-search-autocomplete .show-result li a:hover, #headersearch #result-search-autocomplete .show-result li a:hover{background-color: rgba(81, 190, 120, 0.1);}
#checkout-checkout .card-title{font-size: 18px;margin-bottom:0px;}
.media i{font-size: 40px;margin-right: 15px;}
.media{background-color: #F7FAFD; padding: 20px; border: 1px solid #eef6fe;}
.product-list .card__title a{height:46px;overflow: hidden;display: block;}
.course-detail .description-wrap ul{list-style-type: disc;margin-left: 20px;}
.media,.course-detail .description-wrap h3, .course-detail .description-wrap ul, .course-detail .description-wrap p,.course-detail .description-wrap h4, .pagination{margin-bottom: 20px;}
.pagination>li:not(:first-of-type) {margin-left: 5px;border-width: 0 0 0 1px; border-color: transparent;border-style: solid;}
.pagination>li{background: rgba(248, 248, 248, 1); border-radius: 3px;}
.pagination>li.active {background: rgba(15, 58, 141, 1);}
.pagination>li>a,.pagination>li>span{padding: 5px; padding-right: 6px; padding-left: 6px;}
.pagination>li>span{color:#ffffff;}
#qnav-horizontal{background-color: #233d63;opacity: .9;}
#qnav-horizontal ul{list-style-type: none;margin:5px 5px 5px 8px;padding:0px;}
#qnav-horizontal ul li{display: block;float: left;}
#qnav-horizontal ul li a{display: block; padding: 4px; border: 1px solid rgba(255, 255, 255, 1); margin: 10px 0px; text-decoration: none; width: 30px; text-align: center; border-left: 0px;color: #ffffff;}
#qnav-horizontal ul li:first-child a{border-left:1px solid rgba(255, 255, 255, 1);}
#qnav-horizontal ul li a.active{background-color:#5a7baa;color:#ffffff;}
#form-quiz ul{list-style-type: none;margin:0px;padding:0px;}
#form-quiz ul>li{border-bottom:1px solid #e2e2e2;height: 300px;overflow: hidden;overflow-y: scroll;}
.question{background-color: #ececec;padding:10px;text-align: justify;}
#form-quiz ol li{margin-bottom: 15px;}
.btn-group{display: block;padding:10px;}
.btn-group .btn-next{margin-left: 5px !important;}
.title.page-title{margin-bottom: 5px !important;}
.forms{max-width: 500px; margin: 20px auto; background-color: #ffffff; padding: 20px; border: 1px solid #dedede;}
.forms .form-group{display: block;}
.mt-2{margin-top:20px;}
#display-answers{text-align: left;padding: 20px;background-color:#ffffff;}
#display-answers h3{text-align: center;}
#display-answers ul>li{margin-bottom: 30px;}
#display-answers .options{margin-left: 20px;}
.li-question .options{padding:15px 15px 15px 30px;}
.breadcrumb__list li:last-child:after{display: none;}
.product-breadcrumb li{padding-right: 22px !important;}
.main-menu>ul>li:last-child .dropdown-menu-item{right:0;left:auto;}
.main-menu>ul>li:last-child .dropdown-menu-item:after{left:auto;right: 15px;}
@media only screen and (max-width:767px){
	.breadcrumb-area.breadcrumb-detail-area{height:auto;padding-top:20px;padding-bottom:20px;}
	iframe{width:100% !important;}
	#information-information img{width:100% !important;}
}
.well{padding:15px;background-color: #fafafa;border:1px solid #eaeaea;}
#information-information h1, #information-information h2, #information-information h3, #information-information h4{margin-top:20px;}
#form-currency .btn{font-size: 0.9rem;}
.generic-accordion .generic-list-item li {
	font-size: 15px;
	color: rgba(35,61,99,.8);
	font-weight: 400;
}

.generic-accordion .generic-list-item li:last-child {
	margin-bottom: 0;
}

.generic-accordion .card {
	border: 0;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	margin-bottom: 10px;
}

.generic-accordion .card-header {
	padding: 0;
	background-color: transparent;
	border-bottom: 0;
}

.generic-accordion .card-header .btn {
	width: 100%;
	-webkit-border-radius: 8px;
	-moz-border-radius: 8px;
	border-radius: 8px;
	padding: 10px 20px 10px 50px;
	text-align: left;
	color: #233d63;
	text-decoration: none;
	font-size: 17px;
	position: relative;
	font-weight: 600;
	background-color: rgba(127,136,151,.07);
	border: 0;
}

.generic-accordion .card-header .btn[aria-expanded=true] .la-angle-up,.generic-accordion .card-header .btn[aria-expanded=true] .la-minus {
	display: block;
}

.generic-accordion .card-header .btn[aria-expanded=false] .la-angle-down,.generic-accordion .card-header .btn[aria-expanded=false] .la-plus {
	display: block;
}

.generic-accordion .card-header .btn .la {
	display: none;
	position: absolute;
	left: 20px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	font-size: 20px;
}

.generic--accordion .card-header .btn {
	padding-left: 20px;
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
}

.generic--accordion .card-header .btn .la {
	left: auto;
	right: 20px;
	font-size: 16px;
}

.generic--accordion .card-header .btn:hover {
	background-color: rgba(127,136,151,.1);
}

.generic--accordion .card {
	margin-bottom: 0;
	border-bottom: 1px solid rgba(0,0,0,.125);
	-webkit-border-radius: 0;
	-moz-border-radius: 0;
	border-radius: 0;
}

.generic--accordion .card:first-of-type {
	border-bottom: 1px solid rgba(0,0,0,.125);
}

.generic--accordion .card:not(:first-of-type):not(:last-of-type) {
	border-bottom: 1px solid rgba(0,0,0,.125);
}

.generic-accordion-layout-2 .card {
	background-color: #fff;
	-webkit-box-shadow: 0 0 15px rgba(0,0,0,.075);
	-moz-box-shadow: 0 0 15px rgba(0,0,0,.075);
	box-shadow: 0 0 15px rgba(0,0,0,.075);
	border: 1px solid rgba(128,137,150,.1);
}

.generic-accordion-layout-2 .card-header .btn {
	padding-top: 17px;
	padding-bottom: 17px;
	background-color: #fff;
}

.generic-accordion-layout-2 .card-header .btn[aria-expanded=true] {
	background-color: #ec5252;
	color: #fff;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;
}
